import {
  Box,
  FormControl,
  MenuItem,
  Autocomplete,
  TextField,
  Divider,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { AppSelect, InputBase, InputLabel } from "components/common/Input";
import { logisticsTeam } from "constants/logisticsTeam";
import { useFormikContext } from "formik";
import { defaultLogisticsPartner, LogisticsPartner } from "types/logisticsPartner";
import { LogisticsFormValues } from "../../../../types/types";

interface AgentDetailsProps {
  mainAgents: LogisticsPartner[];
  subAgents: LogisticsPartner[];
  findAgent: (id: string, type: string) => LogisticsPartner;
}

export default function AgentDetailsForm({ mainAgents, subAgents, findAgent }: AgentDetailsProps) {
  const formik = useFormikContext<LogisticsFormValues>();

  return (
    <>
      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink htmlFor="agent">
            Agent:
          </InputLabel>
          <Autocomplete
            id="agentId"
            size="small"
            getOptionLabel={(option: LogisticsPartner) => option.name}
            isOptionEqualToValue={(option: LogisticsPartner, value: LogisticsPartner) => option.id === value.id}
            value={findAgent(formik.values.agentId, "mainAgents")}
            options={
              findAgent(formik.values.agentId, "mainAgents") !== defaultLogisticsPartner
                ? mainAgents
                : [defaultLogisticsPartner, ...mainAgents]
            }
            autoComplete
            includeInputInList
            filterSelectedOptions
            onChange={(event, newValue: LogisticsPartner | null) => {
              formik.setFieldValue("agentId", newValue?.id || "");
            }}
            renderInput={(params) => <TextField {...params} name="agentId" fullWidth />}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
          />
        </FormControl>

        <FormControl variant="standard" fullWidth>
          <InputLabel shrink htmlFor="subAgent">
            Sub Agent:
          </InputLabel>
          <Autocomplete
            id="subAgentId"
            size="small"
            getOptionLabel={(option: LogisticsPartner) => option.name}
            isOptionEqualToValue={(option: LogisticsPartner, value: LogisticsPartner) => option.id === value.id}
            value={findAgent(formik.values.subAgentId, "subAgents")}
            options={
              findAgent(formik.values.subAgentId, "subAgents") !== defaultLogisticsPartner
                ? subAgents
                : [defaultLogisticsPartner, ...subAgents]
            }
            autoComplete
            includeInputInList
            filterSelectedOptions
            onChange={(event, newValue: LogisticsPartner | null) => {
              formik.setFieldValue("subAgentId", newValue?.id || "");
            }}
            renderInput={(params) => <TextField {...params} name="subAgentId" label="Search Sub Agents" fullWidth />}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
          />
        </FormControl>

        <FormControl variant="standard" fullWidth>
          <InputLabel shrink htmlFor="revivnCoordinatorId">
            Revivn Coordinator:
          </InputLabel>
          <AppSelect defaultValue="" id="revivnCoordinatorId" {...formik.getFieldProps("revivnCoordinatorId")}>
            {logisticsTeam.map((person) => {
              return (
                <MenuItem key={person[0]} value={person[0]}>
                  {person[1]}
                </MenuItem>
              );
            })}
          </AppSelect>
        </FormControl>
      </Box>
      <Divider sx={{ padding: "10px 0" }} />
      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 3, padding: "20px 0" }}>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink htmlFor="name">
            Agent Order ID
          </InputLabel>
          <InputBase id="orderNumber" {...formik.getFieldProps("orderNumber")} placeholder="Order ID" />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel htmlFor="estimatedCostInCents">Estimated Invoice Cost</InputLabel>
          <OutlinedInput
            id="estimatedCostInCents"
            size="small"
            type="number"
            inputProps={{
              step: 0.01,
            }}
            placeholder="0.00"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            {...formik.getFieldProps("estimatedCostInCents")}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel htmlFor="costInCents">Invoice Cost</InputLabel>
          <OutlinedInput
            id="costInCents"
            sx={{ height: "44px" }}
            type="number"
            inputProps={{
              step: 0.01,
            }}
            placeholder="0.00"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            {...formik.getFieldProps("costInCents")}
          />
        </FormControl>
      </Box>
    </>
  );
}
