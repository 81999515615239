import { Paper, Typography } from "@mui/material";

export const PageTitle = ({
  text,
  subtitle,
  children,
}: {
  text: string;
  subtitle?: string;
  children: React.ReactNode;
}) => {
  return (
    <>
      <div>
        <Typography variant="h5">{text}</Typography>
        {subtitle && <Typography mt={0.5}>{subtitle}</Typography>}
      </div>
      <Paper variant="outlined" sx={{ padding: 3, display: "flex", flexDirection: "column", gap: "1.5rem" }}>
        {children}
      </Paper>
    </>
  );
};
