import { Dialog, IconButton, Tooltip } from "@mui/material";
import { ArrowDownward, ArrowUpward, Close, Delete, Fullscreen, OpenInNew } from "@mui/icons-material";
import React, { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Attachable } from "components/Form/DirectUploadField";

export const DeleteAction = ({ onClick }: { onClick: () => void }) => (
  <Tooltip title="Delete" arrow placement="top">
    <IconButton
      className={"deleteAction"}
      size="small"
      onClick={() => {
        document.startViewTransition(() => onClick());
      }}
    >
      <Delete fontSize="small" />
    </IconButton>
  </Tooltip>
);
export const MoveUpAction = ({ onClick }: { onClick: () => void }) => (
  <Tooltip title="Move up" arrow placement="top">
    <IconButton
      size="small"
      onClick={() => {
        document.startViewTransition(() => onClick());
      }}
    >
      <ArrowUpward fontSize="small" />
    </IconButton>
  </Tooltip>
);
export const MoveDownAction = ({ onClick }: { onClick: () => void }) => (
  <Tooltip title="Move down" arrow placement="top">
    <IconButton
      size="small"
      onClick={() => {
        document.startViewTransition(() => onClick());
      }}
    >
      <ArrowDownward fontSize="small" />
    </IconButton>
  </Tooltip>
);
export const ExpandAction = ({ start, images }: { start: number; images: Attachable[] }) => {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(start);
  useHotkeys("left", () => setIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1)));
  useHotkeys("right", () => setIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1)));

  if (!images[index]) return null;

  return (
    <>
      <Tooltip title="Expand" arrow placement="top">
        <IconButton
          size="small"
          onClick={() => {
            setIndex(start);
            setOpen(true);
          }}
        >
          <Fullscreen fontSize="small" />
        </IconButton>
      </Tooltip>
      <Dialog fullScreen open={open} onClose={() => setOpen(false)} sx={{ margin: 8 }}>
        <img
          src={images[index].url}
          loading="lazy"
          width="100%"
          height="100%"
          style={{ objectFit: "contain", background: "transparent" }}
        />
        <IconButton size="large" onClick={() => setOpen(false)} sx={{ position: "absolute", top: 8, right: 8 }}>
          <Close />
        </IconButton>
      </Dialog>
    </>
  );
};

export const ViewAction = ({ url }: { url: string }) => (
  <Tooltip title="Open in new tab" arrow placement="top">
    <IconButton size="small" onClick={() => window.open(url, "_blank")}>
      <OpenInNew fontSize="small" />
    </IconButton>
  </Tooltip>
);
