import { ShipstationOrderItem } from "types/shipstationOrderItem";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { SubmitAwareMenuItem } from "components/Form/SubmitAwareMenuItem";
import { useAlert } from "hooks/useAlert";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { userHasPermission } from "utils/userPermissions";
import useCurrentUser from "hooks/useCurrentUser";

const validReplacementStates = ["skipped", "created", "on_cart", "packed"];

function useAlertModelErrors() {
  const { alertError } = useAlert();

  return (errors: Record<string, string[]>) => {
    if (!errors) return alertError("An unknown error occurred");
    return alertError(
      Object.entries(errors)
        .map(([key, value]) => `${key} ${value.join(", ")}`)
        .join("\n"),
    );
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function MarkAsHiddenRowAction({ row, showInMenu }: { row: ShipstationOrderItem; showInMenu?: boolean }) {
  const isCancellable = validReplacementStates.includes(row.state.toLowerCase());
  const apiRef = useGridApiContext();
  const alertModelErrors = useAlertModelErrors();
  const { currentUserPermissions } = useCurrentUser();
  const hasPermission = userHasPermission("warehouse_manager", currentUserPermissions);
  const { alertSuccess } = useAlert();

  return (
    <RevivnApiForm
      initialValues={{}}
      action={`shipstation_order_items/${row.id}/mark_as_hidden`}
      method="post"
      onError={(error) => {
        alertModelErrors(error.response?.data?.errors);
      }}
      onSuccess={(response) => {
        const updatedItem = response.data as ShipstationOrderItem;
        apiRef.current?.updateRows([{ ...updatedItem }]);
        alertSuccess(`Item ${updatedItem.id} successfully removed from picklist`);

        // This is a workaround to get the actions column to re-render and hide the popup menu
        apiRef.current?.setColumnVisibility("actions", false);
        apiRef.current?.setColumnVisibility("actions", true);
      }}
    >
      <SubmitAwareMenuItem
        disabled={!isCancellable || !hasPermission}
        sx={{ textTransform: "none", color: "inherit", position: "relative" }}
      >
        <input
          disabled={!isCancellable || !hasPermission}
          type="submit"
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{ opacity: 0, position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
        />
        Remove from Picklist{!hasPermission && " (Insufficient Permissions)"}
      </SubmitAwareMenuItem>
    </RevivnApiForm>
  );
}
