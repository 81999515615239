import { useMemo, useState } from "react";
import useSWR from "swr";
import { wrappedCollectionFetcher } from "services/api.service";
import { ServerErrorOverlay } from "components/DataGrid/components/ServerErrorOverlay";
import { GridNoRowsOverlay } from "@mui/x-data-grid-pro";
import { serializeFilterModel, serializeSortModel } from "components/DataGrid/helpers/serializers";
import { RevivnDataGrid, RevivnDataGridProps } from "components/DataGrid/RevivnDataGrid";
import { useEventListener } from "hooks/useEventListener";
import { DatabaseGridState, useDatabaseDataGridState } from "components/DataGrid/hooks/useDatabaseDataGridState";

interface RevivnDatabaseDataGridProProps extends Omit<RevivnDataGridProps, "rows"> {
  url: string;
  remoteCsvExport?: boolean;
  syncStateWithSearchParams?: boolean;
}

function gridStateToSearchParams(gridState: DatabaseGridState) {
  const { page: pageState, pageSize, filterModel, sortModel } = gridState;
  const filters = serializeFilterModel(filterModel) || "";
  const sort = serializeSortModel(sortModel) || "";
  const page = (pageState + 1).toString();
  const size = pageSize.toString();

  const params = new URLSearchParams({ "page[number]": page, "page[size]": size, sort });
  filters.forEach((filter) => {
    params.append("filter[]", filter);
  });
  return params;
}

export const RevivnDatabaseDataGrid = (props: RevivnDatabaseDataGridProProps) => {
  const [gridState, setGridState] = useDatabaseDataGridState(props.syncStateWithSearchParams);
  const [bulkEditMode, setBulkEditMode] = useState(false);
  useEventListener("bulkEditStart", () => setBulkEditMode(true));
  useEventListener("bulkEditStop", () => setBulkEditMode(false));

  const { data, isLoading, error } = useSWR(
    () => {
      const params = gridStateToSearchParams(gridState);
      return props.url.includes("?") ? `${props.url}&${params}` : `${props.url}?${params}`;
    },
    wrappedCollectionFetcher,
    {
      keepPreviousData: true,
      revalidateOnFocus: !bulkEditMode,
      revalidateOnReconnect: !bulkEditMode,
      revalidateIfStale: !bulkEditMode,
    },
  );

  const exportUrl = useMemo(() => {
    if (!props.remoteCsvExport) return undefined;
    const params = gridStateToSearchParams(gridState);
    if (props.url.includes("?")) {
      const [path, baseParams] = props.url.split("?");
      return `${path}/export_csv?${baseParams}&${params}`;
    } else {
      return `${props.url}/export_csv?${params}`;
    }
  }, [gridState, props.remoteCsvExport, props.url]);

  const rows = useMemo(() => data?.rows || [], [data]);
  const NoRowsOverlay = error ? ServerErrorOverlay : GridNoRowsOverlay;
  const totalCount = data?.meta.count || 0;

  return (
    <RevivnDataGrid
      {...props}
      autoHeight={false}
      rows={rows}
      rowCount={totalCount}
      loading={isLoading}
      pagination={true}
      page={gridState.page}
      pageSize={gridState.pageSize}
      exportUrl={exportUrl}
      paginationMode="server"
      onPageChange={(newPage) => setGridState((p) => ({ ...p, page: newPage }))}
      onPageSizeChange={(newPageSize) => {
        const currentItem = gridState.page * gridState.pageSize;
        const nextPage = Math.floor(currentItem / newPageSize);
        // https://github.com/mui/mui-x/issues/3516
        // Updating the page to keep the same records viewable results in unintentional jumping due to the
        // internal pagination model being inconsistent in MUI.  To fix, we'll need to upgrade MUI.
        setGridState((p) => ({ ...p, pageSize: newPageSize, page: nextPage }));
      }}
      rowsPerPageOptions={[5, 25, 50, 100]}
      filterMode="server"
      filterModel={gridState.filterModel}
      onFilterModelChange={(model) => {
        setGridState((p) => ({ ...p, filterModel: model, page: 0 }));
      }}
      sortingMode="server"
      sortModel={gridState.sortModel}
      onSortModelChange={(model) => {
        setGridState((p) => ({ ...p, sortModel: model, page: 0 }));
      }}
      components={{
        ...props.components,
        NoRowsOverlay,
      }}
      componentsProps={{
        ...props.componentsProps,
        noRowsOverlay: { error },
      }}
    />
  );
};
