import InfoIcon from "@mui/icons-material/Info";
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  Grid2,
  Link,
  Stack,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { RevivnFormTextField } from "components/Form/RevivnFormTextField";
import Tags from "components/Inventories/Tags";
import { colors } from "constants/colors";
import { conditions } from "constants/conditions";
import { deviceTypes } from "constants/device_types";
import { enrolledStatuses } from "constants/enrolled_statuses";
import { securityLevels } from "constants/security_levels";
import { useFormikContext } from "formik";
import { ReactNode } from "react";
import { Inventory, keyboardLanguageDropdownOptions } from "types/inventory";
import { Tag } from "types/tag";
import { FunctionalityRow } from "./InventoryFunctionalityRadio";

const autocompleteProps = {
  autoHighlight: true,
  disableClearable: true,
  disablePortal: true,
  openOnFocus: true,
} as const;

type TechFormAutocompleteProps = Omit<AutocompleteProps<string, false, true, false, "div">, "renderInput"> & {
  field: keyof Inventory;
  label: ReactNode;
  variant?: "standard" | "outlined" | "filled";
};

const TechFormAutocomplete = ({ field, label, variant, ...props }: TechFormAutocompleteProps) => {
  const { errors, handleBlur, setFieldValue, values } = useFormikContext<{ inventory: Inventory }>();

  return (
    <Autocomplete
      {...autocompleteProps}
      {...props}
      onBlur={handleBlur}
      onChange={(_, value) => setFieldValue(`inventory.${field}`, value)}
      value={props.options.find((option) => option === values.inventory[field])}
      renderInput={(textFieldProps: TextFieldProps) => (
        <RevivnFormTextField
          {...textFieldProps}
          name={`inventory.${field}`}
          label={label}
          error={!!errors.inventory?.[field]}
          helperText={errors.inventory?.[field]}
          variant={variant}
        />
      )}
    />
  );
};

const tooltipIcon = <InfoIcon fontSize="inherit" color="info" sx={{ marginLeft: 0.5 }} />;

export const TechnicianFormFunctionalityAttributes = () => {
  const { errors, handleBlur, setFieldValue, values } = useFormikContext<{ inventory: Inventory }>();

  return (
    <>
      <Grid2 size={{ xs: 2, sm: 1 }}>
        <TechFormAutocomplete
          field="condition"
          options={conditions}
          value={conditions.find((option) => option === values.inventory.condition)}
          label={
            <Tooltip
              title="A cumulative grade of dents, dings, scratches, visible wear, laminate damage and worn keys. From 'near perfect' A to 'severely damaged' D. This is not an indicator of the unit's functionality."
              placement="right"
              enterDelay={500}
            >
              <span>
                Cosmetic Grade
                {tooltipIcon}
              </span>
            </Tooltip>
          }
        />
      </Grid2>
      <Grid2 size={{ xs: 2, sm: 1 }}>
        <TechFormAutocomplete
          field="color"
          options={colors}
          value={colors.find((option) => option === values.inventory.color)}
          label={
            <Tooltip
              title="Overall color of the device case. If multiple colors are present, select the most dominant color."
              placement="right"
              enterDelay={500}
            >
              <span>
                Color
                {tooltipIcon}
              </span>
            </Tooltip>
          }
        />
      </Grid2>
      <Grid2 size={{ xs: 2, sm: 1 }}>
        <TechFormAutocomplete
          field="securityLevel"
          options={securityLevels}
          value={securityLevels.find((option) => option === values.inventory.securityLevel)}
          label={
            <Tooltip
              title="Hardware and Software locks of all types. This is separate from mobile device management."
              placement="right"
              enterDelay={500}
            >
              <span>
                Security State
                {tooltipIcon}
              </span>
            </Tooltip>
          }
        />
      </Grid2>
      <Grid2 size={{ xs: 2, sm: 1 }}>
        <TechFormAutocomplete
          field="enrolledStatus"
          options={enrolledStatuses}
          value={enrolledStatuses.find((option) => option === values.inventory.enrolledStatus)}
          label={
            <Tooltip
              title="State of the device enrollment. Enrollment status is refreshed when the computer connects to the internet for the first time after a clean OS installation."
              placement="right"
              enterDelay={500}
            >
              <span>
                Enrolled Status
                {tooltipIcon}
              </span>
            </Tooltip>
          }
        />
      </Grid2>
      <Grid2 size={{ xs: 2, sm: 1 }}>
        <RevivnFormTextField
          fullWidth
          name="inventory.batteryCount"
          label={
            <Tooltip
              title="Cycle Count is the amount of times the battery has been through a full power cycle. Use 0 as the default value if you are unable to record the number."
              placement="right"
              enterDelay={500}
            >
              <span>
                Battery Cycle Count
                {tooltipIcon}
              </span>
            </Tooltip>
          }
          error={!!errors.inventory?.batteryCount}
          helperText={errors.inventory?.batteryCount}
        />
      </Grid2>
      <Grid2 size={{ xs: 2, sm: 1 }}>
        <RevivnFormTextField
          fullWidth
          name="inventory.batteryHealth"
          label={
            <Tooltip
              title="Battery health is the calculated percentage of the battery's charge capacity. The formula follows: Current capacity divided by the Design capacity, out of 100%."
              placement="right"
              enterDelay={500}
            >
              <span>
                Battery Health
                {tooltipIcon}
              </span>
            </Tooltip>
          }
          error={!!errors.inventory?.batteryHealth}
          helperText={errors.inventory?.batteryHealth}
        />
      </Grid2>
      <Grid2 size={2}>
        <Autocomplete
          {...autocompleteProps}
          options={keyboardLanguageDropdownOptions}
          value={keyboardLanguageDropdownOptions.find((option) => option.value === values.inventory.keyboardLanguage)}
          onBlur={handleBlur}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          onChange={(_, value) => setFieldValue("inventory.keyboardLanguage", value?.value)}
          renderInput={(textFieldProps: TextFieldProps) => (
            <RevivnFormTextField
              {...textFieldProps}
              name="inventory.keyboardLanguage"
              label={
                <Tooltip
                  title={
                    <>
                      The language of the keyboard.
                      <br />
                      <Link color="secondary" href="https://support.apple.com/en-us/102743" target="_blank">
                        How to identify your Apple keyboard layout by country or region
                      </Link>
                    </>
                  }
                  placement="right"
                  enterDelay={500}
                >
                  <span>
                    Keyboard Language
                    {tooltipIcon}
                  </span>
                </Tooltip>
              }
              error={!!errors.inventory?.keyboardLanguage}
              helperText={errors.inventory?.keyboardLanguage}
            />
          )}
        />
      </Grid2>
      <Grid2 size={2}>
        <RevivnFormTextField
          fullWidth
          multiline
          minRows={2}
          maxRows={6}
          name="inventory.comments"
          label={
            <Tooltip
              title="Any additional information about the device that is not covered by the other fields."
              placement="right"
              enterDelay={500}
            >
              <span>
                Comments
                {tooltipIcon}
              </span>
            </Tooltip>
          }
          error={!!errors.inventory?.comments}
          helperText={errors.inventory?.comments}
        />
      </Grid2>
      <Grid2 size={2}>
        <Stack direction="row">
          <Box width="8em" />
          <Stack direction="row" justifyContent="space-around" flexGrow={1}>
            <Typography variant="caption">Working</Typography>
            <Typography variant="caption">As-is</Typography>
            <Typography variant="caption">Broken</Typography>
            <Typography variant="caption">Repaired</Typography>
          </Stack>
        </Stack>
        <Stack>
          <Typography variant="overline">LCD</Typography>
        </Stack>
        <FunctionalityRow label="Display" name="screenFunctionality" />
        <FunctionalityRow label="Camera" name="cameraFunctionality" />
        <Stack>
          <Typography variant="overline">Top Case</Typography>
        </Stack>
        <FunctionalityRow label="Keyboard" name="keyboardFunctionality" />
        <FunctionalityRow label="Trackpad" name="trackpadFunctionality" />
        <FunctionalityRow label="Battery" name="batteryFunctionality" />
        <FunctionalityRow label="Microphone" name="microphoneFunctionality" />
        <FunctionalityRow label="Speaker" name="speakerFunctionality" />
        <FunctionalityRow label="Ports" name="portsFunctionality" />
        <Stack>
          <Typography variant="overline">Board</Typography>
        </Stack>
        <FunctionalityRow label="Power" name="powerFunctionality" />
        <FunctionalityRow label="Logic" name="logicFunctionality" />
      </Grid2>
      <Grid2 size={2}>
        <Typography variant="overline">Tags</Typography>
        <Tags
          tags={values.inventory.tags || []}
          onChange={(tags: Tag[]) => {
            setFieldValue("inventory.tags", tags);
            setFieldValue(
              "inventory.tagNames",
              tags.map((tag) => tag.name),
            );
          }}
        />
      </Grid2>
    </>
  );
};

export const TechnicianFormHardwareAttributes = () => {
  const { values } = useFormikContext<{ inventory: Inventory }>();

  return (
    <>
      <TechFormAutocomplete
        field="deviceType"
        options={deviceTypes}
        value={deviceTypes.find((option) => option === values.inventory.deviceType)}
        label="Device Type"
        variant="standard"
      />
      <RevivnFormTextField fullWidth label="Manufacturer" name="inventory.manufacturer" variant="standard" />
      <RevivnFormTextField fullWidth label="Serial Number" name="inventory.serialNumber" variant="standard" />
      <RevivnFormTextField fullWidth label="Model" name="inventory.model" variant="standard" />
      <RevivnFormTextField fullWidth label="Model Order" name="inventory.modelOrder" variant="standard" />
      <RevivnFormTextField fullWidth label="SKU" name="inventory.sku" variant="standard" />
      <RevivnFormTextField fullWidth label="CPU" name="inventory.cpu" variant="standard" />
      <RevivnFormTextField fullWidth label="RAM" name="inventory.ram" variant="standard" />
      <RevivnFormTextField fullWidth label="GPU" name="inventory.gpu" variant="standard" />
      <RevivnFormTextField fullWidth label="Hard Drive" name="inventory.hardDrive" variant="standard" />
      <RevivnFormTextField
        fullWidth
        label="Drive Capacity (GB)"
        name="inventory.hardDriveSizeInGb"
        variant="standard"
      />
      <RevivnFormTextField fullWidth label="Year" name="inventory.year" variant="standard" />
    </>
  );
};
