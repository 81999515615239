import {
  Box,
  FormControl,
  MenuItem,
  Autocomplete,
  TextField,
  Divider,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { AppSelect, InputBase, InputLabel } from "components/common/Input";
import { useFormikContext } from "formik";
import { defaultLogisticsPartner, LogisticsPartner } from "types/logisticsPartner";
import { LogisticsFormValues } from "../../../../types/types";

interface FreighterDetailsProps {
  mainFreighters: LogisticsPartner[];
  subFreighters: LogisticsPartner[];
  findFreighter: (id: string, type: string) => LogisticsPartner;
}

export default function FreighterDetailsForm({ mainFreighters, subFreighters, findFreighter }: FreighterDetailsProps) {
  const formik = useFormikContext<LogisticsFormValues>();

  return (
    <>
      <Box sx={{ display: "grid", gridTemplateColumns: "2fr 2fr 2fr", gap: 3 }}>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink htmlFor="freighter">
            Freighter:
          </InputLabel>
          <Autocomplete
            id="freighterId"
            size="small"
            getOptionLabel={(option: LogisticsPartner) => option.name}
            isOptionEqualToValue={(option: LogisticsPartner, value: LogisticsPartner) => option.id === value.id}
            value={findFreighter(formik.values.freighterId, "mainFreighters")}
            options={
              findFreighter(formik.values.freighterId, "mainFreighters") !== defaultLogisticsPartner
                ? mainFreighters
                : [defaultLogisticsPartner, ...mainFreighters]
            }
            autoComplete
            includeInputInList
            filterSelectedOptions
            onChange={(event, newValue: LogisticsPartner | null) => {
              formik.setFieldValue("freighterId", newValue?.id || "");
            }}
            renderInput={(params) => <TextField {...params} name="freighterId" label="Search Freighters" fullWidth />}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
          />
        </FormControl>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink htmlFor="subFreighter">
            Sub Freighter:
          </InputLabel>
          <Autocomplete
            id="subFreighterId"
            size="small"
            getOptionLabel={(option: LogisticsPartner) => option.name}
            isOptionEqualToValue={(option: LogisticsPartner, value: LogisticsPartner) => option.id === value.id}
            value={findFreighter(formik.values.subFreighterId, "subFreighters")}
            options={
              findFreighter(formik.values.subFreighterId, "subFreighters") !== defaultLogisticsPartner
                ? subFreighters
                : [defaultLogisticsPartner, ...subFreighters]
            }
            autoComplete
            includeInputInList
            filterSelectedOptions
            onChange={(event, newValue: LogisticsPartner | null) => {
              formik.setFieldValue("subFreighterId", newValue?.id || "");
            }}
            renderInput={(params) => (
              <TextField {...params} name="subFreighterId" label="Search Sub Freighters" fullWidth />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
          />
        </FormControl>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink htmlFor="palletsCount">
            # of Pallets:
          </InputLabel>
          <InputBase type="number" id="palletsCount" {...formik.getFieldProps("palletsCount")} />
        </FormControl>
      </Box>
      <Divider sx={{ padding: "10px 0" }} />
      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 3, padding: "20px 0" }}>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink htmlFor="bol">
            BOL #:
          </InputLabel>
          <InputBase id="bol" {...formik.getFieldProps("bol")} />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel htmlFor="estimatedInvoiceCostInCents">Estimated Invoice Cost</InputLabel>
          <OutlinedInput
            id="invoiceCostInCents"
            type="number"
            size="small"
            inputProps={{
              step: 0.01,
            }}
            placeholder="0.00"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            {...formik.getFieldProps("estimatedInvoiceCostInCents")}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel htmlFor="invoiceCostInCents">Invoice Cost</InputLabel>
          <OutlinedInput
            id="invoiceCostInCents"
            type="number"
            size="small"
            inputProps={{
              step: 0.01,
            }}
            placeholder="0.00"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            {...formik.getFieldProps("invoiceCostInCents")}
          />
        </FormControl>
      </Box>
      <Divider sx={{ padding: "10px 0" }} />
      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3, padding: "20px 0" }}>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink htmlFor="deliveryType">
            Delivery Type:
          </InputLabel>
          <AppSelect
            id="deliveryType"
            {...formik.getFieldProps("deliveryType")}
            label="Delivery Type"
            autoWidth
            size="small"
          >
            {["AIR", "1-2 Days", "3-5 Days", "5+ Days", "Same Day"].map((deliveryType) => {
              return (
                <MenuItem key={deliveryType} value={deliveryType}>
                  {deliveryType}
                </MenuItem>
              );
            })}
          </AppSelect>
        </FormControl>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink htmlFor="pickupDate">
            Pickup Date:
          </InputLabel>
          <InputBase type="date" id="pickupDate" size="small" {...formik.getFieldProps("pickupDate")} />
        </FormControl>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink htmlFor="dropoffEta">
            Drop Off ETA:
          </InputLabel>
          <InputBase id="dropoffEta" type="date" size="small" {...formik.getFieldProps("dropoffEta")} />
        </FormControl>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink htmlFor="dropoffDate">
            Drop Off Date:
          </InputLabel>
          <InputBase id="dropoffDate" type="date" {...formik.getFieldProps("dropoffDate")} />
        </FormControl>
      </Box>
    </>
  );
}
