import ListAltIcon from "@mui/icons-material/ListAlt";
import { IconButton, Tooltip } from "@mui/material";
import { useFormikContext } from "formik";
import { Inventory } from "types/inventory";

export const ViewPickupButton = () => {
  const { values } = useFormikContext<{ inventory: Inventory }>();
  return (
    <Tooltip disableInteractive title="View the pickup in a new tab">
      <IconButton href={`/pickups/${values.inventory.pickup.uuid}`} target="_blank">
        <ListAltIcon color="primary" />
      </IconButton>
    </Tooltip>
  );
};
