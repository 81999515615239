import {
  PersistableSettingsDataGrid,
  PersistableSettingsDataGridProps,
} from "components/DataGrid/PersistableSettingsDataGrid";
import { LinearProgress } from "@mui/material";
import * as React from "react";
import { ReactElement, useMemo, useRef, useState } from "react";
import { RevivnToolbar } from "components/DataGrid/components/RevivnToolbar";
import { useEventListener } from "hooks/useEventListener";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { GridRowId, GridRowModel } from "@mui/x-data-grid/models/gridRows";
import { GridBulkActionsItemProps } from "components/DataGrid/components/GridBulkActionsItem";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BulkEditChanges = Record<GridRowId, any>;

export interface RevivnDataGridProps extends PersistableSettingsDataGridProps {
  // a function that returns an array of bulk actions to be displayed in the toolbar.
  getBulkActions?: () => ReactElement<GridBulkActionsItemProps>[];
  // This function is called after a bulk action has been completed.
  onBulkActionComplete?: () => void;
  // If true, the grid will display an edit button that allows the user to enter bulk edit mode.
  bulkEditable?: boolean;
  // This function is called when the user clicks the "Save" button in bulk edit mode.
  // it should return a promise that resolves when the changes have been saved.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  processBulkUpdate?: (changes: BulkEditChanges) => Promise<void>;

  // The URL to use for exporting the data in the grid, if you want to use remote exporting.
  exportUrl?: string;
}

// RevivnDataGrid is the main component for displaying a DataGrid with Revivn styling and functionality.
// It remembers column preferences & filters across requests, and has support for bulk actions & editing.
export const RevivnDataGrid = (props: RevivnDataGridProps) => {
  const [bulkEditMode, setBulkEditMode] = useState(false);
  const apiRef = useGridApiRef();
  const bulkEditStartingRows = useRef<Map<GridRowId, GridRowModel>>(new Map());
  const [bulkEditChanges, setBulkEditChanges] = useState<BulkEditChanges>({});

  useEventListener("bulkEditStart", () => {
    setBulkEditMode(true);
    bulkEditStartingRows.current = apiRef.current.getRowModels();
    // Clear any selected rows (bulk actions are not relevant during bulk editing)
    apiRef.current.setSelectionModel([]);
    // Update rows is necessary to force a re-render of the grid
    apiRef.current.updateRows([]);
  });

  useEventListener("bulkEditCancel", () => {
    apiRef.current.updateRows(Array.from(bulkEditStartingRows.current.values()));
  });

  useEventListener("bulkEditStop", async () => {
    setBulkEditMode(false);
    setBulkEditChanges({}); // Clear unsaved changes
    apiRef.current.updateRows([]);
  });

  const rowsWithEdits = useMemo(() => {
    if (!props.rows) return [];
    return props.rows.map((row) => {
      if (bulkEditChanges[row.id]) {
        return { ...row, ...bulkEditChanges[row.id] };
      }
      return row;
    });
  }, [bulkEditChanges, props.rows]);

  const bulkEditStyles = bulkEditMode ? { color: "#aaa", "& a": { color: "#aaa" } } : {};

  return (
    <PersistableSettingsDataGrid
      {...props}
      rows={rowsWithEdits}
      apiRef={apiRef}
      experimentalFeatures={{ newEditingApi: true }}
      processRowUpdate={(newRow, oldRow) => {
        const changedFields = Object.keys(newRow).filter((key) => newRow[key] !== oldRow[key]);
        if (changedFields.length === 0) return newRow;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const updateBody: any = {};
        for (const field of changedFields) {
          updateBody[field] = newRow[field];
        }
        setBulkEditChanges((prev) => ({ ...prev, [newRow.id]: { ...prev[newRow.id], ...updateBody } }));
        return newRow;
      }}
      disableIgnoreModificationsIfProcessingProps
      // Disable the checkbox selection when in bulk edit mode
      checkboxSelection={!bulkEditMode && props.checkboxSelection}
      sx={{
        ".MuiDataGrid-cell:not(.MuiDataGrid-cell--editable)": bulkEditStyles,
        ".MuiDataGrid-cell--editable:has(.Mui-error.Mui-error)": {
          outline: "1px solid red",
          backgroundColor: "#ffb9b9",
        },
        ".cell-edited-unsaved": {
          backgroundColor: "#fde7a0",
        },
        ...props.sx,
      }}
      isCellEditable={(params) => {
        if (props.isCellEditable) return props.isCellEditable(params) && bulkEditMode;
        return bulkEditMode;
      }}
      getCellClassName={(params) => {
        if (props.bulkEditable) {
          if (bulkEditChanges[params.id] && bulkEditChanges[params.id][params.field]) {
            return "cell-edited-unsaved";
          } else {
            return "";
          }
        } else {
          return props.getCellClassName?.(params) || "";
        }
      }}
      components={{
        ...props.components,
        LoadingOverlay: LinearProgress,
        Toolbar: RevivnToolbar,
      }}
      componentsProps={{
        ...props.componentsProps,
        toolbar: {
          ...props.componentsProps?.toolbar,
          printOptions: { disableToolbarButton: true },
          csvOptions: { disableToolbarButton: true },
          checkboxSelection: props.checkboxSelection,
          bulkEditable: props.bulkEditable,
          bulkEditMode,
          bulkEditChanges,
          onBulkActionComplete: props.onBulkActionComplete,
          getBulkActions: props.getBulkActions,
          processBulkUpdate: props.processBulkUpdate,
          exportUrl: props.exportUrl,
        },
      }}
    />
  );
};
