import React from "react";
import { ScanButton } from "components/Picklist/common/ScanButton";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { ErrorMessage, Field } from "formik";
import { Alert, CircularProgress, Stack, Typography } from "@mui/material";
import { FieldProps } from "formik/dist/Field";
import { useIsLocal } from "hooks/useIsLocalhost";
import { FulfillmentBatch } from "hooks/useFulfillmentBatch";
import { useLoaderData, useNavigate, useRevalidator } from "react-router-dom";

export function SelectInventoryFromCartPage() {
  const { batch } = useLoaderData() as { batch: FulfillmentBatch };
  const isLocal = useIsLocal();
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();

  return (
    <RevivnApiForm
      initialValues={{ currentInventoryId: null }}
      action={`/fulfillment_batches/${batch.id}`}
      method="PATCH"
      onSuccess={() => {
        revalidate();
        navigate(`/picklist/batches/${batch.id}`);
      }}
    >
      <Stack justifyItems="center" alignItems="center" gap={1}>
        <ErrorMessage name="base">{(msg) => <Alert severity="error">{msg}</Alert>}</ErrorMessage>
        <Typography>Scan an inventory label from your cart to begin packing it.</Typography>
        {isLocal && (
          <Typography color={"grey.500"}>(devonly) Possible IDs: {batch.onCartInventoryIds?.join(", ")}</Typography>
        )}
        <Field name="currentInventoryId">
          {({ field, form }: FieldProps) => (
            <ScanButton
              onScan={(scanValue) => {
                form.setFieldValue(field.name, scanValue);
                form.submitForm();
              }}
              endIcon={form.isSubmitting ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : undefined}
              disabled={form.isSubmitting}
            >
              Scan Label
            </ScanButton>
          )}
        </Field>
      </Stack>
    </RevivnApiForm>
  );
}
