import React, { useMemo, useState } from "react";
import { InventoryImage } from "types/inventory";
import { PackInventoryLayout } from "components/Picklist/CurrentBatch/Packing/PackInventoryLayout";
import { TestInventoryStep } from "components/Picklist/CurrentBatch/Packing/Steps/TestInventoryStep";
import { SkippedStepFlow } from "components/Picklist/CurrentBatch/Packing/Steps/SkippedStepFlow";
import { PrintShippingLabelStep } from "components/Picklist/CurrentBatch/Packing/Steps/PrintShippingLabelStep";
import { CapturePhotoStep } from "components/Picklist/CurrentBatch/Packing/Steps/CapturePhotoStep";
import { CompletePackingStep } from "components/Picklist/CurrentBatch/Packing/Steps/CompletePackingStep";
import { OrderItem } from "hooks/useFulfillmentBatch";
import { useRevalidator } from "react-router-dom";
import { PackChargerStep } from "./Steps/PackChargerStep";

export enum Step {
  SkippedStep = -1,
  TestInventory,
  PackCharger,
  PrintShippingLabel,
  CapturePhoto,
  Complete,
}

interface PackInventoryWizardProps {
  item: OrderItem;
}

export function PackInventoryWizard({ item }: PackInventoryWizardProps) {
  const [image, setImage] = useState<InventoryImage | null>(null);
  const [activeStep, setActiveStep] = useState(Step.TestInventory);
  const [previousStep, setPreviousStep] = useState<Step | null>(null);
  const { revalidate } = useRevalidator();

  const stepComponent = useMemo(() => {
    switch (activeStep) {
      case Step.TestInventory:
        return (
          <TestInventoryStep
            onTestsPassed={() => {
              revalidate();
              setActiveStep(Step.PackCharger);
            }}
            onTestsFailed={() => {
              revalidate();
              setPreviousStep(Step.TestInventory);
              setActiveStep(Step.SkippedStep);
            }}
          />
        );
      case Step.PackCharger:
        return (
          <PackChargerStep
            item={item}
            onChargerPacked={() => {
              revalidate();
              setActiveStep(Step.PrintShippingLabel);
            }}
            onSkip={() => {
              revalidate();
              setPreviousStep(Step.PackCharger);
              setActiveStep(Step.SkippedStep);
            }}
          />
        );
      case Step.SkippedStep:
        return (
          <SkippedStepFlow
            onCancel={() => {
              revalidate();
              setActiveStep(previousStep === Step.PackCharger ? Step.PackCharger : Step.TestInventory);
            }}
            noChargerReason={previousStep === Step.PackCharger ? "Charger/Packing Issue" : undefined}
          />
        );
      case Step.PrintShippingLabel:
        return (
          <PrintShippingLabelStep
            inventory={item}
            onClick={() => {
              revalidate();
              setActiveStep(Step.CapturePhoto);
            }}
          />
        );
      case Step.CapturePhoto:
        return (
          <CapturePhotoStep
            inventory={item}
            onImageCaptured={(img) => {
              revalidate();
              setImage(img);
              setActiveStep(Step.Complete);
            }}
          />
        );
      case Step.Complete:
        return (
          <CompletePackingStep
            inventory={item}
            onImageRecapture={(img) => {
              revalidate();
              setImage(img);
            }}
          />
        );
    }
  }, [activeStep, item, revalidate, previousStep]);

  return (
    <PackInventoryLayout activeStep={activeStep} inventory={item} image={image}>
      {stepComponent}
    </PackInventoryLayout>
  );
}
