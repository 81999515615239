import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { fetcher } from "services/api.service";
import { OrderItem } from "hooks/useFulfillmentBatch";

export async function loader({ params, request }: LoaderFunctionArgs) {
  const { batchId, itemId } = params;
  const item = await fetcher<OrderItem>(`/fulfillment_batches/${batchId}/items/${itemId}`);

  if (item.itemState === "cancelled") return redirect(`../cancelled`);

  const searchParams = new URL(request.url).searchParams;
  if (item.itemState === "on_cart" && searchParams.get("step") !== "complete") return redirect("?step=complete");

  return { item };
}
