import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { Button, Stack } from "@mui/material";
import React from "react";
import { PrintLabelButton } from "components/Picklist/common/PrintLabelButton";
import { CapturePhotoButton } from "components/Picklist/common/CapturePhotoButton";
import { Inventory, InventoryImage } from "types/inventory";
import { useNavigate, useParams, useRevalidator } from "react-router-dom";

export function CompletePackingStep(props: {
  inventory: Inventory;
  onImageRecapture: (image: InventoryImage) => void;
}) {
  const { batchId, itemId } = useParams();
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();
  return (
    <>
      <Stack direction="row" justifyContent="space-between" gap={1} alignSelf="stretch" mb={1}>
        <PrintLabelButton inventory={props.inventory}>Re-Print Label</PrintLabelButton>
        <CapturePhotoButton inventory={props.inventory} onImageCaptured={props.onImageRecapture}>
          Re-capture photo
        </CapturePhotoButton>
      </Stack>
      <RevivnApiForm
        initialValues={{}}
        action={`fulfillment_batches/${batchId}/items/${itemId}/complete_packing`}
        method="POST"
        onSuccess={() => {
          revalidate();
          navigate(`/picklist/batches/${batchId}`, { replace: true });
        }}
        onError={() => {
          revalidate();
        }}
      >
        <Button type="submit" variant="contained">
          Next
        </Button>
      </RevivnApiForm>
    </>
  );
}
