import { BatchState } from "hooks/useFulfillmentBatch";
import { FulfillmentBatch } from "hooks/useFulfillmentBatch";
import { fetcher } from "services/api.service";
import { LoaderFunctionArgs } from "react-router-dom";
import { redirect } from "react-router-dom";

export async function loader({ params }: LoaderFunctionArgs) {
  const { batchId } = params;

  const batch = await fetcher<FulfillmentBatch>(`/fulfillment_batches/${batchId}`);
  if (batch.state !== BatchState.Packing) return redirect(`/picklist/batches/${batchId}`);
  if (batch.currentItemId) return redirect(`/picklist/batches/${batchId}/items/${batch.currentItemId}/pack`);
  return { batch };
}
