import { Box, Chip, Stack, Tab, Tabs, TabsProps } from "@mui/material";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import useSWR from "swr";
import { CollectionLike } from "services/api.service";

function useFilterParamsMatchValue(patterns: readonly string[]) {
  const [searchParams] = useSearchParams();

  const matchIndex = patterns.indexOf(searchParams.getAll("filter[]").join(" AND "));
  if (matchIndex < 0) return patterns.indexOf("__any__");
  return matchIndex;
}

interface NavFilterTabProps {
  url?: string;
  filterString: string;
  label: string;
}

const NavFilterTab = ({ url, filterString, label, ...props }: NavFilterTabProps) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const params = useMemo(() => {
    const nextParams = new URLSearchParams(searchParams);
    nextParams.delete("filter[]");
    const filters = filterString.split(" AND ");
    filters.forEach((f) => nextParams.append("filter[]", f));
    return nextParams;
  }, [filterString, searchParams]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data, isLoading } = useSWR<CollectionLike<any>>(
    () => {
      if (!url || filterString === "__any__") return null;
      const filterParams = new URLSearchParams(params);

      // Remove all non-filter params
      filterParams.delete("page[size]");
      filterParams.delete("page[number]");
      filterParams.delete("sort");
      filterParams.delete("size");
      return url.includes("?") ? `${url}&${params}&page[size]=1` : `${url}?${params}&page[size]=1`;
    },
    {
      refreshInterval: 10000,
    },
  );

  if (filterString === "__any__") {
    return <Tab component={Link} to={`${location.pathname}`} label={label} {...props} />;
  }

  return (
    <Tab
      component={Link}
      to={`${location.pathname}?${params}`}
      label={
        <Stack alignItems={"center"} direction={"row"} gap={1}>
          <Box>{label}</Box>
          {!isLoading && <Chip label={data?.meta.count} size={"small"} color={"primary"} />}
        </Stack>
      }
      {...props}
    />
  );
};

interface SavedFilterTabProps {
  url?: string;
  savedFilters: { label: string; filters: string }[];
}

// This component is used to render a set of tabs that represent saved filters.
// The tabs will show the count of items that match the filter.
// The filter modifies the URL which can update the datagrid (assuming the datagrid is synced with the URL)
// If you are not syncing the datagrid with the URL, it will not update the datagrid.
export const DatabaseDataGridSavedFilterTabs = ({ savedFilters, url, ...props }: SavedFilterTabProps & TabsProps) => {
  const filters = savedFilters.map((tab) => tab.filters);
  const value = useFilterParamsMatchValue(filters);

  return (
    <Tabs value={value} {...props}>
      {savedFilters.map((tab) => (
        <NavFilterTab url={url} key={tab.filters} filterString={tab.filters} label={tab.label} />
      ))}
    </Tabs>
  );
};
