import { ShipstationOrderItem } from "types/shipstationOrderItem";
import { GridActionsColDef, GridColTypeDef, ValueOptions } from "@mui/x-data-grid-pro";
import { GenerateReplacementRowAction } from "components/Fulfillment/GenerateReplacementRowAction";
import { ManualReplacementRowAction } from "components/Fulfillment/ManualReplacementRowAction";
import { MarkAsPackedRowAction } from "components/Fulfillment/MarkAsPackedRowAction";
import { MarkAsHighPriorityRowAction } from "components/Fulfillment/MarkAsHighPriorityRowAction";
import { Stack, Tooltip, Typography } from "@mui/material";
import { pluralize } from "utils/helpers";
import { singleSelectColDef } from "components/DataGrid/columnDefs/singleSelectColDef";
import { dateColDef } from "components/DataGrid/columnDefs/dateColDef";
import { Warning } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { getShipByLabel } from "utils/shipBy";
import { dateTimeColDef } from "components/DataGrid/columnDefs/dateTimeColDef";

const warehouseOptions = [
  { value: "New York", label: "NY" },
  { value: "San Francisco", label: "SF" },
];

function getEmojiForState(orderItem: ShipstationOrderItem) {
  switch (orderItem.state.toLowerCase()) {
    case "created":
      if (orderItem.inventoryItem === null) return "🔍";
      if (orderItem.addressVerifiedAt === null) return "⚠️";
      if (orderItem.lastAssignedUser === null) return "🕕";
      return "👷";
    case "skipped":
      return "🟡️";
    case "packed":
      return "📦";
    case "on_cart":
      return "🛒";
    case "cancelled":
      return "⛔️";
    default:
      return "";
  }
}

export const subStatusCalculation = (row: ShipstationOrderItem) => {
  const hasInventory = row.inventoryItem !== null;
  const isPacked = row.state === "packed";
  const isOpen = row.state === "created";

  if (isOpen) {
    return !hasInventory
      ? "Looking for Inventory"
      : row.addressVerifiedAt === null
        ? "Address issue"
        : row.lastAssignedUser !== null
          ? "Fulfillment Started"
          : "Awaiting Batch";
  }

  if (isPacked && row.shipByDate && row.packedAt) {
    if (row.packedAt <= row.shipByDate) {
      return "On Time";
    } else {
      return "Late";
    }
  }

  return null;
};

const itemStateColDef: GridColTypeDef = {
  renderCell: (params) => {
    const color = params.value === "No inventory assigned" ? "gray" : "default";
    const isError = params.value === "Skipped";
    const isPacked = params.value === "Packed";
    const isManuallyPacked = isPacked && params.row.packedReason;

    return (
      <Stack color={color} direction={"row"} gap={1}>
        <Typography>{getEmojiForState(params.row)}</Typography>
        {params.value} {subStatusCalculation(params.row)}
        {isError && <Typography color="error">{params.row.failureReason}</Typography>}
        {isManuallyPacked && (
          <Tooltip title={`Packed by: ${params.row.packedBy}`} placement="top" disableInteractive={true}>
            <Typography color="gray" sx={{ textDecoration: "underline", textDecorationStyle: "dotted" }}>
              {params.row.packedReason}
            </Typography>
          </Tooltip>
        )}
      </Stack>
    );
  },
  sortable: false,
};
const warehouseColDef: GridColTypeDef = {
  type: "singleSelect",
  sortable: false,
  valueGetter: ({ value }) => warehouseOptions.find((option) => option.value === value)?.label ?? value,
  valueOptions: warehouseOptions,
};
const inventoryItemColDef: GridColTypeDef = {
  sortable: false,
  renderCell: (params) => {
    return <Link to={`/inventories/${params.value}`}>{params.value}</Link>;
  },
};
const ageColDef: GridColTypeDef = {
  sortable: true,
  filterable: false,
  renderCell: (params) => {
    if (!params.row.addressVerifiedAt) return null;
    const addressVerifiedAt = new Date(params.row.addressVerifiedAt);
    const now = new Date();
    const diffTime = Math.abs(now.getTime() - addressVerifiedAt.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
    if (diffHours < 48) return <Typography>{pluralize(diffHours, "hour", "hours")}</Typography>;
    const diffMinutes = Math.ceil(diffTime / (1000 * 60));
    if (diffMinutes < 60) return <Typography>{pluralize(diffMinutes, "minute", "minutes")}</Typography>;

    return <Typography>{diffDays} days</Typography>;
  },
};
const shipByDef: GridColTypeDef = {
  ...dateTimeColDef,
  sortable: true,
  filterable: true,
  renderCell: (params) => {
    if (!params.row.shipByDate) {
      return "-";
    }

    return (
      <Tooltip title={new Date(params.row.shipByDate).toString()}>
        <Typography>
          {getShipByLabel(params.row.shipByDate, params.row.warehouseTimezone, params.row.state) || "-"}
        </Typography>
      </Tooltip>
    );
  },
};
const actionColDef: GridActionsColDef<ShipstationOrderItem> = {
  field: "actions",
  sortable: false,
  pinnable: true,
  type: "actions",
  align: "right",
  getActions: (params) => [
    <GenerateReplacementRowAction row={params.row} />,
    <ManualReplacementRowAction row={params.row} />,
    <MarkAsPackedRowAction row={params.row} showInMenu={true} />,
    <MarkAsHighPriorityRowAction row={params.row} showInMenu={true} />,
  ],
};
const addressVerifiedAtColDef: GridColTypeDef = {
  ...dateColDef,
  renderCell: (params) => {
    if (!params.value)
      return (
        <Tooltip title={params.row.addressVerification} placement="top">
          <Stack alignItems="center" direction="row" gap={1}>
            Not Validated <Warning color="warning" fontSize="small" />
          </Stack>
        </Tooltip>
      );
    return dateColDef.renderCell?.(params);
  },
};
const stateValueOptions: ValueOptions[] = [
  { value: "created", label: "Open" },
  { value: "skipped", label: "Skipped" },
  { value: "packed", label: "Packed" },
  { value: "on_cart", label: "On Cart" },
  { value: "cancelled", label: "Cancelled" },
];
export const columns = [
  { field: "id", headerName: "ID", width: 100 },
  { field: "age", headerName: "Age", width: 100, ...ageColDef },
  { field: "shipBy", headerName: "Ship By", width: 100, ...shipByDef },
  { field: "orderNumber", headerName: "Order Number", width: 150 },
  { field: "state", headerName: "State", width: 300, ...singleSelectColDef(stateValueOptions), ...itemStateColDef },
  { field: "addressVerifiedAt", headerName: "Address Validation", width: 150, ...addressVerifiedAtColDef },
  { field: "orderedAt", headerName: "Order Date", width: 150, ...dateColDef },
  { field: "sku", headerName: "Order SKU", width: 150, sortable: false },
  { field: "channelName", headerName: "Channel", width: 150 },
  { field: "lastAssignedUser", headerName: "Last User Assigned", width: 200 },
  { field: "warehouse", headerName: "Warehouse", ...warehouseColDef },
  { field: "inventoryItem", headerName: "Inventory Item", width: 150, ...inventoryItemColDef },
  { headerName: "Actions", width: 140, ...actionColDef },
];
