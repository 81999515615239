import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, TextField, Typography } from "@mui/material";
import { useFormikContext } from "formik";

const feedbackTypes = [
  { value: "late_arrival", label: "Late Arrival" },
  { value: "material_shortage", label: "Material Shortage" },
  { value: "list_item", label: "List Item" },
  { value: "unfinished_work", label: "Unfinished Work" },
  { value: "behavior", label: "Behavior" },
  { value: "agent_reschedule", label: "Agent Reschedule" },
];

interface FormValues {
  pickupFeedback: string[];
}

export default function PickupPerformance() {
  const { values, setFieldValue, getFieldProps } = useFormikContext<FormValues>();
  const pickupFeedback = values.pickupFeedback || [];

  const handleCheckboxChange = (feedbackValue: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (!pickupFeedback.includes(feedbackValue)) {
        setFieldValue("pickupFeedback", [...pickupFeedback, feedbackValue]);
      }
    } else {
      setFieldValue(
        "pickupFeedback",
        pickupFeedback.filter((value) => value !== feedbackValue),
      );
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <FormControl component="fieldset" variant="standard">
        <Typography variant="body2" color="text.secondary">
          Issues Reported:
        </Typography>
        <FormGroup sx={{ gap: 2, mb: 3 }}>
          {feedbackTypes.map((type) => (
            <FormControlLabel
              key={type.value}
              control={
                <Checkbox checked={pickupFeedback.includes(type.value)} onChange={handleCheckboxChange(type.value)} />
              }
              label={type.label}
            />
          ))}
        </FormGroup>
        <Typography variant="body2" color="text.secondary">
          Incident notes:
        </Typography>
        <TextField id="feedbackNotes" multiline fullWidth rows={4} {...getFieldProps("feedbackNotes")} />
      </FormControl>
    </Box>
  );
}
