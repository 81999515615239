import axios, { CancelTokenSource } from "axios";
import _ from "lodash";
import { objectToCamel, objectToSnake } from "ts-case-convert";
import { Contact } from "types/contact";
import TokenService from "./token.service";
import { ResponseWithMeta } from "types/paginatedResponse";

export const HOSTNAME = process.env.REACT_APP_API_BASE_URL || "";
const IS_LOCAL = process.env.REACT_APP_VERCEL_ENV === undefined;

export const api = axios.create({
  baseURL: `${HOSTNAME}api/admin/v1`,
});

const requestCancelSourcesByKey: { [cancelKey: string]: CancelTokenSource } = {};

export const cancelRequest = (cancelKey: string) => {
  const cancelSource = requestCancelSourcesByKey[cancelKey];
  if (cancelSource) {
    cancelSource.cancel();

    delete requestCancelSourcesByKey[cancelKey];
  }
};

function showRailsError(data: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(data, "text/html");
  const errorDialog = document.createElement("dialog");
  errorDialog.id = "error-dialog";
  const stylesFromErrorPage = `@scope { ${doc.querySelector("style")?.innerHTML} }`;
  const scopedStyles = stylesFromErrorPage.replaceAll("body", ":scope");
  // Wrap styles in a @scope to prevent them from affecting the rest of the page

  if (document.querySelector("#error-dialog")) return;
  errorDialog.innerHTML = doc.querySelector("body")?.innerHTML || "";
  errorDialog.insertAdjacentHTML("afterbegin", `<style>${scopedStyles}</style>`);
  errorDialog.style.margin = "auto";
  document.querySelector("body")?.appendChild(errorDialog);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (errorDialog as any).showModal();
  errorDialog.addEventListener("close", () => {
    errorDialog.remove();
  });
}

export const emptyStringsToNull = (data: Contact) => {
  return _.mapValues(data, (value) => (value === "" ? null : value));
};

api.interceptors.request.use(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (config: any) => {
    if (config.cancelKey) {
      cancelRequest(config.cancelKey);
      const newCancelSource = axios.CancelToken.source();
      requestCancelSourcesByKey[config.cancelKey] = newCancelSource;
      config.cancelToken = newCancelSource.token;
    }
    if (config.data && config.headers["Content-Type"] !== "multipart/form-data") {
      config.data = objectToSnake(config.data);
      if (config.data.donation_request?.address_2) {
        config.data.donation_request.address2 = config.data.donation_request.address_2;
        delete config.data.donation_request.address_2;
      }

      if (config.data.location?.address_2) {
        config.data.location.address2 = config.data.location.address_2;
        delete config.data.location.address_2;
      }
      if (config.data.contact) {
        config.data.contact = emptyStringsToNull(config.data.contact);
      }
    }
    if (TokenService.get("accessToken")) config.headers["access-token"] = TokenService.get("accessToken");
    if (TokenService.get("client")) config.headers.client = TokenService.get("client");
    if (TokenService.get("uid")) config.headers.uid = TokenService.get("uid");
    if (TokenService.get("expiry")) config.headers.expiry = TokenService.get("expiry");
    config.headers["token-type"] = "Bearer";

    return config;
  },
  (error: Error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  function (response) {
    return { ...response, data: objectToCamel(response.data) };
  },
  function (error) {
    if (error.response?.status === 401) {
      TokenService.remove("accessToken");
      window.location.href = "/login";
    }

    if (IS_LOCAL && error.response?.status === 500 && error.response.headers["content-type"].startsWith("text/html")) {
      showRailsError(error.response.data);
    }
    return Promise.reject(error);
  },
);
export const fetcher = <T>(url: string) => api.get<T>(url).then((res) => res.data);

interface PaginationMeta {
  count: number;
}

export type CollectionLike<T> = {
  [key: string]: T[];
} & { meta: PaginationMeta };

export const collectionFetcher = <T>(url: string) =>
  fetcher<CollectionLike<T>>(url).then((data) => {
    const collectionKey = Object.keys(data).find((key) => key !== "meta");
    if (!collectionKey) throw new Error("Unable to extract collection key from response");
    return data[collectionKey];
  });

export type WrappedCollection<T> = {
  rows: T[];
} & ResponseWithMeta;

// This fetcher returns a response that is guaranteed to have a `rows` property that you can call to get the collection
// data
export const wrappedCollectionFetcher = <T>(url: string) =>
  fetcher<CollectionLike<T>>(url).then((data) => {
    return new Proxy(data, {
      get(target, prop, receiver) {
        if (prop === "rows" && !target.rows) {
          const collectionKey = Object.keys(target).find((key) => key !== "meta");
          if (!collectionKey) throw new Error("Unable to locate collection key in response");
          return target[collectionKey];
        }
        return Reflect.get(target, prop, receiver);
      },
    }) as unknown as WrappedCollection<T>;
  });
