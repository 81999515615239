import * as React from "react";
import { ReactElement } from "react";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarProps,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { GridBulkEditToolbarButtons } from "components/DataGrid/components/GridBulkEditToolbarButtons";
import { GridBulkActionsButton } from "components/DataGrid/components/GridBulkActionsButton";
import { BulkEditChanges } from "components/DataGrid/RevivnDataGrid";
import { GridRemoteDataExport } from "components/DataGrid/components/GridRemoteDataExport";
import { Box, Typography } from "@mui/material";
import { useHotkeys } from "react-hotkeys-hook";

export const dispatchBulkActionComplete = () => {
  const event = new CustomEvent("bulkActionComplete");
  window.dispatchEvent(event);
};

export const dispatchToolbarEvent = (eventName: string) => {
  const event = new CustomEvent(eventName);
  window.dispatchEvent(event);
};

export interface RevivnToolbarProps extends GridToolbarProps {
  getBulkActions?: () => ReactElement[];
  onBulkActionComplete?: () => void;
  checkboxSelection?: boolean;
  bulkEditable?: boolean;
  bulkEditMode?: boolean;
  bulkEditChanges?: BulkEditChanges;
  processBulkUpdate?: (changes: BulkEditChanges) => Promise<void>;
  exportUrl?: string;
  showQuickFilter?: boolean;
}

const RevivnToolbarQuickFilter = () => {
  const ref = React.useRef<HTMLInputElement | null>(null);
  useHotkeys(
    "/",
    () => {
      ref.current?.focus();
    },
    { enableOnFormTags: false, keyup: true },
  );
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        flexGrow: 1,
        position: "relative",
        ':has(.Mui-focused),:not(:has([value=""]))': {
          ".hotkey": { display: "none" },
        },
      }}
    >
      <Box
        className={"hotkey"}
        sx={{
          border: "1px solid lightgray",
          borderRadius: 1,
          paddingBlock: 0.25,
          paddingInline: 1,
          position: "absolute",
          top: 2,
        }}
      >
        <Typography fontSize={"x-small"} color={"gray"}>
          /
        </Typography>
      </Box>
      <GridToolbarQuickFilter inputRef={ref} placeholder={"Quick Search..."} />
    </Box>
  );
};

export const RevivnToolbar = (props: RevivnToolbarProps) => {
  return (
    <GridToolbarContainer sx={props.sx}>
      {props.checkboxSelection && <GridBulkActionsButton {...props} />}
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      {props.exportUrl ? <GridRemoteDataExport {...props} /> : <GridToolbarExport {...props} />}
      <GridBulkEditToolbarButtons {...props} />
      {props.showQuickFilter && <RevivnToolbarQuickFilter />}
    </GridToolbarContainer>
  );
};
