import { CurrentUser } from "hooks/useCurrentUser";
import { BatchState } from "hooks/useFulfillmentBatch";
import { redirect } from "react-router-dom";
import { fetcher } from "services/api.service";

export async function loader() {
  const { currentFulfillmentBatch: batch } = await fetcher<CurrentUser>("/current_user");
  if (batch && batch.state !== BatchState.Complete) {
    return redirect(`/picklist/batches/${batch.id}`);
  }
  return null;
}
