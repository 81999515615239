import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  redirect,
  Route,
  RouterProvider,
} from "react-router-dom";
import StockImagesUploader from "pages/StockImagesUploader";
import PickupsPage from "pages/PickupsPage";
import AssetRetrievals from "pages/AssetRetrievalPage";
import InventoriesPage from "pages/InventoriesPage";
import EnrolledInventoriesPage from "pages/EnrolledInventoriesPage";
import InventoryTemplatesPage from "pages/InventoryTemplatesPage";
import TechnicianInventoryPage from "pages/TechnicianInventoryPage";
import NewTechnicianInventoryPage from "pages/NewTechnicianInventoryPage";
import ScanPage from "pages/ScanPage";
import ReceivingPage from "pages/ReceivingPage";
import OutboundPage from "pages/OutboundPage";
import { getTomorrowAt10AMISO, OrdersPage } from "pages/OrdersPage";
import { BatchesPage } from "pages/BatchesPage";
import { PicklistLayout } from "pages/Layouts/PicklistLayout";
import { PicklistNewPage } from "pages/Picklist/PicklistNewPage";
import DriversPage from "pages/DriversPage";
import ScanAndDiscover from "pages/ScanAndDiscover";
import VirtualPickup from "pages/VirtualPickup";
import InventoryUploadsPage from "pages/InventoryUploadsPage";
import InventoryPricerUploadsPage from "pages/InventoryPricerUploadsPage";
import ListingsPage from "pages/ListingsPage";
import ProductCatalogsPage from "pages/ProductCatalogsPage";
import { ScanInventoryPage } from "pages/PhotoBooth/ScanInventoryPage";
import { ConfigurationPage } from "pages/PhotoBooth/ConfigurationPage";
import { preload } from "swr";
import { fetcher } from "services/api.service";
import LogisticsPage from "pages/LogisticsPage";
import FinancePage from "pages/FinancePage";
import BenefitingOrganizationsPage from "pages/BenefitingOrganizationsPage";
import DonationRequestsPage from "pages/DonationRequestsPage";
import SupportPage from "pages/SupportPage";
import React from "react";
import { userHasPermission } from "utils/userPermissions";
import useCurrentUser, { CurrentUser } from "hooks/useCurrentUser";
import { CapturePhotosPage } from "pages/PhotoBooth/CapturePhotosPage";
import { ReviewPhotosPage } from "pages/PhotoBooth/ReviewPhotosPage";
import { PhotoBoothLayout } from "pages/Layouts/PhotoBoothLayout";
import { LoggedInLayout } from "pages/Layouts/LoggedInLayout";
import { AssetRetrievalEditPage } from "pages/AssetRetrievalEditPage";
import { FulfillmentPage } from "pages/FulfillmentPage";
import { StartBatchPage } from "pages/Picklist/Batch/StartBatchPage";
import { PickItemPage } from "pages/Picklist/Batch/PickItemPage";
import { PackItemPage } from "pages/Picklist/Batch/PackItemPage";
import { PickingCompletePage } from "pages/Picklist/Batch/PickingCompletePage";
import { SelectInventoryFromCartPage } from "pages/Picklist/Batch/SelectInventoryFromCartPage";
import { BatchCompletePage } from "pages/Picklist/Batch/BatchCompletePage";
import { ItemCancelledPage } from "pages/Picklist/Batch/ItemCancelledPage";
import { PicklistBatchItemLayout } from "pages/Picklist/Batch/Layouts/PicklistBatchItemLayout";
import { PicklistBatchLayout } from "pages/Picklist/Batch/Layouts/PicklistBatchLayout";

import { loader as picklistIndexRouter } from "routes/picklist/root";
import { loader as picklistNewLoader } from "routes/picklist/new";
import { loader as picklistBatchStartLoader } from "routes/picklist/batches/start";
import { loader as picklistBatchRootLoader } from "routes/picklist/batches/root";
import { loader as picklistBatchLayoutLoader } from "routes/picklist/batches/layout";
import { loader as picklistBatchPackingSelectItemLoader } from "routes/picklist/batches/packingSelectItem";
import { loader as picklistBatchPickingCompleteLoader } from "routes/picklist/batches/pickingComplete";
import { loader as picklistBatchItemLayoutLoader } from "routes/picklist/batches/items/layout";
import { loader as picklistBatchItemPickLoader } from "routes/picklist/batches/items/pick";
import { loader as picklistBatchItemPackLoader } from "routes/picklist/batches/items/pack";
import { NewPickupsPage } from "pages/NewPickupsPage";
import { DatagridPageLayout } from "pages/Layouts/DatagridPageLayout";
import { useFeatureFlag } from "hooks/useFeatureFlag";

const PermissionedRoute = ({ children, resource }: { children?: React.ReactNode; resource: string }) => {
  const { currentUserPermissions } = useCurrentUser();
  if (userHasPermission(resource, currentUserPermissions)) {
    return <>{children}</>;
  } else {
    return <> You do not have permission to view this page</>;
  }
};

const TechnicianPageElement = () => {
  const { enabled } = useFeatureFlag("tech_page_refresh");
  return enabled ? <NewTechnicianInventoryPage /> : <TechnicianInventoryPage />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<LoggedInLayout />} errorElement={<LoggedInLayout />}>
      <Route index element={<Navigate replace={true} to="/pickups" />} />
      <Route path="/login" element={<Navigate replace={true} to={"/pickups"} />} />
      <Route path="/phoenix_login" element={<Navigate replace={true} to={"/pickups"} />} />
      <Route path="/stock-photos" element={<StockImagesUploader />} />
      <Route path="/pickups/*" element={<PickupsPage />} />
      <Route element={<DatagridPageLayout />}>
        <Route path="/new_pickups" element={<NewPickupsPage />} />
      </Route>
      <Route path="/asset_retrievals/*" element={<AssetRetrievals />} />
      <Route path="/asset_retrievals/:assetRetrievalUuid" element={<AssetRetrievalEditPage />} />
      <Route path="/inventories/*" element={<InventoriesPage />} />
      <Route path="/enrolled" element={<EnrolledInventoriesPage />} />
      <Route path="/inventory-templates" element={<InventoryTemplatesPage sx={{ mx: 10, my: 8 }} />} />
      <Route path="/inventory-templates/:templateUuid" element={<InventoryTemplatesPage sx={{ mx: 10, my: 8 }} />} />
      <Route path="/inventories/:inventoryId" element={<TechnicianInventoryPage />} />
      <Route path="/scan" element={<ScanPage />} />
      <Route path="/technician" element={<TechnicianPageElement />}>
        <Route path=":inventoryId">
          <Route path="inventory-parts/:type" />
        </Route>
      </Route>
      <Route path="/receiving/*" element={<ReceivingPage />}></Route>
      <Route path="/outbound" element={<OutboundPage />}></Route>
      <Route path="/fulfillment" element={<FulfillmentPage />} />
      <Route path="/orders" element={<OrdersPage />}>
        <Route
          path="due-today"
          loader={async () => {
            const { warehouseName, warehouseTimezone } = await preload<CurrentUser>("/current_user", fetcher);
            const tomorrowAt10AMISO = getTomorrowAt10AMISO(warehouseTimezone);
            return redirect(
              `/orders?sort=shipBy&filter[]=ship_by,on_or_before,${tomorrowAt10AMISO}&filter[]=warehouse,is,${warehouseName}&filter[]=state,not,packed`,
            );
          }}
        />
      </Route>
      <Route path="/batches" element={<BatchesPage />} />
      <Route path="/picklist" element={<PicklistLayout />}>
        <Route index loader={picklistIndexRouter} />
        <Route path="new" element={<PicklistNewPage />} loader={picklistNewLoader} />
        <Route path="batches/:batchId/start" element={<StartBatchPage />} loader={picklistBatchStartLoader} />
        <Route path="batches/:batchId" loader={picklistBatchRootLoader} />
        <Route
          path="batches/:batchId"
          element={<PicklistBatchLayout />}
          loader={picklistBatchLayoutLoader}
          id={"picklist-batch"}
        >
          <Route path="complete" element={<BatchCompletePage />} />
          <Route
            path="packing-select-item"
            element={<SelectInventoryFromCartPage />}
            loader={picklistBatchPackingSelectItemLoader}
          />
          <Route
            path="picking-complete"
            element={<PickingCompletePage />}
            loader={picklistBatchPickingCompleteLoader}
          />
          <Route
            path="items/:itemId"
            element={<PicklistBatchItemLayout />}
            loader={picklistBatchItemLayoutLoader}
            id="picklist-batch-item"
          >
            <Route path="cancelled" element={<ItemCancelledPage />} />
            <Route path="pick" element={<PickItemPage />} loader={picklistBatchItemPickLoader} />
            <Route path="pack" element={<PackItemPage />} loader={picklistBatchItemPackLoader} />
          </Route>
        </Route>
      </Route>
      <Route path="/drivers" element={<DriversPage />}></Route>
      <Route path="/scan-and-discover" element={<ScanAndDiscover />}></Route>
      <Route path="/virtual-pickup" element={<VirtualPickup />}></Route>
      <Route path="/inventory-uploads" element={<InventoryUploadsPage />}></Route>
      <Route path="/inventory-pricer-uploads" element={<InventoryPricerUploadsPage />}></Route>
      <Route path="/listings/*" element={<ListingsPage />} />
      <Route path="/product_catalogs/*" element={<ProductCatalogsPage />} />
      <Route path="/resale-photo-booth" element={<PhotoBoothLayout />}>
        <Route index element={<ScanInventoryPage />} />
        <Route path="configuration" element={<ConfigurationPage />} />
        <Route
          path=":inventoryId"
          errorElement={<ScanInventoryPage />}
          loader={async ({ params }) => {
            return preload(`/inventories/${params.inventoryId}`, fetcher);
          }}
        >
          <Route index element={<Navigate replace={true} to="capture/1" />} />
          <Route path="capture/:photoSet" element={<CapturePhotosPage />} />
          <Route path="review" element={<ReviewPhotosPage />} />
        </Route>
      </Route>

      <Route
        path="/logistics/*"
        element={
          <PermissionedRoute resource={"logistics"}>
            <LogisticsPage />
          </PermissionedRoute>
        }
      />
      <Route
        path="/finance/*"
        element={
          <PermissionedRoute resource={"finance"}>
            <FinancePage />
          </PermissionedRoute>
        }
      />
      <Route
        path="/benefiting-organization/*"
        element={
          <PermissionedRoute resource={"tech_access"}>
            <BenefitingOrganizationsPage />
          </PermissionedRoute>
        }
      ></Route>
      <Route
        path="/donation-requests/*"
        element={
          <PermissionedRoute resource={"tech_access"}>
            <DonationRequestsPage />
          </PermissionedRoute>
        }
      ></Route>
      <Route
        path="/support/*"
        element={
          <PermissionedRoute resource={"support"}>
            <SupportPage />
          </PermissionedRoute>
        }
      ></Route>
    </Route>,
  ),
);
export default function LoggedInRouter() {
  return <RouterProvider router={router} />;
}
