import { Close } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Modal, Typography } from "@mui/material";
import { deserializeFilterParamsToFilterModel } from "components/DataGrid/helpers/deserializers";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { SubmitAwareButton } from "components/Form/SubmitAwareButton";
import { Field } from "formik";
import { useSearchParam } from "hooks/useSearchParam";
import { useSearchParamArray } from "hooks/useSearchParamArray";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { ProductCatalog } from "types/productCatalog";

export default function WholesaleIndividualProductCatalogModal({ filterMode }: { filterMode?: boolean }) {
  const { wholesaleProductCatalogs, totalProductCatalogs } = useOutletContext() as {
    wholesaleProductCatalogs: ProductCatalog[];
    totalProductCatalogs?: number;
  };
  const navigate = useNavigate();
  const location = useLocation();
  const [filters] = useSearchParamArray("filter[]", String);
  const [filterLinkOperator] = useSearchParam("filter_link_operator", "AND", String);

  const handleClose = () => {
    navigate({
      pathname: "/product_catalogs/wholesale",
      search: `${location.search}`,
    });
  };

  const onSuccess = () => {
    const search = location.search ? `${location.search}&success=true` : "?success=true";

    navigate({
      pathname: "/product_catalogs/wholesale",
      search: search,
    });
    window.location.reload();
  };

  return (
    <Modal open={true} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "661px",
          height: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          pt: 4.5,
          px: 3,
          pb: 4,
        }}
      >
        <IconButton onClick={handleClose} sx={{ position: "absolute", top: "4px", right: "4px" }}>
          <Close />
        </IconButton>

        <RevivnApiForm
          initialValues={
            filterMode
              ? { reserveInventoires: false }
              : {
                  reserveInventoires: false,
                  productCatalogs: wholesaleProductCatalogs.reduce(
                    (acc: { [n: number]: number }, { id, quantity, wholesaleQuantity }) => {
                      acc[id] = wholesaleQuantity || quantity;
                      return acc;
                    },
                    {},
                  ),
                }
          }
          action={`/product_catalogs/move_to_wholesale${location.search}`}
          method="POST"
          onSuccess={onSuccess}
        >
          <Box>
            <Typography fontSize={"22px"} textAlign="center" fontWeight={800} sx={{ mb: 1 }}>
              Confirm Inventory to Wholesale
            </Typography>
            <Typography fontSize={14} fontWeight={400} textAlign="center" sx={{ mb: 3 }}>
              By clicking confirm all inventory from the products you selected will be
              <Typography fontWeight="bold" fontSize={14}>
                removed from the product catalog and reserved for wholesale.
              </Typography>
              Please review your selection carefully before exporting.
            </Typography>
            <Field
              name="reserveInventories"
              type="checkbox"
              as={FormControlLabel}
              control={<Checkbox></Checkbox>}
              sx={{ ml: 3, mb: 3 }}
              label={
                <Typography color="text.secondary" fontSize={"16px"}>
                  Reserve inventory and remove from product catalog
                </Typography>
              }
              size="small"
            />
            <Grid container paddingX={5} spacing={5}>
              <Grid item xs={5}>
                <Box mb={3}>
                  <Typography fontWeight="bold">Products Selected</Typography>
                  <Typography>
                    {filterMode ? totalProductCatalogs : wholesaleProductCatalogs.length} unique products
                  </Typography>
                </Box>
                {!filterMode && (
                  <Box>
                    <Typography fontWeight="bold">Units Sele</Typography>
                    <Typography>
                      {wholesaleProductCatalogs.reduce(
                        (acc: number, pc: ProductCatalog) =>
                          acc + (pc.wholesaleQuantity || pc.warehouseQuantity || pc.quantity),
                        0,
                      )}{" "}
                      inventory units
                    </Typography>
                  </Box>
                )}
              </Grid>
              {filterMode && (
                <Grid item xs={7}>
                  <Box>
                    <Typography fontWeight="bold">Filters:</Typography>
                    <Typography fontWeight="bold">
                      {deserializeFilterParamsToFilterModel(filters, filterLinkOperator)?.items.map(
                        ({ columnField, operatorValue, value }) => (
                          <Typography>
                            {columnField}{" "}
                            <Typography component="span" color="#818181">
                              {operatorValue}
                            </Typography>{" "}
                            {value}
                          </Typography>
                        ),
                      )}
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
            <Box mt={4}></Box>
            <Box display="flex" justifyContent="center">
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Back
              </Button>
              <SubmitAwareButton variant="contained" type="submit" color="primary" sx={{ ml: 2 }}>
                Confirm & Export
              </SubmitAwareButton>
            </Box>
          </Box>
        </RevivnApiForm>
      </Box>
    </Modal>
  );
}
