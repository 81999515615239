import { PageTitle } from "pages/Picklist/PageTitle";
import { StartBatchStep } from "components/Picklist/New/StartBatchStep";
import React from "react";
import { useLoaderData } from "react-router-dom";
import { FulfillmentBatch } from "hooks/useFulfillmentBatch";

export const StartBatchPage = () => {
  const { batch } = useLoaderData() as { batch: FulfillmentBatch };
  return (
    <PageTitle text="Outbound Units" subtitle="Fulfill e-commerce units for laptops in batches.">
      <StartBatchStep batch={batch} />
    </PageTitle>
  );
};
