import { Alert, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { ErrorMessage, Field } from "formik";
import React from "react";
import useCurrentUser from "hooks/useCurrentUser";
import { useShipstationOrderItems } from "hooks/useShipstationOrderItems";
import { useAlert } from "hooks/useAlert";
import { SubmitAwareButton } from "components/Form/SubmitAwareButton";
import { useNavigate } from "react-router-dom";

export function ClaimBatchStep() {
  const { user } = useCurrentUser();
  const { alertError } = useAlert();
  const { items, mutate } = useShipstationOrderItems({
    filters: [
      {
        columnField: "warehouse",
        operatorValue: "equals",
        value: user?.warehouseName,
      },
      {
        columnField: "fulfillment_batch_id",
        operatorValue: "isEmpty",
      },
      {
        columnField: "state",
        operatorValue: "equals",
        value: "created",
      },
      {
        columnField: "address_verified_at",
        operatorValue: "isNotEmpty",
      },
    ],
    enabled: !!user?.warehouseName,
  });

  const claimableCount = items?.meta?.count || 0;
  const navigate = useNavigate();

  return (
    <Stack alignItems="center" gap={3}>
      <RevivnApiForm
        initialValues={{ batchSize: 30 }}
        action="/fulfillment_batches"
        method="POST"
        onSuccess={(response) => navigate(`/picklist/batches/${response.data.id}`, { replace: true })}
        onError={(err) => {
          alertError(err.message);
          mutate();
        }}
      >
        <Stack gap={3} alignItems="center">
          <Typography>Unclaimed Units: {items?.meta?.count === undefined ? <Skeleton /> : claimableCount}</Typography>
          <Stack gap={1} justifyContent={"center"} direction={"row"} alignItems={"center"}>
            <Typography>Batch Size: </Typography>
            <Field
              as={TextField}
              name="batchSize"
              variant="standard"
              type="number"
              required={true}
              inputProps={{ min: 1, max: 70 }}
              sx={{ width: 40 }}
            />
          </Stack>
          <ErrorMessage name="base">{(msg) => <Alert severity="error">{msg}</Alert>}</ErrorMessage>
          <SubmitAwareButton variant="contained" type="submit" disabled={claimableCount === 0}>
            Claim Batch
          </SubmitAwareButton>
        </Stack>
      </RevivnApiForm>
    </Stack>
  );
}
