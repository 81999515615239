import { ListItem, List, ListItemText, Divider, Typography, Alert, ListItemButton } from "@mui/material";
import React from "react";
import { useMemo } from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps, useParams } from "react-router-dom";
import { Task } from "types/task";

type props = {
  tasks: Task[] | undefined;
};
interface ListItemLinkProps {
  primary: string;
  to: string;
}

function ListItemLink(props: ListItemLinkProps) {
  const { primary, to } = props;
  const renderLink = useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, "to">>(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to],
  );

  return (
    <li>
      <ListItemButton component={renderLink}>
        <ListItemText primary={primary} />
      </ListItemButton>
    </li>
  );
}

export default function ProgressList({ tasks }: props) {
  const params = useParams();

  return (
    <List sx={{ width: "100%" }}>
      {tasks ? (
        tasks.map((task) => {
          return (
            <ListItem key={task.id} component={"a"} disablePadding>
              <ListItemLink
                to={`/pickups/${params.pickupId}/inventories/${task.inventoryId}`}
                primary={
                  task.name.includes("rcvg")
                    ? `Inventory ${task.inventoryId} is in RCVG location`
                    : `Inventory ${task.inventoryId} is missing ${task.name}`
                }
              />
              <Divider />
            </ListItem>
          );
        })
      ) : (
        <Alert severity="info" sx={{ height: "56px" }}>
          Loading actions could take a moment...
        </Alert>
      )}
      {tasks?.length === 0 ? (
        <Alert severity="info">
          There are currently no actions for this warehouse phase. You either completed all required actions or need to
          complete the actions from previous phase{" "}
        </Alert>
      ) : null}
    </List>
  );
}
