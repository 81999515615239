import { PicklistCard } from "components/Picklist/common/PicklistCard";
import { ArrowForward, Check } from "@mui/icons-material";
import { CheckList, CheckListItem } from "components/Picklist/common/CheckList";
import { Button, Stack } from "@mui/material";
import React from "react";
import { Link, useParams, useRevalidator } from "react-router-dom";

export function ItemCompleteStep({ cartLocation }: { cartLocation?: string }) {
  const { batchId } = useParams();
  const { revalidate } = useRevalidator();

  return (
    <>
      <PicklistCard title="Actions" Icon={Check}>
        <CheckList>
          <CheckListItem label={"Scanned Inventory"} />
          <CheckListItem label={`Scanned to ${cartLocation}`} />
        </CheckList>
      </PicklistCard>

      <Stack justifyItems="center" alignItems="center" gap={1} mt={8}>
        <Button
          component={Link}
          to={`/picklist/batches/${batchId}`}
          variant="contained"
          endIcon={<ArrowForward />}
          onClick={() => {
            revalidate();
          }}
        >
          Next
        </Button>
      </Stack>
    </>
  );
}
