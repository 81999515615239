import { Button, Stack } from "@mui/material";
import { useAlert } from "hooks/useAlert";
import { api } from "services/api.service";
import useSWRMutation from "swr/mutation";

export const CreateUnassignedItems = () => {
  const endpoint = `/shipstation_order_items/create_unassigned_items`;
  const { alertInfo } = useAlert();
  const createUnassignedItemsPost = async () => {
    const response = await api.post(endpoint);

    if (response.status === 200) {
      alertInfo("Items created successfully");
    }
  };

  const { trigger, isMutating } = useSWRMutation(endpoint, createUnassignedItemsPost);

  return (
    <Stack justifyItems="center" alignItems="center" gap={1}>
      Development only action
      <Button disabled={isMutating} color="warning" variant="contained" onClick={() => trigger()}>
        Create Unassigned Items
      </Button>
    </Stack>
  );
};
