import { fetcher } from "services/api.service";
import { Add, Autorenew, CheckCircle, Error, RemoveCircle } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import {
  getGridSingleSelectOperators,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
  GridRowId,
} from "@mui/x-data-grid-pro";
import { dateTimeColDef } from "components/DataGrid/columnDefs/dateTimeColDef";
import { RevivnApiDataGrid } from "components/DataGrid/RevivnApiDataGrid";
import { Link, useSearchParams } from "react-router-dom";
import { ProductCatalog } from "types/productCatalog";
import { daysCountColDef } from "components/DataGrid/columnDefs/daysCountColDef";
import backmarketLogo from "./assets/backmarket_logo.png";
import ebayLogo from "./assets/ebay-logo.png";
import reebeloLogo from "./assets/reebelo-logo.png";
import amazonLogo from "./assets/amazon-logo.png";
import { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { useAlert } from "hooks/useAlert";

type GridRenderCellParamsCustomType = GridRenderCellParams<string, ProductCatalog, unknown>;
const toPrice = (value: string | number) => Number(value).toFixed(2);
const priceRenderer =
  (field: "startingPrice" | "ebayPrice" | "backmarketPrice" | "reebeloPrice" | "amazonPrice") =>
  (params: GridRenderCellParamsCustomType) => (params.row[field] ? `$${toPrice(params.row[field])}` : "-");

type StatusType = "active" | "action_needed" | "not_listed" | "pending" | "invalid";

const merchantStatus: Record<string, { icon: JSX.Element; label: string }> = {
  active: {
    icon: <CheckCircle fontSize="small" color="success" />,
    label: "Active",
  },
  actionNeeded: {
    icon: <Error fontSize="small" color="warning" />,
    label: "Action Needed",
  },
  pending: {
    icon: <Autorenew fontSize="small" color="warning" />,
    label: "Pending",
  },
  notListed: {
    icon: <RemoveCircle fontSize="small" color="disabled" />,
    label: "Not Listed",
  },
  invalid: {
    icon: <RemoveCircle fontSize="small" color="disabled" />,
    label: "Invalid",
  },
};

const statusMapping: Record<StatusType | "not_listed", { icon: JSX.Element; label: string }> = {
  active: merchantStatus.active,
  action_needed: merchantStatus.actionNeeded,
  invalid: merchantStatus.invalid,
  pending: merchantStatus.pending,
  not_listed: merchantStatus.notListed,
};

const channelStatusRenderer =
  (field: "ebayStatus" | "backmarketStatus" | "reebeloStatus" | "amazonStatus") =>
  (params: GridRenderCellParamsCustomType) => {
    if (!params.row[field] && (field === "backmarketStatus" || field === "reebeloStatus")) {
      return "-";
    }

    const status = (params.row[field] as StatusType) || "not_listed";
    let icon, label;
    switch (field) {
      default:
        ({ icon, label } = statusMapping[status]);
        break;
    }

    return (
      <Stack direction="row">
        {icon}
        {label}
      </Stack>
    );
  };

export default function ProductCatalogsDataGrid({
  wholesaleMode,
  setWholesaleProductCatalogs,
}: {
  wholesaleMode: boolean;
  setWholesaleProductCatalogs?: (pcs: ProductCatalog[]) => void;
}) {
  const [wholesaleRowIds, setWholesaleRowIds] = useState<GridRowId[]>([]);
  const [wholeSaleQuantitiesUpdate, setWholesaleQuantitiesUpdate] = useState<{ [key: number]: ProductCatalog }>({});
  const { enabled: elasticInventoryEnabled } = useFeatureFlag("elastic_inventories");
  const { alertSuccess } = useAlert();
  const [searchParams, setSearchParams] = useSearchParams();
  const photosField = (productCatalog: ProductCatalog) => {
    const hasPhotos = productCatalog.hasStockPhotos || productCatalog.hasResalePhotos;

    if (!productCatalog.photosUrl) {
      return null;
    }

    return (
      <Link to={productCatalog.photosUrl} style={{ textDecoration: "none" }}>
        <Button size="small" variant="text" color="primary" startIcon={hasPhotos ? <CheckCircle /> : <Add />}>
          {hasPhotos ? "View Photos" : "Add Photos"}
        </Button>
      </Link>
    );
  };

  const { data } = useSWR<{ productCatalogs: ProductCatalog[] }>(
    () => "product_catalogs?filter[]=quantity,>,0" + location.search.replace("?", "&"),
    fetcher,
  );

  const wholesaleProductCatalogs = useMemo(
    () =>
      data?.productCatalogs
        .filter(({ id }) => wholesaleRowIds.includes(id))
        .map((pc) => wholeSaleQuantitiesUpdate[pc.id] || pc),
    [data, wholesaleRowIds, wholeSaleQuantitiesUpdate],
  );

  useEffect(() => {
    if (wholesaleProductCatalogs && setWholesaleProductCatalogs)
      setWholesaleProductCatalogs(wholesaleProductCatalogs || []);
  }, [wholesaleProductCatalogs, setWholesaleProductCatalogs]);

  useEffect(() => {
    if (searchParams.get('success') === 'true') {
      alertSuccess("Request received we'll email CSV with selected inventories.");
      searchParams.delete('success')
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const statusFilters = {
    display: "flex",
    width: 140,
    filterOperators: getGridSingleSelectOperators(),
    valueOptions: [
      { value: "active", label: "Active" },
      { value: "action_needed", label: "Action Needed" },
      { value: "not_listed", label: "Not Listed" },
    ],
  };

  const columns: GridColDef<ProductCatalog>[] = useMemo(() => [
    { field: "id", headerName: "Product ID", hide: true },
    { field: "merchantProductNumber", headerName: "Merchant Product Number" },
    {
      field: "inventoriesCount",
      headerName: "Quantity",
      type: "number",
      renderCell: (params: GridRenderCellParamsCustomType) => {
        if (params.row.inventoriesCount && params.row.inventoriesCount > 0) {
          const filter = elasticInventoryEnabled ? `q=merchant_product_number:"${params.row.merchantProductNumber}"` : `filter[]=merchant_product_number,equals,${params.row.merchantProductNumber}`;
          return (
            <Link to={`/inventories?${filter}`}>
              {params.row.inventoriesCount}
            </Link>
          );
        }

        return null;
      },
    },
    { field: "sku", headerName: "SKU" },
    {
      field: "amazonStatus",
      headerName: "Amazon Status",
      renderHeader: () => <img src={amazonLogo} style={{ width: "50%", height: "50%", paddingTop: "8px" }} />,
      renderCell: channelStatusRenderer("amazonStatus"),
      ...statusFilters,
    },
    {
      field: "backmarketStatus",
      headerName: "BackMarket Status",
      renderHeader: () => <img src={backmarketLogo} style={{ width: "80%", height: "80%" }} />,
      renderCell: channelStatusRenderer("backmarketStatus"),
      ...statusFilters,
    },
    {
      field: "ebayStatus",
      headerName: "Ebay Status",
      renderHeader: () => <img src={ebayLogo} style={{ width: "50%", height: "50%" }} />,
      renderCell: channelStatusRenderer("ebayStatus"),
      ...statusFilters,
    },
    {
      field: "reebeloStatus",
      headerName: "Reebelo Status",
      renderHeader: () => <img src={reebeloLogo} style={{ width: "50%", height: "50%" }} />,
      renderCell: channelStatusRenderer("reebeloStatus"),
      ...statusFilters,
      valueOptions: [
        { value: "active", label: "Active" },
        { value: "not_listed", label: "Not Listed" },
      ],
    },
    { field: "inventoryId", headerName: "Phoenix ID" },
    { field: "deviceType", headerName: "Device Type" },
    { field: "inventoryGroup", headerName: "Inventory Group" },
    { field: "backmarketId", headerName: "Backmarket ID" },
    { field: "reebeloId", headerName: "Reebelo ID" },
    {
      field: "amazonPriceInCents",
      headerName: "Amazon Price",
      renderCell: priceRenderer("amazonPrice"),
      type: "number",
    },
    {
      field: "ebayPriceInCents",
      headerName: "Ebay Price",
      renderCell: priceRenderer("ebayPrice"),
      type: "number",
    },
    {
      field: "startingPriceInCents", // TODO: Remove field
      headerName: "Starting Price",
      renderCell: priceRenderer("startingPrice"),
      type: "number",
    },
    {
      field: "backmarketPriceInCents",
      headerName: "Backmarket Price",
      renderCell: priceRenderer("backmarketPrice"),
      type: "number",
    },
    {
      field: "reebeloPriceInCents",
      headerName: "Reebelo Price",
      renderCell: priceRenderer("reebeloPrice"),
      type: "number",
    },
    {
      field: "lastSaleAt",
      headerName: "Days since sold",
      ...daysCountColDef,
      filterable: true,
      sortable: true,
      type: "number",
      width: 150,
    },
    {
      field: "currentPriceLastSaleAt",
      headerName: "Days since sold at price",
      ...daysCountColDef,
      filterable: true,
      sortable: true,
      type: "number",
      width: 150,
    },
    {
      field: "lastAmazonSaleAt",
      headerName: "Days since sold in Amazon",
      ...daysCountColDef,
      filterable: true,
      sortable: true,
      type: "number",
      width: 150,
    },
    {
      field: "lastEbaySaleAt",
      headerName: "Days since sold in Ebay",
      ...daysCountColDef,
      filterable: true,
      sortable: true,
      type: "number",
      width: 150,
    },
    {
      field: "lastBackmarketSaleAt",
      headerName: "Days since sold in Backmarket",
      ...daysCountColDef,
      filterable: true,
      sortable: true,
      type: "number",
      width: 150,
    },
    {
      field: "lastReebeloSaleAt",
      headerName: "Days since sold in Reebelo",
      ...daysCountColDef,
      filterable: true,
      sortable: true,
      type: "number",
      width: 150,
    },
    {
      field: "ean",
      headerName: "EAN",
      filterable: true,
      sortable: true,
      type: "string",
    },
    { field: "title", headerName: "Title" },
    { field: "subtitle", headerName: "Subtitle", hide: true, filterable: false },
    { field: "description", headerName: "Description", hide: true, filterable: false },
    { field: "categoryId", headerName: "Category ID", hide: true, filterable: false },
    { field: "primaryCategory", headerName: "Primary Category", hide: true, filterable: false },
    { field: "secondaryCategory", headerName: "Secondary Category", hide: true, filterable: false },
    { field: "condition", headerName: "Condition" },
    { field: "conditionNote", headerName: "Condition Note", hide: true },
    { field: "brand", headerName: "Brand" },
    { field: "screenSize", headerName: "Screen Size" },
    { field: "processor", headerName: "Processor" },
    { field: "modelDescription", headerName: "Model" },
    { field: "model", headerName: "Model Order" },
    { field: "releaseYear", headerName: "Release Year" },
    { field: "hardDriveCapacity", headerName: "Hard Drive Capacity", hide: true },
    { field: "color", headerName: "Color" },
    { field: "ram", headerName: "RAM" },
    { field: "storageType", headerName: "Storage Type", hide: true, filterable: false },
    { field: "gpu", headerName: "GPU" },
    { field: "processorSpeed", headerName: "Processor Speed" },
    { field: "operatingSystem", headerName: "Operating System", hide: true, filterable: false },
    { field: "maximumResolution", headerName: "Maximum Resolution", hide: true, filterable: false },
    { field: "keyboardLanguage", headerName: "Keyboard Language" },
    { field: "itemHeight", headerName: "Item Height", hide: true, filterable: false },
    { field: "itemWidth", headerName: "Item Width", hide: true, filterable: false },
    { field: "itemLength", headerName: "Item Length", hide: true, filterable: false },
    { field: "itemWeight", headerName: "Item Weight", hide: true, filterable: false },
    { field: "shippingPolicyName", headerName: "Shipping Policy Name", hide: true, filterable: false },
    { field: "daysOnShelf", headerName: "Days On Shelf", hide: true },
    { field: "daysSincePurchase", headerName: "Days Since Purchase", hide: true },
    {
      field: "createdAt",
      headerName: "Created",
      ...dateTimeColDef,
      filterable: false,
      width: 200,
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      ...dateTimeColDef,
      filterable: false,
      width: 200,
    },
    {
      field: "lastQuantitiesSyncedAt",
      headerName: "Last Quantities Sync",
      ...dateTimeColDef,
      filterable: false,
      width: 200,
    },
    {
      field: "lastFieldsSyncedAt",
      headerName: "Last Fields Sync",
      ...dateTimeColDef,
      filterable: false,
      width: 200,
    },
    {
      field: "photosUrl",
      headerName: "Photos",
      renderCell: (params: GridRenderCellParamsCustomType) => photosField(params.row),
      filterable: false,
      sortable: false,
        width: 200,
      },
    ].filter(Boolean),
    [elasticInventoryEnabled],
  );

  if (wholesaleMode) {
    columns.splice(2, 1, {
      field: "warehouseQuantity",
      headerName: "quantity",
      type: "number",
    });
    columns.splice(2, 0, {
      field: "wholesaleQuantity",
      headerName: "Wholesale Quant.",
      type: "number",
      editable: true,
      valueGetter: (params) => params.row.wholesaleQuantity || params.row.warehouseQuantity,
      renderCell: ({ row }) => (
        <Typography color={wholesaleRowIds.includes(row.id) ? "#000" : "rgba(0, 0, 0, 0.38)"}>
          {row.wholesaleQuantity || row.warehouseQuantity}
        </Typography>
      ),
    });
  }

  return (
    <RevivnApiDataGrid
      sx={{
        backgroundColor: "background.paper",
        minHeight: 600,
        height: "calc(100vh - 10em)",
      }}
      columns={columns}
      getDetailPanelHeight={() => "auto"}
      components={{ Toolbar: GridToolbar }}
      componentsProps={{
        filterPanel: { visible: false },
        toolbar: {
          printOptions: { disableToolbarButton: true },
          csvOptions: { disableToolbarButton: true, fileName: `product_catalogs_${Date.now()}` },
        },
      }}
      checkboxSelection={wholesaleMode}
      processRowUpdate={(row: ProductCatalog) => {
        if (row.wholesaleQuantity && row.wholesaleQuantity > row.quantity) {
          row.wholesaleQuantity = row.quantity;
        }
        setWholesaleQuantitiesUpdate((old) => ({ ...old, [row.id]: row }));
        return row;
      }}
      isRowSelectable={({ row }: { row: ProductCatalog }) => row.quantity > 0}
      experimentalFeatures={{ newEditingApi: true }}
      onSelectionModelChange={(selectionModel) => {
        setWholesaleRowIds(selectionModel);
      }}
      url={wholesaleMode ? "product_catalogs?filter[]=warehouse_quantity,>,0" : "product_catalogs"}
    />
  );
}
