import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Button,
  Divider,
  GlobalStyles,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import DirectUploadField from "components/Form/DirectUploadField";
import TechnicianInventoryImages from "components/TechnicianInventory/TechnicianInventoryImages";
import { StyledBox } from "components/TechnicianInventory/technicianInventoryStyles";
import { colors } from "constants/colors";
import { conditions } from "constants/conditions";
import { deviceTypes } from "constants/device_types";
import { labelTooltip } from "constants/inventory_tooltips";
import { FormikProvider, useFormik } from "formik";
import { useAlert } from "hooks/useAlert";
import React, { ReactElement, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import { defaultInventory, Inventory, KeyboardLanguage } from "types/inventory";
import { RecordHistoryEvent } from "types/recordHistoryEvent";
import { Tag } from "types/tag";
import { inventoryEditValidation } from "validations/inventory";
import Tags from "./Tags";

type InventoryEditProps = {
  inventory?: Inventory;
  inventoryEvents?: RecordHistoryEvent[];
  loadNextInventoryEvents: () => void;
  isLoadingInventoryEvents: boolean;
  updateInventory: (props: Inventory) => Promise<AxiosResponse<Inventory, Error>>;
};

const olderEventsURL =
  "https://app.hex.tech/d8ff35c6-9bfe-4f6c-b30b-a4d56f722e73/app/618f1afe-aeef-4e9f-a3fc-049cfca7a8c1/latest";

export default function InventoryEdit({
  inventory,
  updateInventory,
  inventoryEvents,
  loadNextInventoryEvents,
  isLoadingInventoryEvents,
}: InventoryEditProps) {
  const navigate = useNavigate();
  const formValues = useMemo(() => {
    return inventory || defaultInventory;
  }, [inventory]);
  const { alertError } = useAlert();
  const formik = useFormik<Inventory>({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: formValues,
    validationSchema: inventoryEditValidation,
    onSubmit: async (values) => {
      try {
        await updateInventory(values);
        navigate(-1);
      } catch {
        alertError("There was an error, please try again");
      }
    },
  });

  const yearsArray = () => {
    const max = new Date().getFullYear();
    const min = max - 20;
    const years = [];

    for (let i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  };

  const historyRow = (content: ReactElement | string, key: number) => {
    return (
      <React.Fragment key={key}>
        <ListItem alignItems="flex-start">
          <ListItemText>{content}</ListItemText>
        </ListItem>
        <Divider component="li" />
      </React.Fragment>
    );
  };

  return (
    <FormikProvider value={formik}>
      <GlobalStyles
        styles={{
          html: { viewTransitionName: "none" },
          "html::view-transition-group(.card)": {
            animationTimingFunction: "ease-in-out",
            animationDuration: "0.5s",
          },
        }}
      />
      <Paper variant="outlined" sx={{ p: "20px" }}>
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          sx={{
            ".css-k0xjq9-MuiTypography-root": { pt: 1 },
            ".MuiChip-deleteIcon": { color: "#ffffff" },
          }}
          noValidate
          autoComplete="off"
        >
          <Grid container sm={12} spacing={2}>
            <Grid item lg={6}>
              <Typography variant="h6" sx={{ color: "#0089BD" }}>
                Inventory Details
              </Typography>
              <Grid container direction="row" sx={{ alignItems: "center" }}>
                <StyledBox sx={{ justifyContent: "space-between" }}>
                  <Typography variant="overline">label:</Typography>
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {inventory?.label}
                  </Typography>
                  <Tooltip title={labelTooltip} sx={{ mx: 1, mr: 2, position: "relative" }}>
                    <InfoIcon fontSize="small" color="primary" />
                  </Tooltip>
                  <Typography variant="overline">Inventory Id:</Typography>
                  <Typography variant="body2" sx={{ color: "#0089BD", mx: 1 }}>
                    {inventory?.id}
                  </Typography>
                  <Typography variant="overline">Data Wipe:</Typography>
                  <Box ml={1}>
                    <Typography variant="overline">Drive Erasure Status:</Typography>
                    <Typography variant="body2" sx={{ color: "#0089BD", mx: 1 }}>
                      {inventory?.drive1ErasureStatus}
                    </Typography>
                  </Box>
                </StyledBox>
              </Grid>
              <Grid container direction="row" sx={{ alignItems: "center" }}>
                <Typography variant="overline">Location:</Typography>
                <Typography variant="body2" sx={{ color: "#0089BD", mx: 1 }}>
                  {inventory?.warehouseLocation}
                </Typography>
                <Typography variant="overline" sx={{ ml: "25px" }}>
                  Warehouse:
                </Typography>
                <Typography variant="body2" sx={{ color: "#0089BD", mx: 1 }}>
                  {inventory?.warehouse?.name}
                </Typography>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid item sm={6}>
                  <Typography variant="overline">Manufacturer</Typography>
                  <TextField
                    id="manufacturer"
                    name="manufacturer"
                    size="small"
                    fullWidth
                    value={formik.values.manufacturer || ""}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="overline"> Device Type</Typography>
                  <Select
                    id="deviceType"
                    name="deviceType"
                    size="small"
                    fullWidth
                    value={formik.values.deviceType || ""}
                    onChange={formik.handleChange}
                  >
                    {deviceTypes.map((device) => {
                      return (
                        <MenuItem key={device} value={device}>
                          {device}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="overline"> Serial Number</Typography>
                  <TextField
                    id="serialNumber"
                    name="serialNumber"
                    size="small"
                    fullWidth
                    value={formik.values.serialNumber || ""}
                    onChange={formik.handleChange}
                    helperText={formik.errors.serialNumber}
                    error={Boolean(formik.errors.serialNumber)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="overline"> SKU</Typography>
                  <TextField
                    id="sku"
                    name="sku"
                    size="small"
                    fullWidth
                    value={formik.values.sku || ""}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="overline"> Model</Typography>
                  <TextField
                    id="model"
                    name="model"
                    size="small"
                    fullWidth
                    value={formik.values.model || ""}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="overline"> Model Order</Typography>
                  <TextField
                    id="modelOrder"
                    name="modelOrder"
                    size="small"
                    multiline
                    fullWidth
                    value={formik.values.modelOrder || ""}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="overline"> Year</Typography>
                  <Select
                    id="year"
                    name="year"
                    size="small"
                    fullWidth
                    value={formik.values.year || ""}
                    onChange={formik.handleChange}
                  >
                    {yearsArray().map((year: number) => {
                      return (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="overline"> Cosmetic Grade</Typography>
                  <Select
                    id="condition"
                    name="condition"
                    size="small"
                    fullWidth
                    value={formik.values.condition || ""}
                    onChange={formik.handleChange}
                  >
                    {conditions.map((condition) => {
                      return (
                        <MenuItem key={condition} value={condition}>
                          {condition}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="overline"> Security Level</Typography>
                  <Select
                    id="securityLevel"
                    name="securityLevel"
                    size="small"
                    fullWidth
                    value={formik.values.securityLevel || ""}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={"Not locked"}>Not locked</MenuItem>
                    <MenuItem value={"EFI locked"}>EFI locked</MenuItem>
                    <MenuItem value={"BIOS locked"}>BIOS locked</MenuItem>
                    <MenuItem value={"Elevated security"}>Elevated security</MenuItem>
                    <MenuItem value={"Icloud locked"}>Icloud locked</MenuItem>
                    <MenuItem value={"Activation lock"}>Activation lock</MenuItem>
                  </Select>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="overline"> Battery Count</Typography>
                  <TextField
                    id="batteryCount"
                    name="batteryCount"
                    size="small"
                    fullWidth
                    value={formik.values.batteryCount || ""}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="overline"> Color</Typography>
                  <Select
                    id="color"
                    name="color"
                    size="small"
                    fullWidth
                    value={formik.values.color || ""}
                    onChange={formik.handleChange}
                  >
                    {colors.map((color) => (
                      <MenuItem key={color} value={color}>
                        {color}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="overline"> Asset Tag</Typography>
                  <TextField
                    id="asset_tag"
                    name="assetTag"
                    size="small"
                    fullWidth
                    value={formik.values.assetTag || ""}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={12}>
                  <Typography variant="overline"> Enrolled</Typography>
                  <Select
                    displayEmpty
                    id="enrolledStatus"
                    name="enrolledStatus"
                    size="small"
                    fullWidth
                    value={formik.values.enrolledStatus || ""}
                    onChange={formik.handleChange}
                  >
                    {[
                      ["enrolled", "Enrolled"],
                      ["unenrolled", "Unenrolled"],
                      ["api_unenrolled", "API Unenrolled"],
                      ["pending_revivn_unenrollment_confirmation", "Pending Revivn Unenrollment Confirmation"],
                      ["not_found", "Not Found"],
                    ].map((status) => {
                      return (
                        <MenuItem key={status[0]} value={status[0]}>
                          {status[1]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="overline"> Enrolled Organization</Typography>
                  <TextField
                    id="enrolledOrganization"
                    name="enrolledOrganization"
                    size="small"
                    fullWidth
                    value={formik.values.enrolledOrganization || ""}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="overline"> RAM</Typography>
                  <TextField
                    id="ram"
                    name="ram"
                    size="small"
                    fullWidth
                    value={formik.values.ram || ""}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="overline"> CPU</Typography>
                  <TextField
                    id="cpu"
                    name="cpu"
                    size="small"
                    fullWidth
                    value={formik.values.cpu || ""}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="overline"> GPU</Typography>
                  <TextField
                    id="gpu"
                    name="gpu"
                    size="small"
                    multiline
                    fullWidth
                    value={formik.values.gpu || ""}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="overline"> Hard Drive</Typography>
                  <TextField
                    id="hardDrive"
                    name="hardDrive"
                    size="small"
                    fullWidth
                    value={formik.values.hardDrive || ""}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="overline">Drive Size (GB)</Typography>
                  <TextField
                    id="hardDriveSizeInGb"
                    name="hardDriveSizeInGb"
                    size="small"
                    fullWidth
                    value={formik.values.hardDriveSizeInGb || ""}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Typography variant="overline" sx={{ pt: 2 }}>
                Description
              </Typography>
              <TextField
                id="description"
                name="description"
                size="small"
                multiline={true}
                fullWidth
                value={formik.values.description || ""}
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Grid item sm={12}>
                <Typography variant="overline">Keyboard Language</Typography>
                <Select
                  displayEmpty
                  id="keyboardLanguage"
                  name="keyboardLanguage"
                  size="small"
                  fullWidth
                  value={formik.values.keyboardLanguage ? formik.values.keyboardLanguage : null}
                  onChange={formik.handleChange}
                >
                  {Object.keys(KeyboardLanguage).map((languageCode) => {
                    return (
                      <MenuItem key={languageCode} value={languageCode}>
                        {KeyboardLanguage[languageCode as keyof typeof KeyboardLanguage]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Typography variant="overline" sx={{ pt: 2 }}>
                Comments
              </Typography>
              <TextField
                id="comments"
                name="comments"
                size="small"
                multiline={true}
                fullWidth
                value={formik.values.comments || ""}
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Typography variant="overline" sx={{ pt: 2 }}>
                Tags
              </Typography>
              <Tags
                tags={formik.values.tags || []}
                onChange={(tags: Tag[]) => {
                  formik.setFieldValue("tags", tags);
                }}
              />
              <Typography variant="overline" sx={{ pt: 2 }}>
                Internal Photos
              </Typography>
              <Typography paragraph variant="caption" color="text.secondary">
                Photos from inbound (serial number & asset tag), photos from returns, etc.
              </Typography>
              <Box>{inventory ? <TechnicianInventoryImages /> : null}</Box>

              <Box marginY={4}>
                <Typography variant="overline">Resale Photos</Typography>
                <Typography paragraph variant="caption" color="text.secondary">
                  Photos uploaded here will be tagged as Resale and will appear externally on our ecom listings. They
                  should be high quality images revealing no sensitive device or client data. The first uploaded resale
                  photo should be a photo of the device with the screen open, facing directly towards the camera with
                  the entire screen and keyboard visible.
                </Typography>
                <Box>
                  <DirectUploadField name="resalePhotos" service="amazon_public" accept={["image/jpeg"]} multiple />
                </Box>
              </Box>
            </Grid>
            <Grid item lg={6}>
              <Typography variant="h6" sx={{ color: "#0089BD" }}>
                History
              </Typography>
              <Paper variant="outlined" sx={{ mt: 1 }}>
                <List
                  sx={{
                    width: "100%",
                    height: "700px",
                    overflow: "auto",
                  }}
                >
                  {inventoryEvents && inventoryEvents.map((event, i) => historyRow(event.description, i))}
                  {historyRow(
                    <>
                      For inventory events older than 6 months check{" "}
                      <Link key={-1} to={olderEventsURL} target="_blank">
                        Older Events
                      </Link>
                    </>,
                    -1,
                  )}
                  {!isLoadingInventoryEvents && <Waypoint onEnter={loadNextInventoryEvents} />}
                </List>
              </Paper>
            </Grid>
            <Grid container sx={{ justifyContent: "center" }}>
              <Button
                color="primary"
                size="large"
                variant="outlined"
                onClick={() => navigate(-1)}
                sx={{ margin: "10px", width: "120px" }}
              >
                BACK
              </Button>
              <Button
                color="primary"
                size="large"
                variant="contained"
                type="submit"
                sx={{ margin: "10px", width: "120px" }}
                disabled={!formik.isValid}
              >
                SAVE
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </FormikProvider>
  );
}
