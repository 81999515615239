import HistoryIcon from "@mui/icons-material/History";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListProps,
  Modal,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { Waypoint } from "react-waypoint";
import { StyledBoxModal } from "../technicianInventoryStyles";
import useRecordHistoryEvent from "hooks/useRecordHistoryEvent";
import { RecordHistoryEvent } from "types/recordHistoryEvent";
import { Inventory } from "types/inventory";

interface InventoryEventHistoryButtonProps {
  entityId: number;
  eventType: "inventory_events" | "inventory_part_events";
  disabled?: boolean;
}

export default function InventoryEventHistoryButton({
  entityId,
  eventType,
  disabled,
}: InventoryEventHistoryButtonProps) {
  const { events, fetchMoreEvents, hasMoreEvents, isLoading } = useRecordHistoryEvent({ entityId, eventType });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Tooltip title="View History" disableInteractive>
        <IconButton onClick={handleOpen} disabled={disabled} color="default">
          <HistoryIcon color="primary" />
        </IconButton>
      </Tooltip>
      <Modal open={open} onClose={handleClose}>
        <StyledBoxModal boxShadow={24} padding={4} gap={1} borderRadius={1} maxHeight="90vh">
          <Typography variant="h6">Event History</Typography>
          <List component={Box} height="50vh" overflow="scroll">
            {events &&
              events.map(({ id, description }: RecordHistoryEvent) => (
                <ListItem divider alignItems="flex-start" key={id}>
                  <ListItemText primary={description} />
                </ListItem>
              ))}
            {!isLoading || !hasMoreEvents ? (
              <Waypoint onEnter={fetchMoreEvents} />
            ) : (
              <>
                <Typography>Fetching events...</Typography>
                <LinearProgress />
              </>
            )}
          </List>
        </StyledBoxModal>
      </Modal>
    </>
  );
}

export const InventoryEventsHistoryButton = ({ inventory }: { inventory?: Inventory }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const InventoryEventsList = (props: ListProps) => {
    const { events, fetchMoreEvents, hasMoreEvents, isLoading } = useRecordHistoryEvent({
      entityId: inventory?.id,
      eventType: "inventory_events",
    });

    return (
      <List {...props}>
        {events.map(({ id, description }: RecordHistoryEvent) => (
          <ListItem divider alignItems="flex-start" key={id}>
            <ListItemText primary={description} />
          </ListItem>
        ))}
        {!isLoading || !hasMoreEvents ? (
          <Waypoint onEnter={fetchMoreEvents} />
        ) : (
          <ListItem>
            <ListItemText primary="Fetching events..." secondary={<LinearProgress />} />
          </ListItem>
        )}
      </List>
    );
  };

  const InventoryEventsDialog = (props: DialogProps) => {
    const theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
      <Dialog {...props} fullScreen={fullscreen} scroll="paper">
        <DialogTitle>Event History</DialogTitle>
        <DialogContent>
          <InventoryEventsList sx={{ maxHeight: "calc(100% - 10vh)" }} />
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Tooltip title="View event history" disableInteractive>
        <IconButton color="primary" onClick={handleOpen}>
          <HistoryIcon />
        </IconButton>
      </Tooltip>
      <InventoryEventsDialog open={open} onClose={handleClose} />
    </>
  );
};
