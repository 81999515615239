import { Upload, PictureAsPdf } from "@mui/icons-material";
import { Box, Paper, Stack, SxProps, Theme, Typography, useEventCallback } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FileStorageService } from "hooks/useDirectUpload";
import { swap, useField } from "formik";
import { DirectUploadImageThumb } from "components/Form/DirectUpload/DirectUploadImageThumb";
import { ImageThumb } from "components/Form/DirectUpload/ImageThumb";
import { DeleteAction, ExpandAction, MoveDownAction, MoveUpAction, ViewAction } from "components/Form/DirectUpload/Actions";

const isAttachable = (value: unknown): value is Attachable =>
  !!value && typeof value === "object" && "signedId" in value;
const isAttachableArray = (value: unknown): value is Attachable[] => Array.isArray(value) && value.every(isAttachable);

interface DirectUploadFieldProps {
  name: string;
  service?: FileStorageService;
  accept?: string[];
  multiple?: boolean;
  reorderable?: boolean;
  sx?: SxProps<Theme>;
  initialFiles?: File[];
}

interface UnpersistedAttachment {
  file: File;
  url: string;
  filename: string;
  contentType?: string;
}

interface Attachment {
  signedId: string;
  url: string;
  filename?: string;
  contentType?: string;
}

export type Attachable = Attachment | UnpersistedAttachment;

export function filesToAttachables(files: File[]): UnpersistedAttachment[] {
  return files.map((file) => {
    const url = URL.createObjectURL(file);
    return { file, url, filename: file.name, contentType: file.type };
  });
}

export default function DirectUploadField({
  name,
  accept = ["image/jpeg"],
  multiple = true,
  reorderable = true,
  initialFiles = [],
  sx,
}: DirectUploadFieldProps) {
  const [, { initialValue }, { setValue }] = useField<Attachment[] | string[]>(name);
  const [active, setActive] = useState(false);
  const [attachables, setAttachables] = useState<Attachable[]>([]);

  const stableSetValue = useEventCallback(setValue);

  useEffect(() => {
    if (!initialValue) return;
    if (!isAttachableArray(initialValue)) throw new Error("Initial value must be an array of Attachments");
    setAttachables(initialValue);
    stableSetValue(initialValue.map(({ signedId }) => signedId));
  }, [initialValue, stableSetValue]);

  useEffect(() => {
    if (!initialFiles || initialFiles.length === 0) return;
    setAttachables((p) => [...filesToAttachables(initialFiles), ...p]);
  }, [initialFiles]);

  useEffect(() => {
    const attachments = attachables.filter((a): a is Attachment => "signedId" in a);
    stableSetValue(attachments.map(({ signedId }) => signedId));
  }, [attachables, stableSetValue]);

  return (
    <Box sx={sx}>
      <Paper
        className={"dropzone"}
        component="label"
        variant="outlined"
        onDragEnter={() => {
          if (!active) setActive(true);
        }}
        onDragExit={() => {
          if (active) setActive(false);
        }}
        onDragOver={(e: { dataTransfer: { files: FileList }; preventDefault: () => void }) => {
          e.preventDefault();
        }}
        onDrop={(e: { dataTransfer: { files: FileList }; preventDefault: () => void }) => {
          e.preventDefault();
          const files = e.dataTransfer.files;
          if (!files) return;
          setAttachables((p) => [...filesToAttachables(Array.from(files)), ...p]);
        }}
        sx={{
          backgroundColor: (theme) => (active ? theme.palette.primary.light : theme.palette.grey[100]),
          boxShadow: (theme) => (active ? `0px 0px 8px ${theme.palette.primary.light}` : "inherit"),
          "&:hover": {
            backgroundColor: (theme) => theme.palette.grey[300],
          },
          display: "inline-block",
          width: "max-content",
          borderStyle: "dashed",
          borderWidth: "2px",
          transition: "ease-in-out 0.2s",
          padding: 2,
          cusror: "pointer",
        }}
      >
        <Box display="flex" flexDirection="row" gap={1} color="text.secondary">
          <Upload />
          <Typography>Upload</Typography>
        </Box>
        <Typography color="text.secondary" variant="caption">
          Click or drag and drop files here
        </Typography>
        <input
          hidden
          multiple={multiple}
          type="file"
          accept={accept.join(",")}
          onChange={(e: { target: { files: FileList | null } }) => {
            const files = e.target.files;
            if (!files) return;
            setAttachables((p) => [...filesToAttachables(Array.from(files)), ...p]);
          }}
        />
      </Paper>

      <Stack gap={2} paddingY={2}>
        <Stack gap={2} direction="row" flexWrap="wrap">
          {attachables.length === 0 && <Typography color="text.secondary">No files present.</Typography>}
          {attachables.map((attachable, index) => (
            <>
              {"file" in attachable && (
                <DirectUploadImageThumb
                  file={attachable.file}
                  url={attachable.url}
                  key={attachable.url}
                  onBlobCreated={(blob) => {
                    setAttachables((prev) => {
                      const attachment: Attachment = {
                        signedId: blob.signed_id,
                        url: attachable.url,
                        filename: attachable.filename,
                        contentType: blob.content_type,
                      };
                      return prev.map((a) => (a === attachable ? attachment : a));
                    });
                  }}
                  onClickDelete={() => {
                    setAttachables((p) => p.filter((a) => a !== attachable));
                  }}
                />
              )}
              {"signedId" in attachable && (
                <ImageThumb
                  src={attachable.contentType === "application/pdf" ? '' : attachable.url}
                  overlay={attachable.contentType === "application/pdf" ? <PictureAsPdf sx={{ fontSize: 50 }} /> : undefined}
                  sx={attachable.contentType === "application/pdf" ? {
                    '& img': {
                      opacity: 0,
                      marginTop: '20px',
                    }
                  } : undefined}
                  filename={attachable.filename ?? ""}
                  key={attachable.signedId}
                  actions={
                    <>
                      {reorderable && index > 0 && (
                        <MoveUpAction
                          onClick={() => setAttachables((p) => swap(p, index, index - 1) as Attachable[])}
                        />
                      )}
                      {reorderable && index < attachables.length - 1 && (
                        <MoveDownAction
                          onClick={() => setAttachables((p) => swap(p, index, index + 1) as Attachable[])}
                        />
                      )}
                      <DeleteAction onClick={() => setAttachables((p) => p.filter((a) => a !== attachable))} />
                      {attachable.contentType !== "application/pdf" && <ExpandAction start={index} images={attachables} key={attachable.url + index.toString()} />}
                      <ViewAction url={attachable.url} />
                    </>
                  }
                />
              )}
            </>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
}
