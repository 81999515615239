import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { SubmitAwareButton } from "components/Form/SubmitAwareButton";
import { useNavigate, useParams, useRevalidator } from "react-router-dom";

export function NotFoundStep({ onCancel }: { onCancel: () => void }) {
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();
  const { batchId, itemId } = useParams();

  return (
    <>
      <Typography>
        <p>Are you sure this unit needs to be replaced?</p>

        <p>Tip: Look through the stacks up, down, left and right from the stack this unit should be in!</p>
      </Typography>
      <Stack justifyItems="center" alignItems="center" gap={2} mt={8}>
        <Button variant="contained" onClick={onCancel} sx={{ backgroundColor: (theme) => theme.palette.grey["500"] }}>
          No, Go Back
        </Button>
        <RevivnApiForm
          initialValues={{}}
          action={`/fulfillment_batches/${batchId}/items/${itemId}/cannot_find`}
          method="POST"
          onSuccess={() => {
            revalidate();
            navigate(`/picklist/batches/${batchId}`);
          }}
          onError={() => {
            revalidate();
          }}
        >
          <SubmitAwareButton variant="contained" type="submit">
            Yes, and move to next unit
          </SubmitAwareButton>
        </RevivnApiForm>
      </Stack>
    </>
  );
}
