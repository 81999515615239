import { Box } from "@mui/material";
import { PageTitleContainer } from "pages/Layouts/components/PageTitleContainer";
import { Outlet } from "react-router-dom";

export const DatagridPageLayout = () => {
  return (
    <>
      <PageTitleContainer />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gap: 1,
          gridTemplateRows: "1fr",
          flexGrow: 1,
          minHeight: "600px",
        }}
      >
        <Outlet />
      </Box>
    </>
  );
};
