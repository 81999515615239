import { Add, ArrowBack, QrCodeScanner } from "@mui/icons-material";
import { Box, Button, Tab, Tabs, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import QRScanner from "components/Scan/QRScanner";
import { SearchInput } from "components/common/Input";
import { useAlert } from "hooks/useAlert";
import useRecordHistoryEvent from "hooks/useRecordHistoryEvent";
import { ReadCompleted } from "hooks/useQrScanner";

import React, { useCallback, useEffect, useState } from "react";
import { Waypoint } from "react-waypoint";
import { api } from "services/api.service";
import { Inventory } from "types/inventory";
import openInventoryPhotoPicker from "utils/openInventoryPhotoPicker";

interface InventoryDetailsProps {
  inventory: Inventory;
  handleOpenPicker?: () => void;
}

interface HistoryProps {
  inventory: Inventory;
}

function Details({ inventory }: InventoryDetailsProps) {
  return (
    <Box
      mt={2}
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(min(400px, 100%), 1fr))",
        gap: 2,
        alignItems: "start",
      }}
    >
      <Box
        sx={{
          borderRadius: 1,
          background: "background.paper",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          p: 2,

          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 1,
        }}
      >
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>LABEL</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.label}</Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>INVENTORY ID</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.id}</Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>LOCATION</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.warehouseLocation}</Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>WAREHOUSE</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.warehouse?.name}</Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>STATE</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.state}</Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>INVENTORY GROUP</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.inventoryGroup}</Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          borderRadius: 1,
          background: "background.paper",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          p: 2,

          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 1,
        }}
      >
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>MANUFACTURER</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.manufacturer}</Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>DEVICE TYPE</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.deviceType}</Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>SERIAL NUMBER</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.serialNumber}</Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>SKU</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.sku}</Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>MODEL</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.model}</Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>MODEL ORDER</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.modelOrder}</Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>YEAR</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.year}</Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>WIPE STATUS</Box>
          <Box mt={0.5}>
            <Box sx={{ minHeight: "22px" }}>
              <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.drive1ErasureStatus}</Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          borderRadius: 1,
          background: "background.paper",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          p: 2,

          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 1,
        }}
      >
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>COSMETIC GRADE</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.condition}</Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>SECURITY LEVEL</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.securityLevel}</Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>BATTERY COUNT</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.batteryCount}</Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>COLOR</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.color}</Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>ASSET TAG</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.assetTag}</Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>ENROLLED</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.enrolledStatus}</Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          borderRadius: 1,
          background: "background.paper",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          p: 2,

          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 1,
        }}
      >
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>TRACKPAD</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>
              {inventory.trackpadFunctionality == null ? "Unchecked" : inventory.trackpadFunctionality}
            </Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>KEYBOARD</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>
              {inventory.keyboardFunctionality == null ? "Unchecked" : inventory.keyboardFunctionality}
            </Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>LCD DISPLAY</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>
              {inventory.screenFunctionality == null ? "Unchecked" : inventory.screenFunctionality}
            </Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>BATTERY</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>
              {inventory.batteryFunctionality == null ? "Unchecked" : inventory.batteryFunctionality}
            </Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>CAMERA</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>
              {inventory.cameraFunctionality == null ? "Unchecked" : inventory.cameraFunctionality}
            </Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>MICROPHONE</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>
              {inventory.microphoneFunctionality == null ? "Unchecked" : inventory.microphoneFunctionality}
            </Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>SPEAKERS</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>
              {inventory.speakerFunctionality == null ? "Unchecked" : inventory.speakerFunctionality}
            </Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>PORTS</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>
              {inventory.portsFunctionality == null ? "Unchecked" : inventory.portsFunctionality}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          borderRadius: 1,
          background: "background.paper",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          p: 2,

          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 1,
        }}
      >
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>RAM</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.ram}</Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>CPU</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.cpu}</Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>GPU</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.gpu}</Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>HARD DRIVE</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.hardDrive}</Box>
          </Box>
        </Box>
        <Box sx={{ gridColumn: "1 / span 2" }}>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>DESCRIPTION</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.description}</Box>
          </Box>
        </Box>
        <Box sx={{ gridColumn: "1 / span 2" }}>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>COMMENTS</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>{inventory.comments}</Box>
          </Box>
        </Box>
        <Box sx={{ gridColumn: "1 / span 2" }}>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>TAGS</Box>
          <Box mt={0.5}>
            <Box sx={{ fontWeight: "600", minHeight: "22px" }}>
              {inventory.tags?.map(({ name }) => name)?.join(", ")}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          borderRadius: 1,
          background: "background.paper",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          p: 2,

          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 1,
        }}
      >
        <Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>PHOTOS</Box>
          <Box mt={0.5} sx={{ display: "flex", gap: 0.5, minHeight: "68px" }}>
            {inventory.inventoryImages?.map(({ url }) => {
              return (
                <Tooltip
                  key={url}
                  title={
                    <React.Fragment>
                      <img src={url} loading="lazy" width={280} />
                    </React.Fragment>
                  }
                >
                  <img src={url} loading="lazy" width={80} />
                </Tooltip>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function History({ inventory }: HistoryProps) {
  const { events, fetchMoreEvents, isLoading, hasMoreEvents } = useRecordHistoryEvent({
    entityId: inventory.id,
    eventType: "inventory_events",
  });

  const loading = isLoading || !hasMoreEvents;

  return (
    <Box
      mt={2}
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(min(400px, 100%), 1fr))",
        gap: 2,
        alignItems: "start",
      }}
    >
      {events.map((event) => {
        return (
          <Box>
            <Typography color="rgba(0, 0, 0, 0.6)">{new Date(event?.createdAt || "").toDateString()}</Typography>
            <Box
              mt={0.5}
              sx={{
                borderRadius: 1,
                background: "background.paper",
                border: (theme) => `1px solid ${theme.palette.divider}`,
                p: 2,
                fontWeight: "bold",
                overflow: "hidden",
              }}
            >
              {event.description}
            </Box>
          </Box>
        );
      })}
      {!loading && <Waypoint onEnter={fetchMoreEvents} />}
    </Box>
  );
}

function InventoryDetails({ inventory, handleOpenPicker }: InventoryDetailsProps) {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isTabletView = useMediaQuery(theme.breakpoints.down("lg"));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Button variant="contained" startIcon={<Add />} fullWidth={isTabletView} onClick={handleOpenPicker}>
        add photo
      </Button>

      <Box mt={2}>
        <Tabs value={value} onChange={handleChange} variant={isTabletView ? "fullWidth" : "standard"}>
          <Tab label="details" />
          <Tab label="history" />
        </Tabs>
      </Box>

      {value === 0 ? <Details inventory={inventory} /> : <History inventory={inventory} />}
    </Box>
  );
}

export default function ScanAndDiscover() {
  const { alertError } = useAlert();
  const [showScan, setShowScan] = useState(false);
  const [inventorySearch, setInventorySearch] = useState<string | null>(null);
  const [inventoryId, setInventoryId] = useState<string | null>(null);

  const [inventory, setInventory] = useState<Inventory | null>(null);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const handleOpenPicker = () => {
    openInventoryPhotoPicker(
      inventory!,
      (image) => {
        setInventory((p) => {
          if (!p) return null;
          return { ...p, inventoryImages: [...p.inventoryImages, image] };
        });
      },
      { fromSources: ["local_file_system", "webcam"] },
    );
  };

  const getInventory = useCallback(() => {
    if (!inventoryId) return;
    setLoading(true);
    api
      .get(`inventories/${inventoryId}`)
      .then(({ data }) => {
        setInventory(data);
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          alertError(`Can't find inventory with id or label ${inventoryId}`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [inventoryId, alertError]);

  const handleScannedQR = useCallback<ReadCompleted>(
    (scannedInventoryId) => {
      if (scannedInventoryId) {
        setInventoryId(scannedInventoryId);
      }
      setShowScan(false);
    },
    [setShowScan],
  );

  useEffect(() => {
    if (!inventory) {
      setInventoryId(null);
    }
  }, [inventory]);

  useEffect(() => {
    if (!!inventoryId) {
      getInventory();
    }
  }, [getInventory, inventoryId]);

  return (
    <Box>
      {inventory && (
        <Box mb={2}>
          <Button startIcon={<ArrowBack />} onClick={() => setInventory(null)}>
            scan or search new item
          </Button>
        </Box>
      )}
      <Box>
        <Typography variant="h4">Scan & Discover</Typography>
        <Typography mt={0.5}>
          View details of an inventory item by scanning its QR code or searching for its ID.
        </Typography>
      </Box>
      {inventory ? (
        <Box mt={2}>
          <InventoryDetails inventory={inventory} handleOpenPicker={handleOpenPicker} />
        </Box>
      ) : (
        <>
          <Box
            mt={2}
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
                "> *": { width: "100%", maxWidth: "none !important" },
              },
            }}
          >
            <Box
              component={"form"}
              sx={{ display: "flex", ".MuiInputBase-root": { borderTopRightRadius: 0, borderBottomRightRadius: 0 } }}
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                getInventory();
              }}
            >
              <SearchInput
                placeholder="Search"
                value={inventorySearch}
                onChange={(e) => setInventorySearch(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    setInventoryId(inventorySearch);
                  }
                }}
                width="none"
                fullWidth
              />
              <Button
                disabled={loading}
                variant="contained"
                sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                onClick={() => setInventoryId(inventorySearch)}
              >
                Search
              </Button>
            </Box>
            <Box sx={{ textAlign: "center" }}>or</Box>
            <Button variant="contained" startIcon={<QrCodeScanner />} onClick={() => setShowScan(true)}>
              scan qr code
            </Button>
          </Box>
          {showScan && (
            <QRScanner open={showScan} handleClose={() => setShowScan(false)} readCompleted={handleScannedQR} />
          )}
        </>
      )}
    </Box>
  );
}
