import { DataGridProProps } from "@mui/x-data-grid-pro/models/dataGridProProps";
import * as React from "react";
import { DataGridPro, GridColumnsPanel, useGridApiContext } from "@mui/x-data-grid-pro";
import { Box, Button, Stack } from "@mui/material";
import { debug } from "components/DataGrid/helpers/debug";
import { useMemo } from "react";

export interface PersistableSettingsProps {
  persistSettingsKey?: string;
}

function persistenceKeyPrefix(key: string) {
  return `dataGrid.${key}`;
}

function loadPersistedState(key: string | undefined, name: string, defaultValue: unknown) {
  if (!key) return defaultValue;
  const rawValue = localStorage.getItem(persistenceKeyPrefix(key) + "." + name);
  if (!rawValue) return defaultValue;
  return JSON.parse(rawValue);
}

function persistSetting(key: string, name: string, value: unknown) {
  localStorage.setItem(persistenceKeyPrefix(key) + "." + name, JSON.stringify(value));
}

function deletePersistedSetting(key: string, name: string) {
  localStorage.removeItem(persistenceKeyPrefix(key) + "." + name);
}

type CustomColumnsPanelProps = PersistableSettingsProps &
  Pick<DataGridProProps, "columns" | "initialState" | "checkboxSelection">;

// This custom columns panel adds a reset button to restore the grid back to its default state.
const CustomColumnsPanel = ({
  persistSettingsKey,
  columns,
  initialState,
  checkboxSelection,
  ...props
}: React.ComponentProps<typeof GridColumnsPanel> & CustomColumnsPanelProps) => {
  const apiRef = useGridApiContext();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <GridColumnsPanel {...props} sx={{ minHeight: 0 }} />
      {persistSettingsKey && (
        <Stack sx={{ p: 0.5 }}>
          <Button
            onClick={() => {
              if (checkboxSelection) apiRef.current.setColumnIndex("__check__", 0);

              columns.forEach((col, index) => {
                apiRef.current.setColumnIndex(col.field, checkboxSelection ? index + 1 : index);
                apiRef.current.setColumnWidth(col.field, col.width || 100);
              });
              apiRef.current.setColumnVisibilityModel(initialState?.columns?.columnVisibilityModel || {});
              deletePersistedSetting(persistSettingsKey, "dimensions");
              deletePersistedSetting(persistSettingsKey, "columnVisibilityModel");
              deletePersistedSetting(persistSettingsKey, "orderedFields");
            }}
          >
            Reset to Defaults
          </Button>
        </Stack>
      )}
    </Box>
  );
};

function persistDimensionsChange(key: string, field: string, width: number) {
  const previous = loadPersistedState(key, "dimensions", {});
  const next = { ...previous, [field]: { width } };
  persistSetting(key, "dimensions", next);
}

function persistOrderedFieldsChange(
  key: string,
  columns: DataGridProProps["columns"],
  targetIndex: number,
  field: string,
) {
  const previous = loadPersistedState(key, "orderedFields", []);

  if (previous.length === 0) {
    const allFields = columns.map((col) => col.field);
    previous.splice(0, 0, ...allFields);
  }
  const filtered = previous.filter((f: string) => f !== field);

  const next = filtered.toSpliced(targetIndex, 0, field);
  persistSetting(key, "orderedFields", next);
}

// If there are new columns, add them to the end of the orderedFields array
function addMissingFieldsToOrderedFields(orderedFields: string[], columnFields: string[]) {
  const missingFields = columnFields.filter((field) => !orderedFields.includes(field));
  return [...orderedFields, ...missingFields];
}

export interface PersistableSettingsDataGridProps extends DataGridProProps {
  // A unique key used to persist the settings to local storage.
  persistSettingsKey?: string;
}

export const PersistableSettingsDataGrid = ({ persistSettingsKey, ...props }: PersistableSettingsDataGridProps) => {
  const key = persistSettingsKey;
  const persistedColumnVisibilityModel = useMemo(() => loadPersistedState(key, "columnVisibilityModel", {}), [key]);
  const persistedOrderedFields = useMemo(() => {
    const saved = loadPersistedState(key, "orderedFields", []);
    if (saved.length === 0) return saved;
    if (props.checkboxSelection) saved.unshift("__check__");
    const columnFields = props.columns.map((col) => col.field);
    return addMissingFieldsToOrderedFields(saved, columnFields);
  }, [key, props.checkboxSelection, props.columns]);
  const persistedDimensions = useMemo(() => loadPersistedState(key, "dimensions", {}), [key]);

  const initialState = useMemo(() => {
    const decoratedState = {
      ...props.initialState,
      columns: {
        columnVisibilityModel: {
          ...props.initialState?.columns?.columnVisibilityModel,
          ...persistedColumnVisibilityModel,
        },
        orderedFields: persistedOrderedFields || props.initialState?.columns?.orderedFields,
        dimensions: {
          ...props.initialState?.columns?.dimensions,
          ...persistedDimensions,
        },
      },
    };
    debug("Persisted Settings Initial State", decoratedState);
    return decoratedState;
    // We are only interested in the initial state, so we ignore the dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!key) return <DataGridPro {...props} />;

  return (
    <DataGridPro
      {...props}
      initialState={initialState}
      onColumnVisibilityModelChange={(model) => {
        persistSetting(key, "columnVisibilityModel", model);
      }}
      onColumnOrderChange={({ targetIndex, field }) => {
        // When checkboxSelection is enabled, the index of the target field is off by one
        const adjustedIndex = props.checkboxSelection ? targetIndex - 1 : targetIndex;
        persistOrderedFieldsChange(key, props.columns, adjustedIndex, field);
      }}
      onColumnWidthChange={({ width, colDef }) => {
        persistDimensionsChange(key, colDef.field, width);
      }}
      components={{ ...props.components, ColumnsPanel: CustomColumnsPanel }}
      componentsProps={{
        ...props.componentsProps,
        columnsPanel: {
          persistSettingsKey,
          columns: props.columns,
          initialState: props.initialState,
          checkboxSelection: props.checkboxSelection,
        },
      }}
    />
  );
};
