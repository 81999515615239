import { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Box, Typography } from "@mui/material";

export const PageTitleContainer = () => {
  return <Box id="page-title"></Box>;
};

export const PageTitle = ({ children }: { children: ReactNode }) => {
  const [rendered, setRendered] = useState(false);
  useEffect(() => {
    setRendered(true);
  }, []);
  if (!rendered) return null;
  return createPortal(
    <Typography variant="h5" component="h1" sx={{ marginBottom: 2 }}>
      {children}
    </Typography>,
    document.getElementById("page-title") as HTMLElement,
  );
};
