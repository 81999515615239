import { fetcher } from "services/api.service";
import useSWR from "swr";
import { Inventory } from "types/inventory";

export type OrderItem = Inventory & { charger: string; trackingNumber?: string };

export enum BatchState {
  Created = "created",
  Picking = "picking",
  Packing = "packing",
  Complete = "complete",
}

export function useFulfillmentBatchItem({ batchId, itemId }: { batchId?: number | string; itemId?: number | string }) {
  const { data, isLoading, mutate, error } = useSWR<OrderItem>(
    itemId ? `/fulfillment_batches/${batchId}/items/${itemId}` : null,
    fetcher,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );

  return {
    inventory: data,
    isLoading,
    mutate,
    error,
  };
}
