import { GridFilterModel, GridSortModel } from "@mui/x-data-grid";
import { GridLinkOperator } from "@mui/x-data-grid-pro";
import { camelCase, capitalize } from "lodash";

export function deserializeSortParamsToSortModel(sort: string): GridSortModel {
  if (sort.trim().length === 0) return [];
  return sort.split(",").map((col) => {
    const direction = col.startsWith("-") ? "desc" : "asc";
    const field = direction === "desc" ? col.slice(1) : col;
    return { field, sort: direction };
  });
}

export function deserializeFilterParamsToFilterModel(
  filters: string[],
  linkOperatorString?: string,
): GridFilterModel | undefined {
  if (filters.length === 0) return;
  let linkOperator = GridLinkOperator.And;
  const normalizedOpString = capitalize(linkOperatorString);
  if (normalizedOpString === "And" || normalizedOpString === "Or") {
    linkOperator = GridLinkOperator[normalizedOpString];
  }
  const items = filters.map((filter) => {
    const [field, operator, ...values] = filter.split(",");
    const camelField = camelCase(field);
    return {
      columnField: camelField,
      operatorValue: camelCase(operator) || operator,
      value: values.join(","),
      id: `${camelField}-${values.join(",")}`,
    };
  });
  return { items, linkOperator };
}
