import { useAlert } from "hooks/useAlert";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { SubmitAwareButton } from "components/Form/SubmitAwareButton";
import { SaveAlt } from "@mui/icons-material";
import { RevivnToolbarProps } from "components/DataGrid/components/RevivnToolbar";

export function GridRemoteDataExport(props: RevivnToolbarProps) {
  const { alertSuccess } = useAlert();
  const onSuccess = () => {
    alertSuccess("Generating CSV... We'll send you an email with the data when completed.");
  };

  if (!props.exportUrl) return null;

  return (
    <RevivnApiForm initialValues={{}} action={props.exportUrl} method="GET" onSuccess={onSuccess}>
      <SubmitAwareButton type="submit" startIcon={<SaveAlt />} color="primary">
        Export
      </SubmitAwareButton>
    </RevivnApiForm>
  );
}
