import * as React from "react";
import { useEffect, useState } from "react";
import { useConfirmation } from "hooks/useConfirmation";
import { useAlert } from "hooks/useAlert";
import { Box, Button, CircularProgress } from "@mui/material";
import { Cancel, Edit, Save } from "@mui/icons-material";
import { isAxiosError } from "utils/helpers";
import { RevivnToolbarProps, dispatchToolbarEvent } from "components/DataGrid/components/RevivnToolbar";

export const GridBulkEditToolbarButtons = (props: RevivnToolbarProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const { confirm } = useConfirmation();
  const { alertWarning, alertError } = useAlert();

  useEffect(() => {
    // When exiting bulk edit mode, reset the saving state
    if (!props.bulkEditMode) setIsSaving(false);
  }, [props.bulkEditMode]);

  const hasChanges = Object.keys(props.bulkEditChanges ?? {}).length > 0;
  return (
    <>
      {props.bulkEditable && !props.bulkEditMode && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexGrow: 1,
          }}
        >
          <Button
            variant={"contained"}
            size={"small"}
            startIcon={<Edit />}
            onClick={() => {
              dispatchToolbarEvent("bulkEditStart");
            }}
          >
            Edit
          </Button>
        </Box>
      )}
      {props.bulkEditMode && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexGrow: 1,
          }}
        >
          <Button
            variant={"contained"}
            size={"small"}
            disabled={!hasChanges || isSaving}
            startIcon={isSaving ? <CircularProgress size={18} /> : <Save />}
            onClick={() => {
              if (!props.bulkEditChanges) return;

              if (!props.processBulkUpdate) {
                alertWarning("No processBulkUpdate function provided. Changes not saved.");
                dispatchToolbarEvent("bulkEditSave");
                return;
              }

              confirm({
                message: `Are you sure you want to update ${Object.keys(props.bulkEditChanges).length} rows?`,
                handler: async () => {
                  if (!props.bulkEditChanges) return;
                  setIsSaving(true);
                  try {
                    await props.processBulkUpdate?.(props.bulkEditChanges);
                    dispatchToolbarEvent("bulkEditStop");
                    props.onBulkActionComplete?.();
                  } catch (e) {
                    if (isAxiosError(e)) {
                      alertError(`Failed to save changes: ${e.response?.data?.errors?.message}`);
                    } else {
                      alertError("Failed to save changes, please try again.");
                    }
                  } finally {
                    setIsSaving(false);
                  }
                },
              });
            }}
          >
            {isSaving ? "Saving..." : "Save"}
          </Button>
          <Button
            size={"small"}
            startIcon={<Cancel />}
            disabled={isSaving}
            onClick={() => {
              dispatchToolbarEvent("bulkEditCancel");
              dispatchToolbarEvent("bulkEditStop");
            }}
          >
            Cancel
          </Button>
        </Box>
      )}
    </>
  );
};
