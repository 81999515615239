import { fetcher } from "services/api.service";
import useSWR from "swr";
import { Inventory } from "types/inventory";

export type OrderItem = Inventory & { charger: string; trackingNumber?: string };

export enum BatchState {
  Created = "created",
  Picking = "picking",
  Packing = "packing",
  Complete = "complete",
}

export interface FulfillmentBatch {
  id: number;
  currentItemId: number | null;
  itemsCount: number;
  orderCount: number;
  state: BatchState;
  userEmail: string;
  stateCompleteCount: number;
  pickingAt: string;
  packingAt: string;
  completeAt: string;
  pickingDuration: string;
  packingDuration: string;
  endedPickingAt: string;
  onCartInventoryIds: number[];
}

export function useFulfillmentBatch({ batchId }: { batchId?: number | string }) {
  const { data, isLoading, mutate, error } = useSWR<FulfillmentBatch>(`/fulfillment_batches/${batchId}`, fetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });

  return {
    batch: data,
    isLoading,
    mutate,
    error,
  };
}
