import { Search } from "@mui/icons-material";
import {
  Box,
  InputLabel as MuiInputLabel,
  Select,
  SelectProps,
  styled,
  TextField as MuiTextField,
  TextFieldProps,
} from "@mui/material";
import React from "react";
import { OutlinedInput } from "@mui/material";

const TextField = styled(MuiTextField)({
  ".MuiInputBase-input": {
    borderColor: "rgb(206, 212, 218)",
    padding: "12px",
    background: "background.paper",
  },
  fieldset: {
    borderColor: "rgb(206, 212, 218)",
  },
});

export const InputBase = React.forwardRef<HTMLDivElement, TextFieldProps>((props, ref) => {
  return <TextField ref={ref} fullWidth variant="outlined" {...props} />;
});

export const InputLabel = styled(MuiInputLabel)(({ theme }) => ({
  position: "static",
  color: `${theme.palette.text.primary} !important`,
  fontSize: "16px",
  transform: "none",
  marginBottom: "4px",
}));

export function SearchInput({ sx, width = "415px", ...props }: TextFieldProps & { width?: string }) {
  return (
    <Box sx={{ position: "relative", width: "100%", maxWidth: width }}>
      <InputBase
        sx={sx}
        {...props}
        InputProps={{
          startAdornment: <Search sx={{ color: "action.active" }} />,
        }}
      />
    </Box>
  );
}

export function AppSelect<T>({ ...props }: SelectProps<T>) {
  return (
    <Select
      sx={{
        ".MuiSelect-standard": { padding: "0px !important" },
        ".MuiSelect-select.MuiInputBase-input": {
          borderColor: "rgb(206, 212, 218)",
          height: "44px !important",
          display: "flex",
          alignItems: "center !important",
          padding: "0px 12px !important",
          ":focus": {
            borderRadius: "4px",
            backgroundColor: "white",
          },
        },
        ".MuiSelect-iconStandard": { right: "8px !important" },
        fieldset: {
          borderColor: "rgb(206, 212, 218)",
        },
      }}
      input={<OutlinedInput sx={{}} />}
      {...props}
    ></Select>
  );
}

export function RequiredSymbol() {
  return <span style={{ color: "error.main", fontWeight: 600 }}>*</span>;
}
