import { Box, Typography, Grid } from "@mui/material";
import { NewPickup } from "types/pickup";
import DirectUploadField from "components/Form/DirectUploadField";

interface PhotoConfig {
  fieldName: keyof NewPickup;
  title: string;
}

const photoConfigs: PhotoConfig[] = [
  {
    fieldName: "walkthroughPhotos",
    title: "Walk Through Photos",
  },
  {
    fieldName: "onSiteLaptopPhotos",
    title: "On-Site Laptop Photos",
  },
  {
    fieldName: "onSiteInboundObjectPhotos",
    title: "On-Site IBO Photos",
  },
  {
    fieldName: "sealedPalletPhotos",
    title: "Sealed Pallet Photos",
  },
  {
    fieldName: "inboundPalletPhotos",
    title: "Inbound Pallet Photos",
  },
];

function PhotoSection({ config }: { config: PhotoConfig }) {
  return (
    <Box mb={4}>
      <Grid container justifyContent="space-between" direction="row" alignItems="center" sx={{ p: "10px", pr: "10px" }}>
        <Typography variant="subtitle1" fontWeight="bold">
          {config.title}
        </Typography>
      </Grid>
      <Box mt={1}>
        <DirectUploadField
          name={config.fieldName}
          service="amazon_public"
          accept={["image/jpeg", "image/png", "application/pdf"]}
          multiple
        />
      </Box>
    </Box>
  );
}

export default function LogisticsPhotos() {
  return (
    <Box>
      {photoConfigs.map((config) => (
        <PhotoSection key={config.fieldName} config={config} />
      ))}
    </Box>
  );
}
