import React from "react";
import { ClaimBatchStep } from "components/Picklist/New/ClaimBatchStep";
import { PageTitle } from "./PageTitle";
import { useIsLocal } from "hooks/useIsLocalhost";
import { CreateUnassignedItems } from "./CreateUnassignedItems";

export function PicklistNewPage() {
  const isLocal = useIsLocal();

  return (
    <PageTitle text="Outbound Units" subtitle="Fulfill e-commerce units for laptops in batches.">
      <ClaimBatchStep />
      {isLocal && <CreateUnassignedItems />}
    </PageTitle>
  );
}
