import { LoaderFunctionArgs } from "react-router-dom";
import { redirect } from "react-router-dom";
import { fetcher } from "services/api.service";
import { FulfillmentBatch } from "hooks/useFulfillmentBatch";
import { BatchState } from "hooks/useFulfillmentBatch";

export async function loader({ params }: LoaderFunctionArgs) {
  const { batchId } = params;

  const batch = await fetcher<FulfillmentBatch>(`/fulfillment_batches/${batchId}`);

  if (!batch) return redirect("/picklist/new");

  if (batch.state === BatchState.Created) return redirect(`/picklist/batches/${batchId}/start`);

  if (batch.state === BatchState.Complete) return redirect(`/picklist/batches/${batchId}/complete`);

  if (batch.currentItemId && batch.state === BatchState.Picking) {
    return redirect(`/picklist/batches/${batch.id}/items/${batch.currentItemId}/pick`);
  }

  if (batch.state === BatchState.Picking && !batch.currentItemId) {
    return redirect(`/picklist/batches/${batch.id}/picking-complete`);
  }

  if (batch.state === BatchState.Packing && !batch.currentItemId) {
    return redirect(`/picklist/batches/${batch.id}/packing-select-item`);
  }

  if (batch.state === BatchState.Packing && batch.currentItemId) {
    return redirect(`/picklist/batches/${batch.id}/items/${batch.currentItemId}/pack`);
  }

  // Throw error?
  return { batch };
}
