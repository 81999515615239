import { BatchState, FulfillmentBatch } from "hooks/useFulfillmentBatch";
import { Outlet, useLoaderData } from "react-router-dom";
import React from "react";
import { Paper, Step, StepLabel, Stepper, styled } from "@mui/material";
import { PicklistProgress } from "components/Picklist/common/PicklistProgress";

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const PicklistBatchLayout = () => {
  const { batch } = useLoaderData() as { batch: FulfillmentBatch };
  const total = batch.itemsCount;
  const activeStep = batch.state === BatchState.Picking ? 0 : 1;
  return (
    <>
      <Stepper activeStep={activeStep} sx={{ paddingInline: "2rem" }}>
        <Step
          sx={{
            ".MuiStepIcon-root.Mui-completed": { color: "success.main", opacity: 0.7 },
            ".MuiStepLabel-label.Mui-completed": { color: "grey.500" },
          }}
        >
          <StepLabel>Pick</StepLabel>
        </Step>
        <Step>
          <StepLabel>Pack</StepLabel>
        </Step>
      </Stepper>
      <StyledPaper variant="outlined" sx={{ padding: 3 }}>
        {batch.currentItemId && <PicklistProgress complete={batch.stateCompleteCount + 1} total={total} />}
        <Outlet context={{ batch }} />
      </StyledPaper>
    </>
  );
};
