import { PicklistCard } from "components/Picklist/common/PicklistCard";
import { Check } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";

export function BatchCompletePage() {
  const navigate = useNavigate();
  return (
    <>
      <PicklistCard title="All Inventory Packed" Icon={Check}>
        <Typography textAlign="center">You’re all done!</Typography>
      </PicklistCard>
      <Stack alignItems="center" gap={2} mt={8}>
        <Button variant="contained" onClick={() => navigate("/picklist")}>
          Exit
        </Button>
      </Stack>
    </>
  );
}
