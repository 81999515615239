import { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { api } from "services/api.service";
import { Pickup } from "types/pickup";
import { useAlert } from "./useAlert";

type PickupQuote = {
  id?: string;
  pickupAgentId: string;
  amountInCents: number | null;
};

type LogisticsDetailsProps = {
  agentId?: string;
  subAgentId?: string;
  estimatedCostInCents: number;
  costInCents: number;
  estimatedInvoiceCostInCents: number;
  secondEstimatedInvoiceCostInCents: number;
  invoiceCostInCents: number;
  itadPaymentInCents: number;
  pickupQuotes: PickupQuote[];
};

export default function useLogisticsDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { alertSuccess } = useAlert();
  const [isLoading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [logisticsDetails, setLogisticsDetails] = useState<any>();
  const [logisticsPickup, setLogisticsPickup] = useState<Pickup>();

  const updateLogisticsDetails = (details: LogisticsDetailsProps) => {
    setLoading(true);
    const logisticsDetailsPayload = Object.assign({}, details);
    if (logisticsDetailsPayload.estimatedCostInCents !== null)
      logisticsDetailsPayload.estimatedCostInCents = Math.ceil(logisticsDetailsPayload.estimatedCostInCents * 100);
    if (logisticsDetailsPayload.costInCents !== null)
      logisticsDetailsPayload.costInCents = Math.ceil(logisticsDetailsPayload.costInCents * 100);
    if (logisticsDetailsPayload.estimatedInvoiceCostInCents !== null)
      logisticsDetailsPayload.estimatedInvoiceCostInCents = Math.ceil(
        logisticsDetailsPayload.estimatedInvoiceCostInCents * 100,
      );
    if (logisticsDetailsPayload.secondEstimatedInvoiceCostInCents !== null)
      logisticsDetailsPayload.secondEstimatedInvoiceCostInCents = Math.ceil(
        logisticsDetailsPayload.secondEstimatedInvoiceCostInCents * 100,
      );

    if (logisticsDetailsPayload.pickupQuotes) {
      logisticsDetailsPayload.pickupQuotes = logisticsDetailsPayload.pickupQuotes.map((quote: PickupQuote) => ({
        ...quote,
        amountInCents: quote.amountInCents !== null ? Math.ceil(quote.amountInCents * 100) : null,
      }));
    }

    if (logisticsDetailsPayload.invoiceCostInCents !== null)
      logisticsDetailsPayload.invoiceCostInCents = Math.ceil(logisticsDetailsPayload.invoiceCostInCents * 100);
    logisticsDetailsPayload.itadPaymentInCents = Math.ceil(logisticsDetailsPayload.itadPaymentInCents * 100);

    api
      .post(`/pickups/${params.pickupUuid}/logistics_details`, {
        logisticsDetails: logisticsDetailsPayload,
      })
      .then(() => {
        setLoading(false);
        alertSuccess("Successfully updated pickup.");
        navigate("/logistics?logistics_status=scheduled");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getLogisticsDetails = useCallback(async () => {
    setLoading(true);
    try {
      await api
        .get(`/pickups/${params.pickupUuid}/logistics_details`)
        .then(({ data: { logisticsDetails: fetchedLogisticsDetails } }) => {
          console.log("fetchedLogisticsDetails", fetchedLogisticsDetails);
          setLogisticsDetails(fetchedLogisticsDetails);
        });
      await api.get(`/logistics_pickups/${params.pickupUuid}`).then(({ data }) => {
        setLogisticsPickup(data);
      });
    } finally {
      setLoading(false);
    }
  }, [params.pickupUuid]);

  useEffect(() => {
    getLogisticsDetails();
  }, [getLogisticsDetails, search]);

  return {
    updateLogisticsDetails,
    logisticsDetails,
    logisticsPickup,
    isLoading,
  };
}
