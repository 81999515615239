import React from "react";
import { PackInventoryWizard } from "components/Picklist/CurrentBatch/Packing/PackInventoryWizard";
import { useRouteLoaderData } from "react-router-dom";
import { OrderItem } from "hooks/useFulfillmentBatch";

export function PackItemPage() {
  const { item } = useRouteLoaderData("picklist-batch-item") as { item: OrderItem };

  return (
    <>
      <PackInventoryWizard key={item.id} item={item} />
    </>
  );
}
