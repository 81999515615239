import { Outlet, useLoaderData } from "react-router-dom";
import { OrderItem } from "hooks/useFulfillmentBatch";
import { useIsLocal } from "hooks/useIsLocalhost";
import { CancelItemButton } from "pages/Picklist/CancelItem";
import React from "react";

export const PicklistBatchItemLayout = () => {
  const { item } = useLoaderData() as { item: OrderItem };
  const isLocal = useIsLocal();

  return (
    <>
      <Outlet />
      {isLocal && item.itemState !== "cancelled" && <CancelItemButton />}
    </>
  );
};
