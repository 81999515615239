import React from "react";
import { DestinationType, ScanAndMoveItemStep } from "components/Picklist/CurrentBatch/Picking/ScanAndMoveItemStep";
import { useRouteLoaderData } from "react-router-dom";
import { OrderItem } from "hooks/useFulfillmentBatch";
import { ReleaseInventoryStep } from "components/Picklist/CurrentBatch/ReleaseInventoryStep";
import { PicklistInventoryCard } from "components/Picklist/common/PicklistInventoryCard";
import { Alert, AlertTitle } from "@mui/material";

export const ItemCancelledPage = () => {
  const { item } = useRouteLoaderData("picklist-batch-item") as { item: OrderItem };
  const onCart = item.warehouseLocation.startsWith("PICK-");

  return (
    <>
      <Alert severity="error">
        <AlertTitle>ORDER CANCELED</AlertTitle>
        Order has been canceled and unit removed from fulfillment batch.
      </Alert>
      <PicklistInventoryCard inventory={item} condensed={true} title="Inventory" />
      {onCart && <ScanAndMoveItemStep item={item} destination={DestinationType.RecoveryCart} />}
      {!onCart && <ReleaseInventoryStep />}
    </>
  );
};
