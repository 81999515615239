import { LoaderFunctionArgs } from "react-router-dom";
import { redirect } from "react-router-dom";
import { fetcher } from "services/api.service";
import { FulfillmentBatch } from "hooks/useFulfillmentBatch";
import { BatchState } from "hooks/useFulfillmentBatch";

export async function loader({ params }: LoaderFunctionArgs) {
  const { batchId } = params;

  const batch = await fetcher<FulfillmentBatch>(`/fulfillment_batches/${batchId}`);
  if (batch.state !== BatchState.Created) return redirect(`batches/${batchId}`);
  return { batch };
}
