import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Laptop from "@mui/icons-material/Laptop";
import * as Yup from "yup";
import React, { useCallback, useEffect, useState } from "react";
import usePickup from "hooks/usePickup";
import { useNavigate, useParams } from "react-router-dom";
import { Modal } from "components/common/Modal";
import { Check, Mediation, PublishedWithChanges, Replay } from "@mui/icons-material";
import { userHasPermission } from "utils/userPermissions";
import useCurrentUser from "hooks/useCurrentUser";
import PickupStateChip from "components/PickupState";
import { format, parseISO } from "date-fns";
import { ElasticDataGrid } from "components/DataGrid/RevivnElasticDataGrid";
import useSWR from "swr";
import { Pickup } from "types/pickup";
import { columns } from "components/Inventories/DataGrid/datagridColumns";
import { debounce } from "lodash";
import { api } from "services/api.service";
import { GridBulkPrintActionItem } from "components/Inventories/DataGrid/GridBulkPrintActionItem";
import { GridBulkActionsItem } from "components/DataGrid/components/GridBulkActionsItem";
import DeleteIcon from "@mui/icons-material/Delete";
import { BulkActions } from "components/Inventories/DataGrid/BulkActions";
import useInventoryTasks from "hooks/useTasks";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ActionProgressDrawer from "components/Inventories/ActionProgressDrawer";

interface IRModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;

  handleIrReady(): void;
}

export function IRModal({ open, setOpen, handleIrReady }: IRModalProps) {
  return (
    <Modal open={open} setOpen={setOpen} width={"683px"}>
      <Box mt={4}>
        <Box sx={{ textAlign: "center" }}>
          <Check color="primary" sx={{ fontSize: "60px" }} />
          <Typography mt={1} variant="h5" component="h2" fontWeight={800} fontSize={"24px"}>
            Initial Report Ready{" "}
          </Typography>
          <Typography mt={1} color="text.secondary" fontSize={"16px"}>
            Are you sure you want to proceed with publishing the initial report? (Note: The user(s) will receive an
            email notification and see this reflected under their pickup.){" "}
          </Typography>

          <Box mt={4.5} sx={{ display: "flex", gap: 1, alignItems: "center", justifyContent: "center" }}>
            <Button variant="contained" onClick={() => handleIrReady()}>
              Yes, publish
            </Button>
            <Button variant="contained" onClick={() => setOpen(false)}>
              no, go back
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

interface FRModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;

  handleFrReady(): void;

  handleForceFrReady(): void;
}

export function FRModal({ open, setOpen, handleFrReady, handleForceFrReady }: FRModalProps) {
  const [bypassOnHold, setBypassOnHold] = useState(false);

  const publish = useCallback(() => {
    if (bypassOnHold) {
      handleForceFrReady();
    } else {
      handleFrReady();
    }
    // I don't think we should bypass eslint exhaustive deps here, but this is how it worked before
    // so leaving for now.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleFrReady, bypassOnHold]);

  return (
    <Modal open={open} setOpen={setOpen} width={"683px"}>
      <Box mt={4}>
        <Box sx={{ textAlign: "center" }}>
          <Check color="primary" sx={{ fontSize: "60px" }} />
          <Typography mt={1} variant="h5" component="h2" fontWeight={800} fontSize={"24px"}>
            Final Report Ready
          </Typography>
          <Typography mt={1} color="text.secondary" fontSize={"16px"}>
            Are you sure you want to proceed with publishing the final report? (Note: The user(s) will receive an email
            notification and see this reflected under their pickup.)
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 3,
            }}
          >
            <FormControlLabel
              control={<Checkbox checked={bypassOnHold} onChange={(e) => setBypassOnHold(e.target.checked)}></Checkbox>}
              label={
                <Typography color="text.secondary" fontSize={"16px"}>
                  Bypass 'On Hold' state and complete pickup
                </Typography>
              }
            />
          </Box>

          <Box mt={4.5} sx={{ display: "flex", gap: 1, alignItems: "center", justifyContent: "center" }}>
            <Button variant="contained" onClick={publish}>
              Yes, publish
            </Button>
            <Button variant="contained" onClick={() => setOpen(false)}>
              no, go back
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

function InventoryTasksButton() {
  const [isDrawerOpen, toggleDrawer] = useState(false);
  const { createInventoryTasks } = useInventoryTasks();

  const handleClick = () => {
    toggleDrawer(!isDrawerOpen);
    createInventoryTasks();
  };

  return (
    <div>
      <Button startIcon={<ListAltIcon />} onClick={handleClick}>
        View Actions
      </Button>
      {isDrawerOpen ? <ActionProgressDrawer toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen} /> : null}
    </div>
  );
}

export function ElasticPickupInventoriesPage() {
  const navigate = useNavigate();
  const params = useParams();
  const { updatePickup, irReady, frReady, forceFrReady } = usePickup();

  const { data: pickup } = useSWR<Pickup>(`/pickups/${params.pickupId}`);
  const [irOpen, setIrOpen] = useState(false);
  const [frOpen, setFrOpen] = useState(false);
  const [ewasteValue, setEwasteValue] = useState<Pickup["ewasteInLbs"]>(0);
  const [ewasteValueMissingError, setEwasteValueMissingError] = useState(false);
  const { currentUserPermissions } = useCurrentUser();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceInput = useCallback(
    debounce((wasteValue) => {
      if (!pickup) return;
      updatePickup({ ...pickup, ewasteInLbs: wasteValue });
    }, 1000),
    [pickup],
  );

  useEffect(() => {
    if (!pickup) return;
    setEwasteValue(pickup.ewasteInLbs);
  }, [pickup, setEwasteValue]);

  if (!pickup) return null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleEwasteChange = (e: any) => {
    setEwasteValueMissingError(false);
    setEwasteValue(e.target.value);
    debounceInput(e.target.value);
  };
  const handleIrReady = () => {
    irReady(pickup.uuid).then(() => setIrOpen(false));
  };

  const handleFrReady = () => {
    frReady(pickup.uuid).then(() => setFrOpen(false));
  };

  const handleForceFrReady = () => {
    forceFrReady(pickup.uuid).then(() => setFrOpen(false));
  };
  const macBackgroundColor = () => {
    const estimate = pickup.postPickupForms[0]?.appleLaptopCount;

    const actual = pickup.appleLaptopCount;
    if (estimate === actual) {
      return "#FFF";
    } else {
      return "#FFF2F1";
    }
  };
  const pcBackgroundColor = () => {
    const estimate = pickup.postPickupForms[0]?.pcLaptopCount;

    const actual = pickup.pcLaptopCount;
    if (estimate === actual) {
      return "#FFF";
    } else {
      return "#FFF2F1";
    }
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: 1,
        gridTemplateRows: "auto 1fr",
        flexGrow: 1,
        minHeight: "600px",
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        sx={{ padding: "20px 25px 0 25px" }}
      >
        <Grid>
          {pickup.repairPickup && (
            <Box display="flex" alignItems="center" gap={1} mb={3}>
              <Typography variant="h5">Return & Warranty Repairs</Typography>
              <Chip
                label={<Replay sx={{ fontSize: 12 }} />}
                sx={{ padding: 0, backgroundColor: "rgba(242, 160, 43, 0.3)", color: "#D58031" }}
              />
            </Box>
          )}
          {pickup.returnPickup && (
            <Box display="flex" alignItems="center" gap={1} mb={3}>
              <Typography variant="h5">Redeploy Inventory</Typography>
              <Chip
                label={<Mediation sx={{ fontSize: 12 }} />}
                sx={{ padding: 0, backgroundColor: "rgba(106, 104, 207, 0.2)", color: "#6A68CF" }}
              />
            </Box>
          )}
          <Typography variant="h6">
            Pickup {pickup.id} <PickupStateChip pickupState={pickup.state} />
            {pickup.virtualPickup && <Chip label={"Virtual Pickup"} />}
          </Typography>
        </Grid>
        <Grid>
          <FormControl>
            <TextField
              id="ewasteInLbs"
              name="ewasteInLbs"
              value={ewasteValue}
              size="small"
              label="E-waste"
              autoComplete="off"
              onChange={handleEwasteChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
              }}
              error={ewasteValueMissingError}
              helperText={ewasteValueMissingError && "E-waste should be either 0 or a positive"}
              sx={{ width: "150px", pr: "20px" }}
            ></TextField>
          </FormControl>

          <Button
            variant="contained"
            sx={{ width: "150px", mr: "20px", marginBottom: "5px" }}
            startIcon={<Laptop />}
            onClick={() => navigate("inventories/new")}
          >
            Add Items
          </Button>
          <Button
            variant="outlined"
            sx={{ width: "175px", marginBottom: "5px" }}
            startIcon={<AddAPhotoIcon />}
            onClick={() => navigate("/receiving")}
          >
            Add Photos
          </Button>
        </Grid>

        {userHasPermission("warehouse_manager", currentUserPermissions) && (
          <Grid container justifyContent="space-between" direction="row" alignItems="center" sx={{ mt: 2 }}>
            <Grid>
              {!pickup.repairPickup && !pickup.returnPickup && (
                <Box display="flex" flexDirection="row" gap={1}>
                  <Button
                    startIcon={<PublishedWithChanges />}
                    onClick={() => {
                      if (Yup.number().min(0).isValidSync(ewasteValue)) {
                        setIrOpen(true);
                      } else {
                        setEwasteValueMissingError(true);
                      }
                    }}
                    variant="contained"
                    disabled={!!pickup.initialReportPublishedAt}
                  >
                    Publish IR{" "}
                    {pickup.initialReportPublishedAt && format(parseISO(pickup.initialReportPublishedAt), "MM/dd/yy")}
                  </Button>
                  <IRModal open={irOpen} setOpen={setIrOpen} handleIrReady={handleIrReady} />
                  <Button
                    startIcon={<PublishedWithChanges />}
                    onClick={() => {
                      setFrOpen(true);
                    }}
                    disabled={!!pickup.finalReportPublishedAt}
                    variant="contained"
                  >
                    Publish FR{" "}
                    {pickup.finalReportPublishedAt && format(parseISO(pickup.finalReportPublishedAt), "MM/dd/yy")}
                  </Button>
                  <FRModal
                    open={frOpen}
                    setOpen={setFrOpen}
                    handleFrReady={handleFrReady}
                    handleForceFrReady={handleForceFrReady}
                  />
                  <InventoryTasksButton />
                </Box>
              )}
            </Grid>
            <Grid>
              <Box>
                <TextField
                  label="Estimate Macs"
                  size="small"
                  style={{ width: 115, backgroundColor: macBackgroundColor() }}
                  value={pickup.postPickupForms[0] && pickup.postPickupForms[0].appleLaptopCount}
                  InputProps={{
                    readOnly: true,
                  }}
                  focused
                />
                <TextField
                  label="Processed Macs"
                  size="small"
                  style={{ marginLeft: 10, width: 115 }}
                  value={pickup.appleLaptopCount}
                  InputProps={{
                    readOnly: true,
                  }}
                  focused
                />
                <TextField
                  label="Estimate PCs"
                  size="small"
                  style={{ marginLeft: 10, width: 115, backgroundColor: pcBackgroundColor() }}
                  value={pickup.postPickupForms[0] && pickup.postPickupForms[0].pcLaptopCount}
                  InputProps={{
                    readOnly: true,
                  }}
                  focused
                />
                <TextField
                  label="Processed PCs"
                  size="small"
                  style={{ marginLeft: 10, width: 115 }}
                  value={pickup.pcLaptopCount}
                  InputProps={{
                    readOnly: true,
                  }}
                  focused
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Paper>
        <BulkActions />
        <ElasticDataGrid
          bulkEditable={true}
          autoHeight={false}
          columns={columns}
          density={"compact"}
          checkboxSelection
          getBulkActions={() => [
            <GridBulkPrintActionItem />,
            <GridBulkActionsItem icon={<DeleteIcon />} eventName={"bulkDelete"} label={"Delete"} />,
          ]}
          syncStateWithSearchParams
          defaultToMostRecentParams
          isCellEditable={(cellParams) => {
            return cellParams.row.inventoryType === "internal";
          }}
          processBulkUpdate={(changes) => {
            const updates = Object.entries(changes).map(([id, update]) => ({ id, ...update }));
            return api.put("inventories/bulk/edit", { inventories: updates });
          }}
          persistSettingsKey={`pickup_inventories`}
          url={`pickups/${pickup.id}/combined_inventories`}
          initialState={{
            columns: {
              columnVisibilityModel: {
                printed: false,
                lineId: false,
                pickupId: false,
                tags: false,
                iqInventoryId: false,
                iqPoIdLine: false,
                warehouseId: false,
                manifestId: false,
                invoicedAt: false,
              },
            },
          }}
        />
      </Paper>
    </Box>
  );
}
