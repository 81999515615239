import React from "react";
import { Check } from "@mui/icons-material";
import { useNavigate, useRevalidator, useRouteLoaderData } from "react-router-dom";
import { OrderItem } from "hooks/useFulfillmentBatch";
import { CheckList, CheckListItem } from "components/Picklist/common/CheckList";
import { PicklistCard } from "components/Picklist/common/PicklistCard";
import { Button, Stack } from "@mui/material";
import { RevivnApiForm } from "components/Form/RevivnApiForm";

export const ReleaseInventoryStep = () => {
  const { item } = useRouteLoaderData("picklist-batch-item") as { item: OrderItem };
  const { revalidate } = useRevalidator();
  const navigate = useNavigate();

  return (
    <>
      <PicklistCard title="Actions" Icon={Check}>
        <CheckList>
          <CheckListItem label={"Scanned Inventory"} />
          <CheckListItem label={item.warehouseLocation} />
        </CheckList>
      </PicklistCard>

      <Stack justifyItems="center" alignItems="center" gap={1} mt={8}>
        Please proceed to the next unit.
        <RevivnApiForm
          initialValues={{}}
          action={`/fulfillment_batches/${item.batchId}/items/${item.itemId}/release_inventory`}
          method="POST"
          onSuccess={async () => {
            navigate(`/picklist/batches/${item.batchId}`, { replace: true });
            revalidate();
          }}
        >
          <Button variant="contained" type="submit">
            Next Unit
          </Button>
        </RevivnApiForm>
      </Stack>
    </>
  );
};
