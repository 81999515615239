import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Card, IconButton, List, ListItem, ListItemText, Theme, Typography } from "@mui/material";
import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GridRenderCellParams,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { RevivnApiDataGrid } from "components/DataGrid/RevivnApiDataGrid";
import { isValidElement, useCallback } from "react";
import { Listing } from "types/listing";

function CustomDetailPanelToggle(props: Pick<GridRenderCellParams, "id" | "value">) {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();
  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);
  const hasDetail = isValidElement(contentCache[id]);

  return (
    <IconButton size="small" tabIndex={-1} disabled={!hasDetail}>
      <ExpandMoreIcon
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: (theme: Theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shortest,
            }),
        }}
        fontSize="inherit"
      />
    </IconButton>
  );
}

const toTimestamp = (value: string) => new Date(value).toLocaleString();
const toPrice = (value: string | number) => Number(value).toFixed(2);
const booleanFormatter = (params: { value: boolean }) => (params.value ? true : false);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const timestampRenderer = (params: GridRenderCellParams<any, Listing, any>) => toTimestamp(params.value);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const priceRenderer = (field: "price" | "priceForBuybox") => (params: GridRenderCellParams<any, Listing, any>) =>
  params.row[field] ? `$${toPrice(params.row[field])}` : "-";

export default function ListingsDataGrid() {
  const columns: GridColDef<Listing>[] = [
    { field: "id", headerName: "ID" },
    { field: "type", headerName: "Store" },
    { field: "sku", headerName: "SKU" },
    { field: "quantity", headerName: "Quantity", type: "number" },
    { field: "state", headerName: "State", filterable: false, sortable: false },
    {
      field: "priceInCents",
      headerName: "Price",
      type: "number",
      renderCell: priceRenderer("price"),
    },
    {
      field: "priceForBuyboxInCents",
      headerName: "Buybox Price",
      type: "number",
      renderCell: priceRenderer("priceForBuybox"),
    },
    {
      field: "buybox",
      headerName: "Buybox",
      type: "boolean",
      valueFormatter: booleanFormatter,
      filterable: false,
      sortable: false,
    },
    {
      field: "sameMerchantWinner",
      headerName: "Same Merchant",
      description: "Same merchant won the buybox",
      type: "boolean",
      valueFormatter: booleanFormatter,
      filterable: false,
      sortable: false,
    },
    { field: "product", headerName: "Product", filterable: false, sortable: false },
    { field: "condition", headerName: "Condition", filterable: false, sortable: false },
    {
      field: "createdAt",
      headerName: "Created",
      renderCell: timestampRenderer,
      filterable: false,
      width: 200,
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      renderCell: timestampRenderer,
      filterable: false,
      width: 200,
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => <CustomDetailPanelToggle {...params} />,
      filterable: false,
      hideable: false,
      headerName: "Details",
      type: "string",
    },
  ];

  const getDetailPanelContent = useCallback(({ row }: { row: Listing }) => {
    if (row.priceHistories && row.priceHistories.length > 0) {
      return (
        <Box padding={2} bgcolor={(theme) => theme.palette.divider}>
          <List component={Card} variant="elevation">
            <ListItem divider dense>
              <Typography variant="h6">Price history for {row.sku}</Typography>
            </ListItem>
            <Box overflow="scroll" maxHeight={300}>
              {row.priceHistories.map((historyItem) => (
                <ListItem divider key={historyItem.id}>
                  <ListItemText
                    primary={`Price change on ${toTimestamp(historyItem.createdAt)}`}
                    secondary={`Adjusted by ${toPrice(historyItem.newPrice - historyItem.oldPrice)}`}
                  />
                  <ListItemText
                    primary={`New Price: $${toPrice(historyItem.newPrice)}`}
                    secondary={`Old Price: $${toPrice(historyItem.oldPrice)}`}
                  />
                  <ListItemText
                    primary={`Price for Buybox: $${toPrice(historyItem.priceForBuybox)}`}
                    secondary={`Quantity: ${historyItem.quantity}`}
                  />
                  {historyItem.pricedByBot && <ListItemText primary="Price Updated By Bot" />}
                </ListItem>
              ))}
              {/* TODO: Progressively load more price histories when we reach the bottom of this scrollbox.
                  Similarly to how we do with inventory events.
                  This should help reduce excessive amounts of price history data being parsed for those
                  listings that may have been in a buybox war. */}
            </Box>
          </List>
        </Box>
      );
    } else {
      return null;
    }
  }, []);

  return (
    <RevivnApiDataGrid
      sx={{
        backgroundColor: "background.paper",
        minHeight: 600,
        height: "calc(100vh - 10em)",
      }}
      columns={columns}
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={() => "auto"}
      components={{ Toolbar: GridToolbar }}
      componentsProps={{
        filterPanel: { visible: false },
        toolbar: {
          printOptions: { disableToolbarButton: true },
          csvOptions: { disableToolbarButton: true, fileName: `listings_${Date.now()}` },
        },
      }}
      filtersStorageName={"listingFiltersStorage"}
      url={"listings"}
    />
  );
}
