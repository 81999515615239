import { PicklistCard } from "components/Picklist/common/PicklistCard";
import { LaptopMac } from "@mui/icons-material";
import { DataList, DataListItem } from "components/Picklist/common/DataList";
import React from "react";
import { useIsLocal } from "hooks/useIsLocalhost";
import { OrderItem } from "hooks/useFulfillmentBatch";

export function PicklistInventoryCard({
  condensed = false,
  inventory,
  title = "Inventory to Pick",
  children,
}: {
  inventory: OrderItem;
  condensed?: boolean;
  title?: string;
  children?: React.ReactNode;
}) {
  const isLocal = useIsLocal();
  return (
    <PicklistCard title={title} Icon={LaptopMac}>
      <DataList>
        <DataListItem label="Label ID" value={inventory?.label} />
        {isLocal && <DataListItem label="(devonly) Inventory ID" value={inventory?.id} />}
        {!condensed && (
          <>
            <DataListItem label="Year" value={inventory?.year} />
            <DataListItem label="Type" value={inventory?.deviceType} />
            <DataListItem label="Size" value={inventory?.screenSize} />
            <DataListItem label="Color" value={inventory?.color} />
            <DataListItem label="Grade" value={inventory?.condition} />
          </>
        )}
        <DataListItem label="Location" value={inventory?.warehouseLocation} />
        {inventory.trackingNumber && <DataListItem label="Tracking #" value={inventory?.trackingNumber} />}
      </DataList>
      {children}
    </PicklistCard>
  );
}
