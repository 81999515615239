import { Box, Button, Typography } from "@mui/material";
import { useFormik } from "formik";
import useAgents from "hooks/useAgents";
import useFreighters from "hooks/useFreighters";
import useItads from "hooks/useItads";
import useLogisticsDetails from "hooks/useLogisticsDetails";
import LogisticsPhotos from "./LogisticsPhotos";
import PickupQuotes from "./PickupQuotes";
import PickupPerformance from "./PickupPerformance";
import { defaultLogisticsPartner, LogisticsPartner } from "types/logisticsPartner";
import { FormikProvider } from "formik";
import AgentDetailsForm from "./AgentDetailsForm";
import ItadDetailsForm from "./ItadDetailsForm";
import FreighterDetailsForm from "./FreighterDetailsForm";

// We do not anticipate exceeding 100 records for agents, itads, or freighters,
// but if we do, we can either increase this value or update hooks to paginate
// through all results.
const LOAD_ALL_RECORDS_PAGE_SIZE = 100;

export default function Form({ updateLogisticsDetails, logisticsDetails }: ReturnType<typeof useLogisticsDetails>) {
  const { mainAgents, subAgents } = useAgents(LOAD_ALL_RECORDS_PAGE_SIZE);
  const { itads } = useItads(LOAD_ALL_RECORDS_PAGE_SIZE);
  const { mainFreighters, subFreighters } = useFreighters(LOAD_ALL_RECORDS_PAGE_SIZE);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      agentId: logisticsDetails?.agentId || "",
      subAgentId: logisticsDetails?.subAgentId || "",
      agentContactId: logisticsDetails?.agentContactId || "",
      revivnCoordinatorId: logisticsDetails?.revivnCoordinatorId || "",
      orderNumber: logisticsDetails?.orderNumber || "",
      estimatedCostInCents:
        (logisticsDetails?.estimatedCostInCents && logisticsDetails?.estimatedCostInCents / 100.0) || null,
      costInCents: (logisticsDetails?.costInCents && logisticsDetails?.costInCents / 100.0) || null,
      agentConversationLink: logisticsDetails?.agentConversationLink || "",
      itadId: logisticsDetails?.itadId || "",
      itadLocationId: logisticsDetails?.itadLocationId || "",
      itadOrderId: logisticsDetails?.itadOrderId || "",
      itadPaymentInCents:
        (logisticsDetails?.itadPaymentInCents && logisticsDetails?.itadPaymentInCents / 100.0) || null,
      freighterId: logisticsDetails?.freighterId || "",
      subFreighterId: logisticsDetails?.subFreighterId || "",
      freighterConversationLink: logisticsDetails?.freighterConversationLink || "",
      palletsCount: logisticsDetails?.palletsCount || "",
      internalNotes: logisticsDetails?.internalNotes || "",
      logisticsClickupLink: logisticsDetails?.logisticsClickupLink || "",
      logisticsClientConversationLink: logisticsDetails?.logisticsClientConversationLink || "",
      itadConversationLink: logisticsDetails?.itadConversationLink || "",
      pickupDate: logisticsDetails?.pickupDate || "",
      dropoffEta: logisticsDetails?.dropoffEta || "",
      dropoffDate: logisticsDetails?.dropoffDate || "",
      deliveryType: logisticsDetails?.deliveryType || "",
      bol: logisticsDetails?.bol || "",
      estimatedInvoiceCostInCents:
        (logisticsDetails?.estimatedInvoiceCostInCents && logisticsDetails?.estimatedInvoiceCostInCents / 100.0) ||
        null,
      secondEstimatedInvoiceCostInCents:
        (logisticsDetails?.secondEstimatedInvoiceCostInCents &&
          logisticsDetails?.secondEstimatedInvoiceCostInCents / 100.0) ||
        "",
      invoiceCostInCents:
        (logisticsDetails?.invoiceCostInCents && logisticsDetails?.invoiceCostInCents / 100.0) || null,
      pickupQuotes: logisticsDetails?.pickupQuotes?.map(
        (quote: { id?: string; pickupAgentId?: string; amountInCents?: number }) => ({
          id: quote.id,
          pickupAgentId: quote.pickupAgentId || "",
          amountInCents: quote.amountInCents ? quote.amountInCents / 100.0 : null,
        }),
      ) || [{ pickupAgentId: "", amountInCents: null }],
      pickupFeedback: logisticsDetails?.pickupFeedback || [],
      feedbackNotes: logisticsDetails?.feedbackNotes || "",
      walkthroughPhotos: logisticsDetails?.walkthroughPhotos || [],
      onSiteLaptopPhotos: logisticsDetails?.onSiteLaptopPhotos || [],
      onSiteInboundObjectPhotos: logisticsDetails?.onSiteInboundObjectPhotos || [],
      sealedPalletPhotos: logisticsDetails?.sealedPalletPhotos || [],
      inboundPalletPhotos: logisticsDetails?.inboundPalletPhotos || [],
    },

    onSubmit: async (values) => {
      updateLogisticsDetails(values);
    },
  });

  const findAgent = (id: string, type: string) => {
    if (type === "subAgents") {
      return subAgents.find((a: LogisticsPartner) => a.id === id) || defaultLogisticsPartner;
    } else {
      return mainAgents.find((a: LogisticsPartner) => a.id === id) || defaultLogisticsPartner;
    }
  };

  const findItad = (id: string) => {
    return itads.find((a: LogisticsPartner) => a.id === id) || defaultLogisticsPartner;
  };

  const findFreighter = (id: string, type: string) => {
    if (type === "subFreighters") {
      return subFreighters.find((a: LogisticsPartner) => a.id === id) || defaultLogisticsPartner;
    } else {
      return mainFreighters.find((a: LogisticsPartner) => a.id === id) || defaultLogisticsPartner;
    }
  };

  return (
    <div>
      <FormikProvider value={formik}>
        <Box component="form" onSubmit={formik.handleSubmit} noValidate autoComplete="off">
          <Box px={4} py={2}>
            <Typography variant="h6" fontSize="18px" fontWeight="bold" sx={{ paddingBottom: "20px" }}>
              1. Agent Details:
            </Typography>
            <AgentDetailsForm mainAgents={mainAgents} subAgents={subAgents} findAgent={findAgent} />
          </Box>
          <Box p={4}>
            <Typography variant="h6" fontSize="18px" fontWeight="bold">
              2. ITAD Details:
            </Typography>
            <ItadDetailsForm itads={itads} findItad={findItad} />
          </Box>
          <Box p={4}>
            <Typography variant="h6" fontSize="18px" fontWeight="bold">
              3. Pickup Quotes:
            </Typography>
            <PickupQuotes mainAgents={mainAgents} findAgent={findAgent} />
          </Box>
          <Box p={4}>
            <Typography variant="h6" fontSize="18px" fontWeight="bold">
              4. Freighter Details:
            </Typography>
            <FreighterDetailsForm
              mainFreighters={mainFreighters}
              subFreighters={subFreighters}
              findFreighter={findFreighter}
            />
          </Box>
          <Box p={4}>
            <Typography variant="h6" fontSize="18px" fontWeight="bold">
              5. Photos:
            </Typography>
            <LogisticsPhotos />
          </Box>
          <Box p={4}>
            <Typography variant="h6" fontSize="18px" fontWeight="bold" sx={{ paddingBottom: "20px" }}>
              6. Pickup Performance Issues:
            </Typography>
            <PickupPerformance />
          </Box>
          <Box mt={4} sx={{ display: "flex", justifyContent: "start" }}>
            <Button color="primary" variant="contained" type="submit" sx={{ width: "100px" }}>
              SAVE
            </Button>
          </Box>
        </Box>
      </FormikProvider>
    </div>
  );
}
