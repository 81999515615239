import { Button, Stack } from "@mui/material";
import { useAlert } from "hooks/useAlert";
import { useState } from "react";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { useParams } from "react-router-dom";

export const CancelItemButton = () => {
  const [showCancelButton, setShowCancelButton] = useState(true);
  const { alertInfo } = useAlert();
  const { itemId } = useParams();

  const endpoint = `/shipstation_order_items/${itemId}/mark_as_cancelled`;
  if (!showCancelButton) return null;

  return (
    <Stack justifyItems="center" alignItems="center" gap={1}>
      Development only action
      <RevivnApiForm
        initialValues={{}}
        action={endpoint}
        method="POST"
        onSuccess={() => {
          alertInfo("Item cancelled in the background successfully");
          setShowCancelButton(false);
        }}
      >
        <Button type="submit" color="warning" variant="contained">
          Cancel Item
        </Button>
      </RevivnApiForm>
    </Stack>
  );
};
