import EditLocationIcon from "@mui/icons-material/EditLocation";
import { Button, Grid2, IconButton, Popover, PopoverProps, TextField, Tooltip } from "@mui/material";
import { useFormikContext } from "formik";
import { useState } from "react";
import { Inventory } from "types/inventory";

export const ChangeLocationButton = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const ChangeLocationPopover = (props: PopoverProps) => {
    const [newLocation, setNewLocation] = useState("");
    const { setFieldValue, values } = useFormikContext<{ inventory: Inventory }>();
    const onSubmit = () => {
      setFieldValue("inventory.warehouseLocation", newLocation);
      setNewLocation("");
      handleClose();
    };

    return (
      <Popover {...props}>
        <Grid2 container margin={2} alignItems="center" spacing={1}>
          <TextField
            autoFocus
            label="Set New Location"
            onChange={(e) => setNewLocation(e.target.value)}
            placeholder={values.inventory.warehouseLocation}
            value={newLocation}
            size="small"
            variant="outlined"
          />
          <Button
            size="small"
            variant="contained"
            onClick={onSubmit}
            disabled={newLocation == "" || newLocation == values.inventory.warehouseLocation}
          >
            Change
          </Button>
        </Grid2>
      </Popover>
    );
  };

  return (
    <>
      <Tooltip title="Change location" disableInteractive>
        <IconButton onClick={handleClick}>
          <EditLocationIcon color="primary" />
        </IconButton>
      </Tooltip>
      <ChangeLocationPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      />
    </>
  );
};
