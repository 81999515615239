import { v4 as uuid } from "uuid";
import { defaultRecordHistoryEvent, RecordHistoryEvent } from "./recordHistoryEvent";
import { defaultPickup, Pickup } from "./pickup";
import { Tag } from "./tag";

export type InventoryImage = {
  id: number;
  inventoryId?: number;
  inventory_id?: number;
  url: string;
};

export type InventoryWarehouse = {
  id: number;
  name: string;
};

export type Attachment = {
  signed_id?: string;
  id?: number;
  url?: string;
  filename?: string;
};

export enum KeyboardLanguage {
  ARB = "ARB - Arabic",
  BLG = "BLG - Bulgarian",
  CAN = "CAN - Canadian French",
  CHP = "CHP - Chinese - Pinyin",
  CHZ = "CHZ - Chinese - Zhuyin",
  CRO = "CRO - Croatian / Slovenian",
  CZE = "CZE - Czech",
  DAN = "DAN - Danish",
  DEU = "DEU - Dutch",
  ESP = "ESP - Spanish (Spain)",
  FIN = "FIN - Swedish / Finnish",
  FRA = "FRA - French / Belgian",
  GBR = "GBR - British/Irish English",
  GER = "GER - German / Austrian",
  GRK = "GRK - Greek",
  HBW = "HBW - Hebrew",
  HNG = "HNG - Hungarian",
  ICE = "ICE - Icelandic",
  INT = "INT - International English",
  ITA = "ITA - Italian",
  JAP = "JAP - Japan",
  KOR = "KOR - Korean",
  LAT = "LAT - Spanish (Latin America)",
  NOR = "NOR - Norwegian",
  PTG = "PTG - Portugese",
  ROM = "ROM - Romanian",
  RUS = "RUS - Russian",
  SVK = "SVK - Slovak",
  SWI = "SWI - Swiss",
  TAI = "TAI - Thai",
  TKF = "TKF - Turkish - F",
  TKQ = "TKQ - Turkish - Q",
  USA = "USA - US English",
}

export const keyboardLanguageDropdownOptions = [
  { label: "🇺🇸 US English", value: "USA" },
  { label: "🇦🇪 Arabic", value: "ARB" },
  { label: "🇬🇧 British / Irish English", value: "GBR" },
  { label: "🇧🇬 Bulgarian", value: "BLG" },
  { label: "🇨🇦 Canadian French", value: "CAN" },
  { label: "🇨🇳 Chinese - Pinyin", value: "CHP" },
  { label: "🇨🇳 Chinese - Zhuyin", value: "CHZ" },
  { label: "🇭🇷 Coatian / Slovenian", value: "CRO" },
  { label: "🇨🇿 Czech", value: "CZE" },
  { label: "🇩🇰 Danish", value: "DAN" },
  { label: "🇳🇱 Dutch", value: "DEU" },
  { label: "🇫🇷 French / Belgian", value: "FRA" },
  { label: "🇩🇪 German / Austrian", value: "GER" },
  { label: "🇬🇷 Greek", value: "GRK" },
  { label: "🇮🇱 Hewbrew", value: "HBW" },
  { label: "🇭🇺 Hungarian", value: "HNG" },
  { label: "🇮🇸 Icelandic", value: "ICE" },
  { label: "🌐 International English", value: "INT" },
  { label: "🇮🇹 Italian", value: "ITA" },
  { label: "🇯🇵 Japan", value: "JAP" },
  { label: "🇰🇷 Korean", value: "KOR" },
  { label: "🇳🇴 Norwegian", value: "NOR" },
  { label: "🇪🇸 Spanish (Spain)", value: "ESP" },
  { label: "🌎 Spanish (Latin America)", value: "LAT" },
  { label: "🇵🇹 Portugese", value: "PTG" },
  { label: "🇷🇴 Romanian", value: "ROM" },
  { label: "🇷🇺 Russian", value: "RUS" },
  { label: "🇸🇰 Slovak", value: "SVK" },
  { label: "🇫🇮 Swedish / Finnish", value: "FIN" },
  { label: "🇨🇭 Swiss", value: "SWI" },
  { label: "🇹🇭 Thai", value: "TAI" },
  { label: "🇹🇷 Turkish - F", value: "TKF" },
  { label: "🇹🇷 Turkish - Q", value: "TKQ" },
];

export type Inventory = {
  id: number;
  uuid: string;
  manufacturer: string;
  serialNumber: string;
  warehouseLocation: string;
  condition: string;
  color: string;
  sku: string;
  ram: string;
  cpu: string;
  hardDrive: string;
  batteryCount?: number;
  batteryHealth?: number;
  listed?: string;
  model?: string;
  modelOrder?: string;
  securityLevel?: string;
  warehouseId: number;
  description?: string;
  keyboardLanguage?: KeyboardLanguage | null;
  comments?: string;
  keyboardIsWorking?: boolean | null;
  trackpadIsWorking?: boolean | null;
  batteryIsWorking?: boolean | null;
  lcdDisplayIsWorking?: boolean | null;
  keyboardFunctionality?: string | null;
  trackpadFunctionality?: string | null;
  batteryFunctionality?: string | null;
  screenFunctionality?: string | null;
  cameraFunctionality?: string | null;
  microphoneFunctionality?: string | null;
  speakerFunctionality?: string | null;
  portsFunctionality?: string | null;
  powerFunctionality?: string | null;
  logicFunctionality?: string | null;
  inventoryEvents: Array<RecordHistoryEvent>;
  pickup: Pickup;
  tags?: Tag[];
  year?: number;
  printedAt?: string;
  deviceType?: string;
  state: string;
  iqInventoryId?: number;
  iqPoIdLine?: string;
  lineId: number;
  assetTag?: string;
  inventoryImages: InventoryImage[];
  quantity: number;
  gpu: string;
  label?: string;
  enrolledStatus: string;
  inventoryGroup?: string;
  manifestId?: string;
  warehouse?: InventoryWarehouse;
  hardDriveSizeInGb?: number | string;
  enrolledOrganization?: string;
  drive1ErasureStatus?: string;
  screenSize?: string;
  resalePhotos: Attachment[];
  resalePhotosOrder: number[];
  itemState?: string;
  itemId?: string;
  batchId?: string;
  merchantProductNumber?: string | null;
};

export const defaultInventory = {
  id: 0,
  uuid: "",
  manufacturer: "",
  serialNumber: "",
  warehouseLocation: "",
  condition: "",
  color: "",
  sku: "",
  ram: "",
  cpu: "",
  hardDrive: "",
  inventoryEvents: [defaultRecordHistoryEvent],
  pickup: defaultPickup,
  tags: [],
  inventoryImages: [],
  state: "",
  iqInventoryId: 0,
  iqPoIdLine: "",
  lineId: 0,
  warehouseId: 0,
  assetTag: "",
  quantity: 0,
  gpu: "",
  enrolledStatus: "",
  enrolledOrganization: "",
  hardDriveSizeInGb: "",
  resalePhotos: [],
  resalePhotosOrder: [],
};

export const defaultTechnicianInventory: Inventory = {
  id: 0,
  uuid: "",
  manufacturer: "",
  serialNumber: "",
  warehouseLocation: "",
  condition: "",
  color: "",
  sku: "",
  ram: "",
  cpu: "",
  hardDrive: "",
  model: "",
  modelOrder: "",
  securityLevel: "",
  warehouseId: 0,
  description: "",
  keyboardLanguage: null,
  merchantProductNumber: null,
  comments: "",
  batteryCount: 0,
  batteryHealth: 0,
  tags: [],
  trackpadIsWorking: null,
  keyboardIsWorking: null,
  batteryIsWorking: null,
  lcdDisplayIsWorking: null,
  trackpadFunctionality: "",
  keyboardFunctionality: "",
  batteryFunctionality: "",
  screenFunctionality: "",
  cameraFunctionality: "",
  microphoneFunctionality: "",
  speakerFunctionality: "",
  portsFunctionality: "",
  powerFunctionality: "",
  logicFunctionality: "",
  inventoryEvents: [defaultRecordHistoryEvent],
  pickup: defaultPickup,
  inventoryImages: [],
  state: "",
  iqInventoryId: 0,
  iqPoIdLine: "",
  lineId: 0,
  quantity: 0,
  gpu: "",
  enrolledStatus: "",
  enrolledOrganization: "",
  hardDriveSizeInGb: "",
  resalePhotos: [],
  resalePhotosOrder: [],
};

export type InventoryAddProps = {
  id: string;
  inventory_template_uuid: string;
  quantity: string;
  print_labels: boolean;
};

export const defaultInventoryAdd = () => {
  return {
    id: uuid(),
    inventory_template_uuid: "",
    quantity: "1",
    print_labels: true,
  };
};

export type InventoryBulkEditProps = {
  id: number;
  warehouseLocation?: string;
  ram?: string;
  serialNumber?: string;
  sku?: string;
};

export interface IndexedInventory {
  id: number;
  uuid: string;
  label: string;
  pickupId: number;
  lineId: number;
  warehouseLocation: string;
  serialNumber: string;
  manufacturer: string;
  deviceType: string;
  sku: string;
  color: string;
  quantity: number;
  model: string;
  modelOrder: string;
  cpu: string;
  ram: string;
  ramSizeInGb: number;
  hardDrive: string;
  hardDriveSizeInGb: number;
  condition: string;
  screenSize: string;
  screenResolution: string;
  assetTag: string;
  gpu: string;
  year: number;
  state: string;
  enrolledStatus: string;
  securityLevel: string;
  netPriceInCents: number;
  batteryCount: number;
  comments: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  unenrolledAt: string;
  unenrolledById: string;
  createdById: string;
  enrolledOrganization: string;
  warehouseName: string;
  partnerId: string;
  tagNames: string;
}
