import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Radio, RadioGroup, RadioProps, Stack, SvgIcon, TextField, Typography } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { useMemo } from "react";
import { Inventory } from "types/inventory";

const FunctionalityRadio = (props: RadioProps) => (
  <Field
    type="radio"
    as={Radio}
    {...props}
    icon={<Box component={SvgIcon} />}
    checkedIcon={<CheckCircleIcon />}
    sx={{ borderRadius: 1, flexGrow: 1, transition: "ease-in-out 0.1s", ":hover": { outline: "1px solid" } }}
  />
);

export const FunctionalityRow = ({ label, name }: { label: string; name: keyof Inventory }) => {
  const { values } = useFormikContext<{ inventory: Inventory }>();

  const replaced = useMemo(() => {
    return String(values.inventory[name]).includes("Replaced");
  }, [name, values.inventory]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ ":hover": { backgroundColor: (theme) => theme.palette.grey[200] }, transition: "ease-in-out 0.1s" }}
    >
      <Typography width="8em" variant="caption">
        {label}
      </Typography>
      {replaced ? (
        <Field
          as={TextField}
          fullWidth
          disabled
          name={`inventory.${name}`}
          size="small"
          slotProps={{ input: { disableUnderline: true, sx: { fontWeight: "bold" } } }}
          variant="standard"
        />
      ) : (
        <RadioGroup row sx={{ flexGrow: 1, justifyContent: "space-between" }}>
          <FunctionalityRadio color="success" name={`inventory.${name}`} value="Working" />
          <FunctionalityRadio color="warning" name={`inventory.${name}`} value="As-is" />
          <FunctionalityRadio color="error" name={`inventory.${name}`} value="Broken" />
          <FunctionalityRadio color="info" name={`inventory.${name}`} value="Repaired" />
        </RadioGroup>
      )}
    </Stack>
  );
};
