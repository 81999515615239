import Breadcrumbs from "components/common/Breadcrumbs";
import { Box, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { GridActionsColDef } from "@mui/x-data-grid-pro";
import { RevivnApiDataGrid } from "components/DataGrid/RevivnApiDataGrid";
import { BatchReassignUserRowAction } from "components/Fulfillment/BatchReassignUserRowAction";
import { FulfillmentBatch } from "hooks/useFulfillmentBatch";
import { useStateColOptions } from "components/DataGrid/columnDefs/useStateColOptions";
import { dateColDef } from "components/DataGrid/columnDefs/dateColDef";

const reassignUser: GridActionsColDef<FulfillmentBatch> = {
  field: "actions",
  sortable: false,
  pinnable: true,
  type: "actions",
  align: "center",
  getActions: (params) => [<BatchReassignUserRowAction row={params.row} />],
};

export const BatchesPage = () => {
  const stateColOptions = useStateColOptions("fulfillment_batch");

  const columns = useMemo(() => {
    return [
      { field: "id", headerName: "ID", width: 60 },
      { field: "userEmail", headerName: "Assigned To", width: 300 },
      { field: "state", headerName: "State", width: 120, ...stateColOptions },
      { field: "pickingAt", headerName: "Started Picking", filterable: true, width: 140, ...dateColDef },
      {
        field: "endedPickingAt",
        headerName: "Ended Picking",
        filterable: false,
        sortable: false,
        width: 140,
        ...dateColDef,
      },
      { field: "pickingDuration", headerName: "Picking Duration", filterable: false, width: 150 },
      { field: "packingAt", headerName: "Started Packing", filterable: false, width: 140, ...dateColDef },
      { field: "completeAt", headerName: "Ended Packing", filterable: false, width: 140, ...dateColDef },
      { field: "packingDuration", headerName: "Packing Duration", filterable: false, width: 100 },
      { field: "itemsCount", headerName: "Count of items", filterable: false },
      { headerName: "Actions", width: 150, ...reassignUser },
    ];
  }, [stateColOptions]);

  return (
    <Stack>
      <Breadcrumbs data={[{ label: "Batches" }]}></Breadcrumbs>
      <Box my={4}>
        <Typography variant="h4">Batches</Typography>
      </Box>
      <RevivnApiDataGrid url={`fulfillment_batches`} columns={columns} />
    </Stack>
  );
};
