import { ElasticDataGrid } from "components/DataGrid/RevivnElasticDataGrid";
import { ElasticPickup } from "types/pickup";
import { Link } from "react-router-dom";
import { defaultInventoryFilters } from "constants/default_inventory_filters";
import PickupStateChip from "components/PickupState";
import { Paper } from "@mui/material";
import { dateColDef } from "components/DataGrid/columnDefs/dateColDef";
import { GridEnrichedColDef, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { PageTitle } from "pages/Layouts/components/PageTitleContainer";

const columns: GridEnrichedColDef[] = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    width: 125,
    renderCell: (params) => {
      return <Link to={`/pickups/${params.row.uuid}${defaultInventoryFilters}`}>{params.row.id}</Link>;
    },
  },
  {
    field: "organizationName",
    type: "string",
    headerName: "Company",
    valueGetter: (params: GridValueGetterParams<ElasticPickup>) => {
      if (params.row.repairPickup) {
        return "Revivn - Returns & Warranty";
      }
      if (params.row.returnPickup) {
        return "Revivn - Redeploy";
      }
      return params.row.organizationName;
    },
    width: 200,
  },
  { field: "scheduledAt", headerName: "Date", width: 150, ...dateColDef },
  { field: "itadName", headerName: "ITAD", type: "string", width: 150 },
  {
    field: "state",
    headerName: "State",
    width: 200,
    renderCell: (params) => {
      return <PickupStateChip pickupState={params.row.state} />;
    },
  },
  { field: "appleLaptopCount", headerName: "Mac Laptops", width: 150 },
  { field: "pcLaptopCount", headerName: "PC Laptops", width: 150 },
  {
    field: "address",
    headerName: "Address",
    width: 500,
    sortable: false,
    renderCell: (params) => {
      return (
        <>
          {`${params.row.locationAddress || ""} ${params.row.locationAddress2 || ""} ${
            params.row.locationCity || ""
          } ${params.row.locationZipcode || ""} ${params.row.locationState || ""}`}
        </>
      );
    },
  },
  {
    field: "logisticsClickupLink",
    headerName: "Clickup",
    sortable: false,
    width: 150,
    renderCell: (params) => {
      return (
        params.row.logisticsClickupLink && (
          <a target="_blank" href={params.row.logisticsClickupLink}>
            {params.row.logisticsClickupLink.replace("https://app.clickup.com/t/", "")}
          </a>
        )
      );
    },
  },
];
export const NewPickupsPage = () => {
  return (
    <>
      <PageTitle>Pickups</PageTitle>
      <Paper sx={{ flexGrow: 1 }}>
        <ElasticDataGrid
          autoHeight={false}
          columns={columns}
          syncStateWithSearchParams
          url={"/pickups/elastic_index"}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </Paper>
    </>
  );
};
