import { Box, darken, Paper, Typography } from "@mui/material";
import { RevivnDatabaseDataGrid } from "components/DataGrid/RevivnDatabaseDataGrid";
import { columns } from "components/Orders/columns";
import useCurrentUser from "hooks/useCurrentUser";
import { DatabaseDataGridSavedFilterTabs } from "components/DataGrid/components/DatabaseDataGridSavedFilterTabs";
import { DateTime } from "luxon";

const getDateString = (date: Date) => {
  return date.toISOString().split("T")[0];
};

export const getTomorrowAt10AMISO = (timezone: string | null) => {
  return DateTime.now()
    .setZone(timezone || "America/Los_Angeles")
    .plus({ days: 1 })
    .set({ hour: 10, minute: 0, second: 0, millisecond: 0 })
    .toISO()
    ?.split(".")?.[0];
};

export const OrdersPage = () => {
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);
  const { user } = useCurrentUser();
  const url = `shipstation_order_items?filter%5B%5D=ordered_at%2Cafter%2C${getDateString(tenDaysAgo)}`;
  if (!user) return null;

  const tomorrowAt10AM = getTomorrowAt10AMISO(user.warehouseTimezone);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: 1,
        gridTemplateRows: "auto auto 1fr",
        flexGrow: 1,
        minHeight: "600px",
      }}
    >
      <Box mb={1}>
        <Typography variant="h4">Orders</Typography>
      </Box>
      <DatabaseDataGridSavedFilterTabs
        url={url}
        savedFilters={[
          {
            label: "Due Today",
            filters: `ship_by,on_or_before,${tomorrowAt10AM} AND warehouse,is,${user.warehouseName} AND state,not,packed`,
          },
          {
            label: "Needs Inventory",
            filters: "state,is,created AND inventory_item,is_empty",
          },
          {
            label: "Needs Valid Address",
            filters: "address_verified_at,is_empty",
          },
          {
            label: "Due Later",
            filters: `ship_by,after,${tomorrowAt10AM} AND warehouse,is,${user.warehouseName} AND state,not,packed`,
          },
          {
            label: "All Orders",
            filters: "__any__",
          },
        ]}
        sx={{ mb: 1 }}
      />
      <Paper>
        <RevivnDatabaseDataGrid
          url={url}
          syncStateWithSearchParams
          remoteCsvExport={true}
          persistSettingsKey="ordersPage"
          columns={columns}
          getRowClassName={(params) => (params.row.state != "packed" && params.row.priority > 0 ? "high-priority" : "")}
          sx={{
            "& .high-priority": {
              backgroundColor: "#ffcccc",
              "&:hover": {
                backgroundColor: darken("#ffcccc", 0.1),
              },
            },
          }}
        />
      </Paper>
    </Box>
  );
};
