import { PicklistCard } from "components/Picklist/common/PicklistCard";
import { InfoOutlined, Person } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { FulfillmentBatch } from "hooks/useFulfillmentBatch";
import { useNavigate } from "react-router-dom";
export function StartBatchStep({ batch }: { batch: FulfillmentBatch }) {
  const navigate = useNavigate();
  const estimatedTime = batch.itemsCount * 2;
  return (
    <>
      <PicklistCard title="User" Icon={Person}>
        <Stack alignItems="center">
          <Typography>{batch.userEmail}</Typography>
          <Typography>Assigned: {batch.orderCount} Orders</Typography>
        </Stack>
      </PicklistCard>
      <PicklistCard title="Overview" Icon={InfoOutlined}>
        <Stack alignItems="center">
          <Typography>{batch.itemsCount} Macbooks</Typography>
          <Typography>Estimated Time: {estimatedTime} min</Typography>
        </Stack>
      </PicklistCard>
      <Stack justifyItems="center" alignItems="center" gap={1} mt={8}>
        <RevivnApiForm
          initialValues={{}}
          action={`fulfillment_batches/${batch.id}/start_picking`}
          method="POST"
          onSuccess={() => navigate(`/picklist/batches/${batch.id}`, { replace: true })}
        >
          <Button variant="contained" type="submit">
            Start
          </Button>
        </RevivnApiForm>
      </Stack>
    </>
  );
}
