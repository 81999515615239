import { Box, FormControl, Autocomplete, TextField, OutlinedInput, IconButton, InputAdornment } from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { LogisticsPartner } from "types/logisticsPartner";
import { useFormikContext } from "formik";
import { LogisticsFormValues } from "types/types";
import { InputLabel } from "components/common/Input";

type PickupQuote = {
  id?: string;
  pickupAgentId: string;
  amountInCents: number | null;
};

type PickupQuotesProps = {
  mainAgents: LogisticsPartner[];
  findAgent: (id: string, type: string) => LogisticsPartner;
};

export default function PickupQuotes({ mainAgents, findAgent }: PickupQuotesProps) {
  const formik = useFormikContext<LogisticsFormValues>();

  return (
    <Box>
      {formik.values.pickupQuotes.map((quote: PickupQuote, index: number) => (
        <Box key={index} sx={{ display: "grid", gridTemplateColumns: "1fr 1fr auto", gap: 3, mb: 2 }}>
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink htmlFor="Logistics Agent">
              Logistics Agent:
            </InputLabel>
            <Autocomplete
              id={`quotes.${index}.pickupAgentId`}
              size="small"
              getOptionLabel={(option: LogisticsPartner) => option.name}
              isOptionEqualToValue={(option: LogisticsPartner, value: LogisticsPartner) => option.id === value.id}
              value={findAgent(quote.pickupAgentId, "mainAgents")}
              options={mainAgents}
              autoComplete
              includeInputInList
              filterSelectedOptions
              onChange={(_event, newValue: LogisticsPartner | null) => {
                const updatedQuotes = [...formik.values.pickupQuotes];
                updatedQuotes[index] = {
                  ...updatedQuotes[index],
                  pickupAgentId: newValue?.id || "",
                };
                formik.setFieldValue("pickupQuotes", updatedQuotes);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={!quote.pickupAgentId && index !== formik.values.pickupQuotes.length - 1}
                  helperText={
                    !quote.pickupAgentId && index !== formik.values.pickupQuotes.length - 1 ? "Agent is required" : ""
                  }
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              )}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor={`quotes.${index}.amountInCents`}>Quote Amount</InputLabel>
            <OutlinedInput
              id={`quotes.${index}.amountInCents`}
              type="number"
              size="small"
              inputProps={{
                step: 0.01,
                onWheel: (e: React.WheelEvent<HTMLInputElement>) => e.target instanceof HTMLElement && e.target.blur(),
              }}
              placeholder="0.00"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              {...formik.getFieldProps(`pickupQuotes.${index}.amountInCents`)}
            />
          </FormControl>
          {(index !== formik.values.pickupQuotes.length - 1 || formik.values.pickupQuotes.length > 1) && (
            <IconButton
              onClick={() => {
                const updatedQuotes = formik.values.pickupQuotes.filter((_: PickupQuote, i: number) => i !== index);
                formik.setFieldValue("pickupQuotes", updatedQuotes);
              }}
              sx={{ mt: 2 }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ))}
      <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
        <IconButton
          onClick={() => {
            formik.setFieldValue("pickupQuotes", [
              ...formik.values.pickupQuotes,
              { pickupAgentId: "", amountInCents: null },
            ]);
          }}
        >
          <AddIcon color="primary" />
        </IconButton>
      </Box>
    </Box>
  );
}
