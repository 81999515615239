import { api } from "services/api.service";
import React, { useCallback, useState } from "react";
import { PicklistCard } from "components/Picklist/common/PicklistCard";
import { Check } from "@mui/icons-material";
import { CheckList, CheckListItem } from "components/Picklist/common/CheckList";
import { Alert, Stack } from "@mui/material";
import { ScanButton } from "components/Picklist/common/ScanButton";
import { OrderItem } from "hooks/useFulfillmentBatch";
import { useRevalidator } from "react-router-dom";
import { AxiosError } from "axios";

export enum DestinationType {
  PickCart,
  RecoveryCart,
}

const scanAttributes = {
  [DestinationType.RecoveryCart]: {
    label: "Set New Location",
    description: "Please move unit to the recovery cart.",
    endpoint: "move_to_recovery_cart",
  },
  [DestinationType.PickCart]: {
    label: "Scan Cart",
    description: null,
    endpoint: "move_to_cart",
  },
};

export function ScanAndMoveItemStep({ item, destination }: { item: OrderItem; destination: DestinationType }) {
  const attrs = scanAttributes[destination];
  const [scannedLocation, setScannedLocation] = useState<string | null | undefined>(null);

  const [error, setError] = useState<string | undefined>();
  const label = scannedLocation ? `Moving Inventory...` : attrs.label;
  const { revalidate } = useRevalidator();

  const moveToLocation = useCallback(
    (warehouseLocation: string) => {
      setScannedLocation(warehouseLocation);
      api
        .post(`/fulfillment_batches/${item.batchId}/items/${item.itemId}/${attrs.endpoint}`, {
          warehouseLocation,
        })
        .then(() => {
          revalidate();
        })
        .catch((err) => {
          const response = (err as AxiosError)?.response;
          const errors = response?.data?.errors;
          if (errors) {
            setError(errors[0]);
          } else {
            setError(`Failed to move inventory. ${response?.status} error.`);
          }
          revalidate();
          setScannedLocation(undefined);
        });
    },
    [item.batchId, item.itemId, attrs.endpoint, revalidate],
  );

  const validateLocation = useCallback(
    (location: string) => {
      if (destination != DestinationType.PickCart) return true;

      if (location.startsWith("PICK-")) return true;
      setError("Location is not a PICK- location. Please scan a valid location.");
      return false;
    },
    [destination],
  );

  return (
    <div style={{ width: "100%" }}>
      <PicklistCard title="Actions" Icon={Check}>
        <CheckList>
          <CheckListItem label={"Scanned Inventory"} />
        </CheckList>
      </PicklistCard>

      <Stack justifyItems="center" alignItems="center" gap={1} mt={8}>
        {attrs.description}
        {error && <Alert severity="error">{error}</Alert>}
        <ScanButton
          onScan={(scanValue) => {
            if (scanValue && validateLocation(scanValue)) {
              moveToLocation(scanValue);
            }
          }}
        >
          {label}
        </ScanButton>
      </Stack>
    </div>
  );
}
