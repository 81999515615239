import { Box, FormControl, Autocomplete, TextField, InputAdornment, OutlinedInput } from "@mui/material";
import { InputBase, InputLabel } from "components/common/Input";
import { useFormikContext } from "formik";
import { defaultLogisticsPartner, LogisticsPartner } from "types/logisticsPartner";
import { LogisticsFormValues } from "../../../../types/types";

interface ItadDetailsProps {
  itads: LogisticsPartner[];
  findItad: (id: string) => LogisticsPartner;
}

export default function ItadDetailsForm({ itads, findItad }: ItadDetailsProps) {
  const formik = useFormikContext<LogisticsFormValues>();

  return (
    <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 3 }}>
      <FormControl variant="standard" fullWidth>
        <InputLabel shrink htmlFor="itad">
          ITAD:
        </InputLabel>
        <Autocomplete
          id="itadId"
          size="small"
          getOptionLabel={(option: LogisticsPartner) => option.name}
          isOptionEqualToValue={(option: LogisticsPartner, value: LogisticsPartner) => option.id === value.id}
          value={findItad(formik.values.itadId)}
          options={
            findItad(formik.values.itadId) !== defaultLogisticsPartner ? itads : [defaultLogisticsPartner, ...itads]
          }
          autoComplete
          includeInputInList
          filterSelectedOptions
          onChange={(event, newValue: LogisticsPartner | null) => {
            formik.setFieldValue("itadId", newValue?.id || "");
          }}
          renderInput={(params) => <TextField {...params} name="itadId" label="Search Itads" fullWidth />}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            );
          }}
        />
      </FormControl>
      <FormControl variant="standard" fullWidth>
        <InputLabel shrink htmlFor="itadOrderId">
          ITAD Order ID:
        </InputLabel>
        <InputBase id="itadOrderId" {...formik.getFieldProps("itadOrderId")} placeholder="Order ID" />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel htmlFor="itadPaymentInCents">Itad Payment</InputLabel>
        <OutlinedInput
          id="itadPaymentInCents"
          size="small"
          type="number"
          inputProps={{
            step: 0.01,
          }}
          placeholder="0.00"
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          {...formik.getFieldProps("itadPaymentInCents")}
        />
      </FormControl>
    </Box>
  );
}
