import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import InfoIcon from "@mui/icons-material/Info";
import InputIcon from "@mui/icons-material/Input";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import MemoryIcon from "@mui/icons-material/Memory";
import MonitorIcon from "@mui/icons-material/Monitor";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  ButtonProps,
  Divider,
  Grid2,
  InputAdornment,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { AxiosError } from "axios";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { TabPanel } from "components/Tabs/TabPanel";
import { ChangeLocationButton } from "components/TechnicianInventory/v2/InventoryChangeLocationButton";
import { DeviceWipeStatus } from "components/TechnicianInventory/v2/InventoryDeviceWipeStatus";
import { InventoryEventsHistoryButton } from "components/TechnicianInventory/v2/InventoryEventHistoryButton";
import InventoryPartsBulkInbound from "components/TechnicianInventory/InventoryPartsBulkInbound";
import InventoryPartsPanel from "components/TechnicianInventory/InventoryPartsPanel";
import { PrintLabelButton } from "components/TechnicianInventory/v2/InventoryPrintLabelButton";
import { ViewPickupButton } from "components/TechnicianInventory/v2/InventoryViewPickupButton";
import {
  TechnicianFormFunctionalityAttributes,
  TechnicianFormHardwareAttributes,
} from "components/TechnicianInventory/v2/TechForm";
import TechnicianInventoryImages from "components/TechnicianInventory/TechnicianInventoryImages";
import { TechnicianFormSchema } from "components/TechnicianInventory/ValidationSchema";
import { Field, Form, Formik, FormikProps, useFormikContext } from "formik";
import { useAlert } from "hooks/useAlert";
import { useConfirmation } from "hooks/useConfirmation";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import useInventory from "hooks/useInventory";
import React, { useCallback, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Inventory } from "types/inventory";
import * as Yup from "yup";

export default function NewTechnicianInventoryPage() {
  const navigate = useNavigate();
  const { inventory } = useInventory();
  const { confirm } = useConfirmation();
  const { alertSuccess, alertError } = useAlert();
  const { enabled: inventoryPartsEnabled } = useFeatureFlag("inventory_parts");
  const [inboundPartsMode, setInboundPartsMode] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const techForm = useRef<FormikProps<{ inventory: Inventory }>>(null);

  const handleTabChange = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      const typeIndex = ["inventory_details", "top_case", "board", "lcd", "battery"];

      if (!inventory) return;
      if (newValue === 0) navigate(`/technician/${inventory.id}`);
      else {
        const location = `/technician/${inventory.id}/inventory-parts/${typeIndex[newValue]}`;
        navigate(location, { replace: true });
      }
      setTabIndex(newValue);
    },
    [inventory, navigate],
  );

  const InventorySearchTextField = (props: TextFieldProps) => {
    const params = useParams();
    const initialValues = { searchValue: params.inventoryId ?? "" };
    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => e.target.select();
    const handleSubmit = (values: { searchValue: string }) => {
      if (!values.searchValue) return;
      if (techForm.current) {
        if (techForm.current.dirty) {
          const prompt = window.confirm("You have unsaved changes. Are you sure you want to change inventories?");
          if (!prompt) return;
        }
        techForm.current.resetForm();
      }

      navigate(`/technician/${values.searchValue}`);
    };

    return (
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <Field
            {...props}
            as={TextField}
            autoFocus
            fullWidth
            name="searchValue"
            placeholder={params.inventoryId ?? "Scan or type ID"}
            onFocus={handleFocus}
            onBlur={handleSubmit}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" fontSize="small" />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Form>
      </Formik>
    );
  };

  const InboundInventoryPartsButton = (props: ButtonProps) => {
    const handleClick = () => setInboundPartsMode((prevState) => !prevState);
    const label = inboundPartsMode ? "Back" : "Inbound Parts";

    return (
      <Button {...props} onClick={handleClick}>
        {label}
      </Button>
    );
  };

  const InventoryDetails = () => {
    const { values } = useFormikContext<{ inventory: Inventory }>();

    const AttributeLabel = (props: TypographyProps & { label: string; value: string | number | undefined }) => (
      <Typography {...props}>
        {props.label}:{" "}
        <Typography component="span" color="primary">
          {props.value}
        </Typography>
      </Typography>
    );

    return (
      <Grid2 container size={{ xs: 12, md: "grow" }} columnSpacing={2}>
        <Grid2 size="auto">
          <AttributeLabel label="ID" value={values.inventory.id} />
          <AttributeLabel label="Label" value={values.inventory.label} />
        </Grid2>

        <Grid2 size="auto">
          <AttributeLabel label="Location" value={values.inventory.warehouseLocation} />
          <AttributeLabel label="Warehouse" value={values.inventory.warehouse?.name} />
        </Grid2>

        <Grid2 size="auto">
          <AttributeLabel label="State" value={values.inventory.state} />
          <AttributeLabel label="Group" value={values.inventory.inventoryGroup} />
        </Grid2>
      </Grid2>
    );
  };

  const TechnicianFormSubmitButton = () => {
    const { dirty, isValid } = useFormikContext<Inventory>();

    return (
      <Button
        fullWidth
        type="submit"
        color="primary"
        size="large"
        variant="contained"
        sx={{ my: 2 }}
        disabled={!dirty || !isValid}
      >
        Save Inventory
      </Button>
    );
  };

  const handleSubmitSuccess = () => alertSuccess("Inventory saved successfully.");

  const handleSubmitError = (error: AxiosError) => {
    if (error.response?.status === 422) {
      confirm({
        handler: () => {
          techForm.current?.setFieldValue("inventory." + error.response?.data.errors.skip_param, true, false);
          techForm.current?.handleSubmit();
        },
        message: error.response?.data.errors.message,
        context: error.response?.data.errors.skip_param,
      });
    } else alertError("Failed to save inventory.");
    throw error;
  };

  return (
    <>
      <Grid2 container marginBottom={1} spacing={1} justifyContent="flex-end" alignItems="center">
        <Grid2 size="grow">
          <InventorySearchTextField
            disabled={inboundPartsMode}
            variant="outlined"
            label="Inventory ID"
            placeholder="Enter ID or label"
          />
        </Grid2>
        <Grid2 hidden={!!inventory}>
          <InboundInventoryPartsButton size="large" variant="outlined" startIcon={<InputIcon />} />
        </Grid2>
      </Grid2>

      {inboundPartsMode && <InventoryPartsBulkInbound />}

      {inventory && (
        <RevivnApiForm
          initialValues={{ inventory: inventory }}
          action={`/inventories/${inventory?.id}`}
          method="PUT"
          validationSchema={Yup.object({ inventory: TechnicianFormSchema })}
          FormikProps={{
            innerRef: techForm,
            enableReinitialize: true,
            validateOnChange: false,
            validateOnBlur: false,
          }}
          onSuccess={handleSubmitSuccess}
          onError={handleSubmitError}
        >
          <Grid2 container marginBottom={1} rowSpacing={1}>
            <InventoryDetails />
            <Grid2 sx={{ alignSelf: "center" }}>
              <DeviceWipeStatus />
            </Grid2>
            <Grid2>
              <ViewPickupButton />
            </Grid2>
            <Grid2>
              <ChangeLocationButton />
            </Grid2>
            <Grid2>
              <PrintLabelButton inventory={inventory} />
            </Grid2>
            <Grid2>
              <InventoryEventsHistoryButton inventory={inventory} />
            </Grid2>
          </Grid2>
          <Grid2 container columns={2} spacing={{ sm: 1, md: 2 }}>
            <Grid2 size={{ sm: 2, md: 1 }} component={Paper} variant="outlined" padding={2}>
              <Grid2 sx={{ paddingBottom: 1 }}>
                <Typography variant="h6">Tech Form</Typography>
              </Grid2>
              <Grid2 container columns={2} spacing={2}>
                <TechnicianFormFunctionalityAttributes />
              </Grid2>
            </Grid2>

            <Grid2 size={{ sm: 2, md: 1 }} sx={{ padding: 2 }} component={Paper} variant="outlined">
              {inventoryPartsEnabled && (
                <Tabs
                  scrollButtons
                  allowScrollButtonsMobile
                  onChange={handleTabChange}
                  variant="scrollable"
                  value={tabIndex}
                >
                  <Tab icon={<InfoIcon />} label="Details" value={0} />
                  <Tab icon={<KeyboardIcon />} label="Case" value={1} />
                  <Tab icon={<MemoryIcon />} label="Board" value={2} />
                  <Tab icon={<MonitorIcon />} label="LCD" value={3} />
                  <Tab icon={<BatteryChargingFullIcon />} label="Battery" value={4} />
                </Tabs>
              )}
              <Divider sx={{ mb: 1 }} />
              <TabPanel index={0} value={tabIndex}>
                <Stack spacing={1}>
                  <TechnicianFormHardwareAttributes />
                  <Typography variant="h6">Photos</Typography>
                  <TechnicianInventoryImages />
                  <TechnicianFormSubmitButton />
                </Stack>
              </TabPanel>
              <TabPanel index={1} value={tabIndex}>
                <InventoryPartsPanel partType="TopCase" parentInventory={inventory} />
              </TabPanel>
              <TabPanel index={2} value={tabIndex}>
                <InventoryPartsPanel partType="Board" parentInventory={inventory} />
              </TabPanel>
              <TabPanel index={3} value={tabIndex}>
                <InventoryPartsPanel partType="Lcd" parentInventory={inventory} />
              </TabPanel>
              <TabPanel index={4} value={tabIndex}>
                <InventoryPartsPanel partType="Battery" parentInventory={inventory} />
              </TabPanel>
            </Grid2>
          </Grid2>
        </RevivnApiForm>
      )}
    </>
  );
}
