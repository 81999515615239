import { PicklistCard } from "components/Picklist/common/PicklistCard";
import { Check } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { SubmitAwareButton } from "components/Form/SubmitAwareButton";
import { useNavigate, useParams, useRevalidator } from "react-router-dom";

export function PickingCompletePage() {
  const { batchId } = useParams();
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();

  return (
    <>
      <PicklistCard title="All Inventory Picked" Icon={Check}>
        <Typography textAlign="center">
          Next, move your cart to the packing area of the facility to package the orders and ship them out.
        </Typography>
      </PicklistCard>
      <Stack justifyItems="center" alignItems="center" gap={1} mt={8}>
        <RevivnApiForm
          initialValues={{}}
          action={`/fulfillment_batches/${batchId}/start_packing`}
          method="POST"
          onSuccess={() => {
            revalidate();
            navigate(`/picklist/batches/${batchId}`);
          }}
        >
          <SubmitAwareButton variant="contained" type="submit">
            Pack
          </SubmitAwareButton>
        </RevivnApiForm>
      </Stack>
    </>
  );
}
