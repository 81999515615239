import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { GreyButton } from "components/Picklist/common/GreyButton";
import { OrderItem } from "hooks/useFulfillmentBatch";

interface PackChargerStepProps {
  onChargerPacked: () => void;
  onSkip: () => void;
  item: OrderItem;
}

export function PackChargerStep({ onChargerPacked, onSkip, item }: PackChargerStepProps) {
  const getChargerInfo = () => {
    if (!item.charger || item.charger === "Do not include charger") {
      return {
        required: false,
        message: "No charger needed",
        imageUrl: "/no-charger.png",
      };
    }

    const chargerTypeMap: Record<string, { required: boolean; type: string; imageUrl: string }> = {
      "MagSafe 3": {
        required: true,
        type: "MagSafe 3",
        imageUrl: "/magsafe-charger.png",
      },
      "USBC 3": { required: true, type: "USB-C", imageUrl: "/usbc-charger.png" },
    };

    const chargerType = chargerTypeMap[item.charger] || {
      required: true,
      type: item.charger,
      imageUrl: "/usbc-charger.png",
    };

    return {
      required: true,
      type: chargerType.type,
      message: `Please pack a ${item.charger} charger`,
      imageUrl: chargerType.imageUrl,
    };
  };

  const chargerInfo = getChargerInfo();

  return (
    <Stack direction="column" alignItems="center" gap={1} mt={2}>
      <Typography align="center">Is the pictured charger packed in the box?</Typography>
      <Typography
        align="center"
        fontWeight={600}
        sx={{
          maxWidth: "80%",
          whiteSpace: "pre-line",
          mb: 2,
        }}
      >
        {chargerInfo.message}
      </Typography>
      <img
        src={chargerInfo.imageUrl}
        alt={`${chargerInfo.type} charger`}
        style={{
          maxWidth: "300px",
          height: "auto",
          marginBottom: "1rem",
        }}
      />
      <Button variant="contained" onClick={onChargerPacked}>
        {chargerInfo.required ? "yes, charger packed" : "Continue"}
      </Button>
      {chargerInfo.required && <GreyButton onClick={onSkip}>No, unable to pack</GreyButton>}
    </Stack>
  );
}
