import React from "react";
import { OrderItem } from "hooks/useFulfillmentBatch";
import { useRouteLoaderData, useSearchParams } from "react-router-dom";
import { ScanAndConfirmInventoryStep } from "components/Picklist/CurrentBatch/Picking/ScanAndConfirmInventoryStep";
import { DestinationType, ScanAndMoveItemStep } from "components/Picklist/CurrentBatch/Picking/ScanAndMoveItemStep";
import { NotFoundStep } from "components/Picklist/CurrentBatch/Picking/NotFoundStep";
import { ItemCompleteStep } from "components/Picklist/CurrentBatch/Picking/ItemCompleteStep";
import { PicklistInventoryCard } from "components/Picklist/common/PicklistInventoryCard";

export function PickItemPage() {
  const { item } = useRouteLoaderData("picklist-batch-item") as { item: OrderItem };
  const [searchParams, setSearchParams] = useSearchParams();
  const step = searchParams.get("step") ?? "scan";

  return (
    <>
      <PicklistInventoryCard inventory={item} />
      {step === "scan" && (
        <ScanAndConfirmInventoryStep
          onClickNotFound={() => setSearchParams({ step: "not_found" }, { replace: true })}
          onInventoryScanned={() => {
            setSearchParams({ step: "move" }, { replace: true });
          }}
          item={item}
        />
      )}
      {step === "move" && <ScanAndMoveItemStep item={item} destination={DestinationType.PickCart} />}
      {step === "not_found" && <NotFoundStep onCancel={() => setSearchParams({}, { replace: true })} />}
      {step === "complete" && <ItemCompleteStep cartLocation={item.warehouseLocation} />}
    </>
  );
}
