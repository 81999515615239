import { FulfillmentBatch } from "hooks/useFulfillmentBatch";
import { LoaderFunctionArgs } from "react-router-dom";
import { fetcher } from "services/api.service";

export async function loader({ params }: LoaderFunctionArgs) {
  const { batchId } = params;

  const batch = await fetcher<FulfillmentBatch>(`/fulfillment_batches/${batchId}`);
  return { batch };
}
