import { OrderItem } from "hooks/useFulfillmentBatch";
import { LoaderFunctionArgs } from "react-router-dom";
import { fetcher } from "services/api.service";

export async function loader({ params }: LoaderFunctionArgs) {
  const { batchId, itemId } = params;
  const item = await fetcher<OrderItem>(`/fulfillment_batches/${batchId}/items/${itemId}`);

  return { item };
}
