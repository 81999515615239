import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import {
  Button,
  Chip,
  ChipProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import { useMemo, useState } from "react";
import { Inventory } from "types/inventory";

export const DeviceWipeStatus = (props: ChipProps) => {
  const { setFieldValue, values } = useFormikContext<{ inventory: Inventory }>();
  const state = values.inventory.drive1ErasureStatus?.toLowerCase() ?? "";
  const clickable = !["passed", "manual"].includes(state);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(clickable);
  const handleClose = () => setOpen(false);

  const label = useMemo(() => {
    switch (state) {
      case "passed":
        return "Wipe Passed";
      case "failed":
        return "Wipe Failed";
      case "manual":
        return "Manually Wiped";
      default:
        return "Not Wiped";
    }
  }, [state]);

  const color = useMemo(() => {
    switch (state) {
      case "passed":
      case "manual":
        return "success";
      case "failed":
        return "error";
      default:
        return "warning";
    }
  }, [state]);

  const icon = useMemo(() => {
    switch (state) {
      case "passed":
      case "manual":
        return <CheckCircleIcon />;
      case "failed":
        return <CancelIcon />;
      default:
        return <ErrorIcon />;
    }
  }, [state]);

  const desc = useMemo(() => {
    switch (state) {
      case "passed":
        return "Data was successfully erased.";
      case "failed":
        return "A disk wipe was attempted, but did not successfully pass. There may still be sensitive information on this device.";
      case "manual":
        return "Device was manually wiped.";
      default:
        return "Device has not been wiped yet.";
    }
  }, [state]);

  const DiskWipeDialog = (dialogProps: DialogProps) => {
    const handleConfirm = () => setFieldValue("drive1ErasureStatus", "Manual");

    return (
      <Dialog {...dialogProps}>
        <DialogTitle>Set wipe status to Manually Wiped</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to set the wipe status to Manually Wiped? This should only be done for devices that
            cannot be programmatically erased.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" onClick={handleConfirm}>
            Set to Manually Wiped
          </Button>
          <Button size="small" variant="contained" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Tooltip arrow disableInteractive title={desc}>
        <Chip
          {...props}
          clickable={clickable}
          color={color}
          icon={icon}
          label={label}
          variant="outlined"
          onClick={handleOpen}
        />
      </Tooltip>
      <DiskWipeDialog open={open} onClose={handleClose} />
    </>
  );
};
