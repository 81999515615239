export const getDeadline = (shipByDate: string, timezone: string) => {
  const shipByDateObj = new Date(shipByDate);
  const options = { timeZone: timezone, hour: "2-digit" as const, hourCycle: "h23" as const };
  const hoursInTimezone = new Intl.DateTimeFormat("en-US", options).format(shipByDateObj);

  if (parseInt(hoursInTimezone) < 10) {
    const prevDay = new Date(shipByDateObj);
    prevDay.setDate(prevDay.getDate() - 1);
    return prevDay.toLocaleString("en-CA", { timeZone: timezone }).split(",")[0];
  } else {
    return shipByDateObj.toLocaleString("en-CA", { timeZone: timezone }).split(",")[0];
  }
};

export const getShipByLabel = (shipByDate: string, timezone: string | null, state: string | null) => {
  const tz = timezone || "America/Los_Angeles";
  const deadline = getDeadline(shipByDate, tz);
  const deadlineDateString = deadline.split("T")[0];
  const todayDateString = new Date().toLocaleDateString("en-CA", { timeZone: tz }).split(",")[0];

  if (["cancelled", "packed", null].includes(state)) {
    return null;
  }

  if (deadlineDateString === todayDateString) {
    return "Due Today";
  } else if (deadlineDateString > todayDateString) {
    return "Due Later";
  }
  return "Overdue";
};
