import { useNavigate, useSearchParams } from "react-router-dom";
import * as React from "react";
import { useEffect, useState } from "react";
import { serializeFilterModel, serializeSortModel } from "components/DataGrid/helpers/serializers";
import { GridFilterModel, GridLinkOperator, GridSortModel } from "@mui/x-data-grid";
import {
  deserializeFilterParamsToFilterModel,
  deserializeSortParamsToSortModel,
} from "components/DataGrid/helpers/deserializers";

interface DatabaseQueryParams {
  page: number;
  size: number;
  filter: string[];
  sort: string;
  filterLinkOperator: string;
}

export interface DatabaseGridState {
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
  page: number;
  pageSize: number;
}

const DEFAULT_PARAMS: DatabaseQueryParams = { page: 1, size: 25, filter: [], sort: "", filterLinkOperator: "and" };

const searchParamsToDatabaseQueryParams = (searchParams: URLSearchParams): Partial<DatabaseQueryParams> => {
  const page = searchParams.get("page[number]") ? Number(searchParams.get("page[number]")) : undefined;
  const size = searchParams.get("page[size]") ? Number(searchParams.get("page[size]")) : undefined;
  const filter = searchParams.getAll("filter[]");
  const sort = searchParams.get("sort") || undefined;
  const filterLinkOperator = searchParams.get("filter_link_operator") || undefined;
  return { page, size, filter, sort, filterLinkOperator };
};

function getInitialSearchParams(): Partial<DatabaseQueryParams> | null {
  const params = searchParamsToDatabaseQueryParams(new URLSearchParams(window.location.search));
  return Object.values(params).some((value) => value !== undefined) ? params : null;
}

function fillInParamsWithDefaults(params?: Partial<DatabaseQueryParams> | null | false): DatabaseQueryParams {
  if (!params) return DEFAULT_PARAMS;
  return {
    page: params?.page || DEFAULT_PARAMS.page,
    size: params?.size || DEFAULT_PARAMS.size,
    filter: params?.filter || DEFAULT_PARAMS.filter,
    sort: params?.sort || DEFAULT_PARAMS.sort,
    filterLinkOperator: params?.filterLinkOperator || DEFAULT_PARAMS.filterLinkOperator,
  };
}

const emptyFilterModel: GridFilterModel = { items: [], linkOperator: GridLinkOperator.And };

function databaseQueryParamsToGridState(params: DatabaseQueryParams): DatabaseGridState {
  return {
    page: params.page - 1,
    pageSize: params.size,
    filterModel: deserializeFilterParamsToFilterModel(params.filter, params.filterLinkOperator) ?? emptyFilterModel,
    sortModel: deserializeSortParamsToSortModel(params.sort),
  };
}

// Returns the controllable state for the datagrid, initialized from the url or local storage.
// This hook is also responsible for syncing the datagrid params with the URL search params and persisting
// the most recent params to local storage.
export function useDatabaseDataGridState(syncStateWithSearchParams?: boolean) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // These two refs help prevent infinite loops when syncing the state with the URL.
  // We start with them set to true to ignore the first render.
  const isInternalNavigationRef = React.useRef(true);
  const isExternalNavigationRef = React.useRef(true);
  const [gridState, setGridState] = useState<DatabaseGridState>(() => {
    const queryParamSources = [syncStateWithSearchParams && getInitialSearchParams()];
    const initialQueryParams = fillInParamsWithDefaults(queryParamSources.find(Boolean));
    return databaseQueryParamsToGridState(initialQueryParams);
  });

  // ---------------
  // Internal Navigation: When the datagrid state changes, update the URL search params.
  // We can't use setSearchParams here because it is not referentially stable and will change on every render
  // https://github.com/remix-run/react-router/issues/9991
  useEffect(() => {
    if (isExternalNavigationRef.current) {
      isExternalNavigationRef.current = false;
      return;
    }
    const { page, pageSize, filterModel, sortModel } = gridState;
    const filterParams = serializeFilterModel(filterModel);
    const sort = serializeSortModel(sortModel);

    const newSearchParams = new URLSearchParams();
    if (sort !== DEFAULT_PARAMS.sort) newSearchParams.set("sort", sort);
    if (pageSize !== DEFAULT_PARAMS.size) newSearchParams.set("page[size]", gridState.pageSize.toString());
    if (page !== DEFAULT_PARAMS.page) newSearchParams.set("page[number]", (gridState.page + 1).toString());
    filterParams.forEach((filter) => newSearchParams.append("filter[]", filter));

    if (!syncStateWithSearchParams) return;
    // We set this ref to prevent the effect above from running after navigation to prevent
    // an infinite loop of setting state and navigating.
    isInternalNavigationRef.current = true;
    navigate(`?${newSearchParams}`);
  }, [gridState, navigate, syncStateWithSearchParams]);

  // ---------------
  // External Navigation: When the URL search params change, update the datagrid state.
  useEffect(() => {
    // If the search params changed due to our own navigation, don't update the state.
    if (isInternalNavigationRef.current) {
      isInternalNavigationRef.current = false;
      return;
    }
    if (!syncStateWithSearchParams) return;

    isExternalNavigationRef.current = true;
    const queryParams = searchParamsToDatabaseQueryParams(searchParams);
    const nextState = databaseQueryParamsToGridState(fillInParamsWithDefaults(queryParams));
    setGridState(nextState);
  }, [searchParams, syncStateWithSearchParams]);

  return [gridState, setGridState] as const;
}
