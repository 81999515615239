import PrintIcon from "@mui/icons-material/Print";
import { IconButton, Tooltip } from "@mui/material";
import PrintableQRCodes from "components/Pickups/PrintableQRCodes";
import Printable from "components/Printable";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { Inventory } from "types/inventory";

export const PrintLabelButton = ({ inventory }: { inventory?: Inventory }) => {
  const printComponentRef = useRef(null);

  const trigger = () => (
    <Tooltip title="Reprint label" disableInteractive>
      <IconButton color="primary">
        <PrintIcon />
      </IconButton>
    </Tooltip>
  );

  const PrintContent = () => (
    <Printable>
      <PrintableQRCodes ref={printComponentRef} selectedInventories={inventory ? [inventory] : []} />
    </Printable>
  );

  return (
    <>
      <ReactToPrint trigger={trigger} content={() => (printComponentRef ? printComponentRef.current : null)} />
      {printComponentRef && <PrintContent />}
    </>
  );
};
